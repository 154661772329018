import axios from "axios";
import secureLocalStorage from "react-secure-storage";
const API_URL = process.env.REACT_APP_API_Link;

const getEligibilityById = (id, uid) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "course/checkCourseEligibility/" + id + "/" + uid,
    {
      headers: headers,
    }
  );
};

const getCourseIntroById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "course/courseIntroById/" + id, {
    headers: headers,
  });
};

const getCourseNameById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "course/courseNameById/" + id, {
    headers: headers,
  });
};

const getNewCourseById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "course/newCourseById/" + id, {
    headers: headers,
  });
};
const getQuizById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "quiz/quizContent/" + id, {
    headers: headers,
  });
};

const getQuizAnsById = (userId, quesId, quizAns, courseCode) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "quiz/checkCorrectAnswer",
    {
      userId: userId,
      questionId: quesId,
      answer: quizAns,
      ip: secureLocalStorage.getItem("ip"),
      courseCode: courseCode,
    },
    {
      headers: headers,
    }
  );
};

const CourseService = {
  getCourseIntroById,
  getCourseNameById,
  getNewCourseById,
  getQuizById,
  getQuizAnsById,
  getEligibilityById,
};

export default CourseService;
