import React from 'react'

export default function SvgsIco() {
  return (
    <svg className='d-none'>
        <g id="icon-01" >
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                stroke="none">
                <path d="M1448 4602 c-599 -240 -1088 -439 -1088 -442 0 -3 207 -89 460 -190
                l460 -184 0 -493 0 -493 -192 0 c-229 0 -297 -12 -418 -71 -277 -137 -416
                -475 -316 -767 34 -102 80 -175 158 -254 56 -56 65 -70 50 -75 -239 -86 -432
                -281 -513 -516 -81 -237 -59 -476 64 -697 57 -102 205 -250 307 -307 86 -48
                197 -87 290 -102 100 -16 4189 -15 4244 1 86 26 166 136 166 228 0 81 -62 182
                -130 210 l-30 12 0 378 0 378 30 12 c42 17 104 90 118 138 28 91 8 167 -63
                237 -70 70 -91 75 -353 75 l-221 0 6 50 c3 29 0 69 -7 95 -15 53 -76 127 -120
                145 l-30 12 0 89 0 89 125 0 c93 0 125 3 125 13 0 6 -30 194 -67 416 -52 318
                -64 407 -55 420 50 70 66 117 67 191 0 114 -50 196 -147 246 l-48 24 0 174 0
                174 -87 31 c-49 17 -94 35 -101 40 -9 5 96 52 307 137 177 70 321 131 321 134
                0 10 -2183 880 -2205 879 -11 0 -509 -197 -1107 -437z m2010 -87 c480 -192
                872 -352 872 -355 0 -3 -99 -46 -219 -94 l-219 -87 -645 245 c-354 135 -649
                246 -654 246 -15 0 -59 -140 -46 -148 6 -4 259 -101 562 -217 303 -115 551
                -212 551 -215 0 -3 -248 -104 -550 -224 l-550 -220 -885 354 c-487 195 -885
                357 -885 360 0 11 1755 710 1776 707 11 -2 413 -160 892 -352z m702 -927 l0
                -118 -39 -19 c-60 -31 -100 -69 -129 -125 -49 -93 -33 -231 36 -309 13 -15 14
                -28 3 -100 -19 -127 -10 -117 -107 -117 l-84 0 0 494 0 494 27 9 c22 8 52 0
                160 -41 l133 -51 0 -117z m-2165 -88 c302 -121 557 -220 565 -220 8 0 263 99
                565 220 302 121 551 220 552 220 2 0 3 -218 3 -484 l0 -484 -49 -44 c-147
                -132 -416 -238 -721 -285 -164 -25 -539 -24 -705 1 -303 46 -574 155 -724 291
                l-41 37 0 484 c0 266 1 484 3 484 1 0 250 -99 552 -220z m2329 -219 c26 -27
                31 -39 31 -81 0 -43 -5 -54 -33 -82 -28 -28 -39 -33 -82 -33 -43 0 -54 5 -82
                33 -28 28 -33 39 -33 81 0 52 25 92 70 112 37 16 96 3 129 -30z m9 -648 c26
                -159 47 -294 47 -300 0 -10 -35 -13 -140 -13 -105 0 -140 3 -140 13 0 6 21
                141 47 300 l48 287 45 0 45 0 48 -287z m-2950 -46 c34 -29 80 -64 102 -77 l40
                -25 -325 -5 c-321 -5 -326 -5 -375 -30 -64 -31 -124 -91 -155 -155 -34 -69
                -35 -198 -2 -265 34 -71 75 -113 144 -151 l63 -34 1703 -5 1704 -5 19 -24 c26
                -32 24 -77 -6 -106 l-24 -25 -1674 0 c-1097 0 -1693 4 -1728 10 -177 34 -327
                177 -374 355 -64 248 93 511 346 579 39 11 114 15 267 15 l213 1 62 -53z
                m2597 36 c0 -9 -5 -45 -11 -80 l-11 -63 -179 0 c-98 0 -179 2 -179 4 0 2 24
                19 53 38 28 20 72 54 97 76 l45 41 93 1 c79 0 92 -2 92 -17z m-1978 -323 c185
                -43 303 -55 558 -55 255 0 373 12 558 55 75 18 122 20 449 20 l366 0 -6 -29
                c-3 -15 -9 -51 -13 -80 l-7 -51 127 0 126 0 0 -80 0 -80 -1615 0 c-1342 0
                -1621 2 -1648 14 -96 40 -126 168 -60 247 50 59 43 58 585 59 458 0 503 -2
                580 -20z m2933 -805 c30 -29 32 -74 6 -106 l-19 -24 -2104 -5 -2103 -5 -59
                -23 c-84 -31 -147 -74 -211 -144 -95 -102 -135 -206 -135 -349 0 -149 56 -278
                166 -379 60 -56 105 -84 180 -113 l59 -22 2103 -5 2104 -5 19 -24 c26 -32 24
                -77 -6 -106 l-24 -25 -2066 0 c-2304 0 -2146 -5 -2300 70 -147 72 -252 178
                -321 325 -106 228 -76 497 80 702 61 80 140 144 241 193 151 73 -10 68 2293
                69 l2073 1 24 -25z m-135 -375 l0 -80 -720 0 -720 0 0 -80 0 -80 720 0 720 0
                0 -200 0 -200 -2032 2 -2033 3 -59 29 c-73 36 -135 96 -173 168 -26 48 -28 62
                -28 158 0 95 3 110 27 156 15 28 38 65 52 82 35 41 119 93 175 108 36 10 486
                13 2059 13 l2012 1 0 -80z"/>
                <path d="M800 720 l0 -80 1120 0 1120 0 0 80 0 80 -1120 0 -1120 0 0 -80z"/>
            </g>
        </g>
        <g id="icon-02" >
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="" stroke="none">
                <path d="M1513 4788 c-22 -24 -241 -274 -487 -557 l-446 -513 0 -1698 c0
                -1631 1 -1698 18 -1713 17 -16 100 -17 985 -17 954 0 967 0 987 20 25 25 25
                51 1 81 l-19 24 -926 3 -926 2 0 1600 0 1599 511 3 511 3 19 24 c18 22 19 44
                19 412 0 376 -1 390 -20 409 -27 27 -75 26 -94 -2 -14 -19 -16 -72 -16 -370
                l0 -348 -421 0 c-250 0 -419 4 -417 9 2 5 185 219 408 475 l405 466 1092 0
                1093 0 0 -709 c0 -701 0 -709 21 -735 25 -31 61 -34 89 -6 20 20 20 33 20 783
                0 727 -1 765 -18 780 -17 16 -114 17 -1183 17 l-1165 0 -41 -42z"/>
                <path d="M3515 3103 c-42 -10 -85 -13 -145 -8 -47 3 -119 8 -161 11 -99 8
                -137 -9 -243 -104 -60 -54 -99 -80 -155 -103 -156 -63 -194 -106 -236 -260
                -21 -76 -40 -120 -78 -177 -97 -148 -107 -203 -61 -339 22 -64 28 -104 33
                -223 6 -135 8 -148 34 -192 16 -27 57 -71 96 -103 38 -30 78 -67 89 -82 l20
                -28 -229 -395 c-125 -217 -228 -404 -229 -415 0 -11 7 -31 16 -43 19 -27 47
                -28 243 -6 78 8 151 14 163 12 16 -2 36 -38 93 -167 40 -90 80 -172 89 -182
                19 -23 69 -25 89 -4 8 7 113 185 233 394 184 321 220 377 229 363 7 -9 105
                -179 220 -377 114 -198 214 -368 223 -377 20 -23 74 -23 94 0 8 9 48 93 89
                186 l74 168 60 -5 c33 -3 122 -11 197 -18 130 -12 138 -12 158 6 11 10 20 31
                20 47 0 20 -73 156 -225 419 -124 214 -225 393 -225 398 0 7 32 38 148 143 73
                66 85 102 92 273 5 119 11 161 28 205 49 123 39 192 -43 316 -61 91 -67 105
                -96 213 -37 139 -80 186 -223 246 -73 30 -127 67 -214 146 -35 32 -71 54 -104
                64 -62 18 -85 18 -163 -2z m-224 -139 c88 -14 115 -15 193 -4 155 22 166 19
                257 -65 67 -61 116 -91 222 -135 47 -19 84 -42 94 -56 9 -14 24 -61 34 -105
                21 -90 50 -150 114 -245 54 -78 56 -102 18 -211 -23 -67 -27 -97 -29 -211 -3
                -156 -4 -157 -103 -237 -77 -60 -107 -96 -161 -188 -48 -84 -80 -103 -182
                -112 -102 -9 -181 -33 -250 -75 -79 -49 -109 -49 -199 -2 -90 48 -158 68 -259
                77 -95 9 -123 26 -167 100 -57 98 -102 150 -175 206 -38 29 -76 65 -84 80 -10
                19 -14 62 -13 141 0 119 -7 165 -42 263 -25 71 -18 107 35 176 50 66 84 139
                111 241 28 103 46 128 113 153 95 36 163 77 229 137 59 53 110 86 135 88 4 0
                53 -7 109 -16z m-460 -1638 c42 -38 119 -66 184 -66 57 0 144 -24 215 -60 33
                -17 60 -32 60 -34 0 -7 -363 -641 -374 -653 -9 -10 -26 21 -72 125 -40 90 -68
                141 -81 148 -16 7 -62 6 -162 -4 -78 -8 -144 -13 -146 -10 -5 4 326 587 334
                588 2 0 21 -15 42 -34z m1348 -261 c92 -160 163 -292 158 -294 -5 -2 -57 2
                -116 8 -159 18 -180 17 -201 -5 -10 -10 -42 -73 -70 -139 -29 -66 -55 -124
                -59 -128 -6 -6 -372 610 -389 653 -3 8 81 50 153 77 22 8 80 19 130 24 97 10
                165 36 193 75 9 13 21 22 25 21 5 -1 84 -133 176 -292z"/>
                <path d="M3275 2646 c-192 -47 -344 -203 -390 -398 -84 -357 221 -699 579
                -649 211 29 386 186 441 397 23 89 20 129 -11 153 -34 27 -47 26 -77 -1 -17
                -16 -27 -41 -35 -85 -22 -130 -110 -245 -229 -301 -51 -24 -70 -27 -158 -27
                -93 0 -105 2 -167 33 -196 96 -277 338 -179 536 41 83 129 166 208 196 77 30
                186 32 267 6 53 -18 61 -18 85 -5 37 21 37 84 1 110 -60 44 -227 62 -335 35z"/>
                <path d="M3676 2362 c-105 -103 -215 -209 -243 -236 l-53 -50 -74 72 c-41 40
                -83 72 -94 72 -32 0 -72 -38 -72 -69 0 -21 21 -48 99 -124 93 -91 101 -97 140
                -97 40 0 49 6 169 119 227 212 412 406 412 430 0 28 -43 71 -71 71 -16 0 -81
                -57 -213 -188z"/>
                <path d="M1152 3068 c-20 -25 -18 -70 4 -92 14 -14 85 -16 634 -16 680 0 650
                -3 650 60 0 63 30 60 -654 60 -487 0 -626 -3 -634 -12z"/>
                <path d="M1154 2485 c-27 -27 -26 -54 4 -87 14 -17 50 -18 487 -18 457 0 472
                1 490 20 24 27 22 70 -5 92 -21 17 -53 18 -487 18 l-464 0 -25 -25z"/>
                <path d="M1155 1916 c-27 -19 -24 -74 4 -98 22 -17 51 -18 486 -18 432 0 464
                1 485 18 27 22 29 65 5 92 -18 19 -33 20 -490 20 -381 0 -475 -3 -490 -14z"/>
                <path d="M1152 1338 c-20 -25 -18 -70 4 -92 14 -14 72 -16 489 -16 417 0 475
                2 489 16 19 18 21 75 4 92 -18 18 -971 17 -986 0z"/>
            </g>
        </g>
        <g id="icon-03" >
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="" stroke="none">
                <path d="M2523 5114 c-13 -6 -2441 -1900 -2485 -1939 -32 -29 -43 -63 -33
                -102 7 -31 362 -395 407 -419 35 -18 62 -18 98 1 l30 16 0 -1236 0 -1235 -230
                0 c-180 0 -237 -3 -259 -14 -66 -35 -66 -137 0 -172 42 -21 4976 -21 5018 0
                66 35 66 137 0 172 -22 11 -79 14 -259 14 l-230 0 0 475 c0 509 -1 514 -51
                541 -35 18 -63 18 -98 0 -50 -27 -51 -32 -51 -541 l0 -475 -1580 0 -1580 0 0
                480 c0 514 0 519 -51 546 -35 18 -63 18 -98 0 -51 -27 -51 -32 -51 -546 l0
                -480 -140 0 -140 0 2 1317 3 1316 908 709 907 709 908 -709 907 -708 5 -494
                c3 -272 9 -503 14 -513 10 -21 57 -47 86 -47 33 0 75 26 88 55 9 19 12 138 12
                431 l0 405 30 -16 c34 -18 63 -19 93 -4 31 16 385 369 403 401 21 41 17 74
                -15 110 -16 18 -160 134 -319 258 l-291 225 0 512 c-1 375 -4 519 -13 538 -24
                53 -41 55 -378 55 -328 0 -355 -3 -376 -47 -5 -10 -11 -114 -14 -230 l-5 -213
                -125 98 c-490 385 -948 741 -967 750 -22 12 -58 14 -80 6z m1153 -1092 c611
                -477 1128 -881 1150 -899 l39 -32 -105 -105 c-57 -58 -108 -106 -112 -106 -3
                0 -465 358 -1024 795 -564 440 -1031 797 -1046 800 -16 4 -40 1 -55 -7 -16 -8
                -482 -368 -1036 -801 -554 -433 -1011 -787 -1015 -787 -4 0 -54 47 -112 105
                -67 67 -101 108 -95 114 19 18 2290 1791 2295 1791 3 0 505 -391 1116 -868z
                m604 157 c0 -293 -3 -369 -12 -363 -7 5 -93 70 -190 147 l-178 138 0 225 0
                224 190 0 190 0 0 -371z"/>
                <path d="M1551 2926 c-52 -29 -50 11 -51 -1044 0 -1068 -2 -1024 55 -1050 19
                -9 267 -12 1006 -12 1065 0 1021 -2 1047 55 17 38 17 1972 0 2010 -26 57 18
                55 -1050 55 -797 -1 -988 -3 -1007 -14z m909 -566 l0 -380 -380 0 -380 0 0
                380 0 380 380 0 380 0 0 -380z m960 0 l0 -380 -380 0 -380 0 0 380 0 380 380
                0 380 0 0 -380z m-960 -960 l0 -380 -380 0 -380 0 0 380 0 380 380 0 380 0 0
                -380z m960 0 l0 -380 -380 0 -380 0 0 380 0 380 380 0 380 0 0 -380z"/>
                <path d="M1055 1607 c-17 -14 -28 -36 -32 -63 -5 -36 -1 -46 25 -75 25 -28 37
                -34 72 -34 35 0 47 6 72 34 26 29 30 39 25 75 -7 54 -44 86 -97 86 -26 0 -48
                -8 -65 -23z"/>
                <path d="M4431 1566 c-87 -48 -50 -186 49 -186 51 0 100 49 100 99 0 75 -83
                124 -149 87z"/>
            </g>
        </g>
        <g id="icon-04" >
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="" stroke="none">
                <path d="M1290 4611 c-83 -26 -141 -74 -181 -151 -23 -43 -24 -54 -27 -302
                l-3 -258 -413 0 c-457 0 -479 -2 -557 -63 -23 -17 -56 -57 -73 -87 l-31 -55 0
                -1495 0 -1495 27 -50 c31 -60 67 -95 128 -127 l45 -23 1565 -3 c861 -1 1576 0
                1590 3 31 8 654 627 671 667 l11 28 377 0 c386 0 476 7 540 40 52 26 114 97
                137 156 18 47 19 97 19 1534 l0 1485 -27 50 c-31 59 -67 94 -128 126 l-45 24
                -1800 2 c-990 1 -1811 -2 -1825 -6z m3591 -204 c35 -27 39 -53 39 -281 l0
                -226 -1820 0 -1820 0 0 233 c1 241 2 250 45 275 30 17 3534 16 3556 -1z
                m-1088 -718 c43 -19 47 -46 47 -284 l0 -225 -1820 0 -1820 0 0 225 c0 234 5
                264 44 283 31 15 3516 16 3549 1z m1127 -1094 c0 -1190 2 -1147 -49 -1175 -13
                -6 -166 -10 -425 -10 l-406 0 0 275 c0 293 -4 320 -49 345 -25 13 -77 13 -102
                0 -46 -25 -49 -49 -49 -393 l0 -325 -197 -4 c-223 -4 -253 -12 -328 -86 -71
                -70 -79 -101 -83 -324 l-4 -198 -1489 0 -1490 0 -24 26 -25 27 0 1113 0 1114
                1820 0 1820 0 0 -141 0 -141 34 -34 c46 -46 86 -46 132 0 l35 35 -3 500 -3
                501 443 0 442 0 0 -1105z m-1230 -1492 c0 -10 -243 -253 -252 -253 -13 0 -9
                183 5 210 7 13 24 30 37 37 27 14 210 18 210 6z"/>
                <path d="M1565 4251 c-61 -25 -80 -108 -36 -155 79 -84 207 4 159 109 -20 44
                -76 65 -123 46z"/>
                <path d="M1870 4247 c-38 -18 -63 -77 -50 -113 16 -42 39 -63 75 -70 111 -21
                159 133 58 185 -28 14 -52 14 -83 -2z"/>
                <path d="M2178 4244 c-35 -18 -48 -43 -48 -89 0 -65 69 -110 131 -85 39 16 59
                46 59 88 0 44 -14 68 -50 87 -36 19 -57 18 -92 -1z"/>
                <path d="M2788 4244 c-34 -18 -48 -43 -48 -86 0 -41 20 -71 57 -87 21 -8 267
                -11 935 -9 l908 3 27 27 c38 39 38 97 0 136 l-27 27 -913 2 c-791 2 -916 0
                -939 -13z"/>
                <path d="M485 3531 c-37 -16 -60 -51 -60 -93 0 -33 6 -45 33 -69 18 -16 43
                -29 57 -29 107 0 144 139 50 185 -36 17 -50 18 -80 6z"/>
                <path d="M784 3521 c-42 -26 -58 -72 -40 -116 26 -60 78 -80 136 -50 71 37 67
                136 -7 174 -31 16 -53 14 -89 -8z"/>
                <path d="M1105 3528 c-34 -18 -55 -51 -55 -86 0 -44 14 -68 52 -88 64 -33 138
                11 138 83 0 45 -14 69 -50 88 -34 18 -57 19 -85 3z"/>
                <path d="M1708 3524 c-54 -29 -66 -102 -24 -151 l24 -28 921 -3 920 -2 33 29
                c44 39 46 98 5 139 l-27 27 -913 2 c-791 2 -916 0 -939 -13z"/>
                <path d="M2411 2626 c-42 -23 -50 -53 -51 -173 l0 -113 -633 0 c-691 0 -661 2
                -686 -58 -8 -20 -11 -122 -9 -359 3 -371 2 -362 77 -383 22 -6 269 -10 644
                -10 l607 0 0 -116 c0 -114 0 -116 29 -145 23 -23 38 -29 72 -29 43 0 43 0 356
                312 172 171 320 327 328 345 32 67 27 74 -304 406 -168 170 -318 315 -333 323
                -33 17 -66 17 -97 0z m329 -861 c-96 -96 -176 -172 -177 -168 -1 5 -4 25 -8
                47 -4 22 -17 48 -32 62 l-26 24 -633 0 -634 0 0 205 0 205 608 0 c395 0 620 4
                645 11 45 12 77 57 77 109 0 35 2 33 177 -142 l178 -178 -175 -175z"/>
                <path d="M3905 2421 c-37 -16 -60 -51 -60 -93 0 -33 6 -45 33 -69 20 -18 43
                -29 62 -29 19 0 42 11 62 29 27 24 33 36 33 70 0 67 -71 117 -130 92z"/>
            </g>
        </g>
        <g id="icon-05" >
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="" stroke="none">
                <path d="M919 4947 c-42 -32 -42 -46 -3 -285 l38 -228 -100 -99 c-346 -345
                -543 -740 -605 -1210 -17 -131 -7 -402 21 -540 87 -437 342 -819 707 -1059
                l63 -41 0 -436 c0 -268 4 -458 11 -495 32 -178 173 -330 348 -376 60 -15 161
                -17 1201 -17 1249 -1 1191 -4 1310 60 100 53 182 148 224 257 21 56 21 66 24
                867 l2 810 137 137 138 138 57 -57 58 -57 -150 -151 c-98 -99 -150 -159 -150
                -172 0 -36 47 -83 83 -83 28 0 55 23 220 188 234 234 229 216 92 352 l-94 94
                70 73 c66 68 71 76 65 105 -4 18 -22 49 -41 68 l-35 36 106 104 c172 169 196
                226 136 321 -45 69 -165 177 -207 185 -78 14 -101 1 -243 -139 l-132 -131 -38
                36 c-20 19 -45 38 -54 41 -15 5 -17 33 -20 274 -3 245 -5 273 -25 325 -50 135
                -148 231 -296 291 -51 22 -66 22 -669 27 l-617 5 205 263 c221 286 228 299
                179 347 -13 14 -29 25 -35 25 -6 0 -444 36 -974 80 -531 44 -970 80 -978 80
                -7 0 -20 -6 -29 -13z m1011 -227 c415 -34 763 -65 773 -69 16 -5 -57 -104
                -457 -619 -262 -337 -480 -612 -484 -612 -4 1 -45 53 -92 116 -47 64 -94 122
                -105 130 -35 25 -69 16 -161 -46 -326 -218 -549 -537 -629 -900 -26 -118 -31
                -366 -10 -491 21 -127 65 -268 115 -371 47 -96 34 -91 -83 31 -185 195 -307
                429 -369 708 -18 83 -22 130 -22 313 0 196 2 226 27 341 63 297 195 575 384
                809 34 42 116 128 182 192 99 95 121 122 121 145 0 15 -14 107 -30 203 -16 96
                -30 179 -30 184 0 5 26 6 58 3 31 -3 397 -33 812 -67z m1885 -750 c70 -34 139
                -108 164 -175 20 -52 21 -76 21 -363 l0 -307 -755 -755 c-811 -811 -780 -776
                -734 -834 12 -15 19 -35 16 -44 -2 -9 -50 -131 -106 -271 -104 -259 -109 -281
                -75 -315 35 -36 57 -31 333 79 l269 108 27 -22 c58 -45 44 -57 552 451 l473
                473 0 -707 c0 -691 0 -708 -21 -763 -25 -67 -94 -141 -164 -175 l-50 -25
                -1160 0 -1160 0 -55 26 c-70 33 -126 89 -159 159 l-26 55 -3 412 -3 412 35
                -14 c50 -21 73 -19 101 10 45 44 35 75 -55 171 l-80 85 0 796 0 797 83 82 c75
                73 199 174 216 174 4 0 51 -61 106 -134 148 -199 161 -201 292 -31 l88 114
                663 1 663 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -596 0 c-327
                0 -595 2 -595 6 0 3 68 93 152 200 l151 195 671 -3 671 -3 50 -25z m793 -917
                l-113 -113 -57 57 -58 58 112 112 113 113 57 -57 58 -58 -112 -112z m-843
                -1073 c-401 -402 -735 -730 -740 -730 -6 0 -84 74 -175 165 l-165 165 735 735
                735 735 170 -170 170 -170 -730 -730z m-2725 458 c-1 -594 2 -573 -55 -428
                -103 267 -96 657 17 902 17 37 33 68 34 68 2 0 4 -244 4 -542z m1705 -1138
                c43 -44 77 -81 74 -84 -8 -8 -261 -107 -265 -103 -5 4 100 267 106 267 3 0 41
                -36 85 -80z"/>
                <path d="M1479 3030 c-9 -5 -22 -23 -29 -40 -15 -35 0 -84 31 -100 11 -6 363
                -10 924 -10 l906 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -908
                0 c-552 0 -914 -4 -924 -10z"/>
                <path d="M1479 2470 c-9 -5 -22 -23 -29 -40 -15 -35 0 -84 31 -100 11 -6 320
                -10 804 -10 l786 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -788
                0 c-477 0 -794 -4 -804 -10z"/>
                <path d="M1479 1910 c-9 -5 -22 -23 -29 -40 -15 -35 0 -84 31 -100 11 -6 219
                -10 524 -10 l506 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -508
                0 c-301 0 -515 -4 -524 -10z"/>
                <path d="M1479 1030 c-9 -5 -22 -23 -29 -40 -15 -35 0 -84 31 -100 10 -6 161
                -10 364 -10 l346 0 24 25 c16 15 25 36 25 55 0 19 -9 40 -25 55 l-24 25 -348
                0 c-200 0 -355 -5 -364 -10z"/>
            </g>
        </g>
        <g id="icon-06" >
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="" stroke="none">
                <path d="M4040 4834 c-112 -16 -175 -30 -235 -51 -310 -111 -531 -340 -629
                -653 -36 -112 -52 -271 -40 -378 l10 -82 -1129 -2 -1129 -3 -19 -24 c-25 -30
                -24 -76 1 -101 20 -20 33 -20 1166 -20 l1145 0 35 -76 c53 -116 118 -211 208
                -300 114 -114 239 -192 399 -247 16 -6 17 -57 17 -877 0 -959 2 -920 -62 -960
                -31 -19 -50 -20 -289 -20 -238 0 -259 -1 -280 -19 -24 -19 -24 -22 -29 -251
                l-5 -232 -260 244 c-144 134 -269 247 -279 251 -10 4 -62 7 -117 7 -93 0 -102
                -2 -124 -25 -14 -13 -25 -36 -25 -50 0 -14 11 -37 25 -50 22 -22 32 -25 105
                -25 l80 0 313 -293 c172 -162 322 -299 334 -306 13 -6 34 -9 48 -5 53 13 55
                25 55 326 l0 278 213 0 c256 0 285 7 364 85 88 88 83 33 83 1019 l0 861 108
                -3 c656 -23 1152 599 989 1239 -26 101 -86 238 -118 270 -29 29 -83 25 -108
                -7 -28 -35 -27 -43 13 -122 142 -285 124 -598 -49 -857 -315 -470 -991 -503
                -1351 -66 -274 332 -249 826 57 1129 165 164 360 245 589 246 180 0 321 -41
                465 -135 83 -54 123 -61 155 -29 22 22 26 60 10 90 -14 25 -160 117 -238 149
                -152 62 -346 93 -472 75z"/>
                <path d="M4015 4473 c-149 -45 -265 -196 -265 -342 0 -58 26 -91 71 -91 44 0
                68 28 79 92 10 66 27 103 67 142 138 140 383 40 383 -156 0 -130 -75 -213
                -213 -233 -82 -13 -87 -26 -87 -235 0 -164 2 -179 21 -204 27 -34 77 -36 107
                -3 19 21 22 35 24 161 l3 138 44 15 c93 31 168 99 217 195 33 63 43 178 24
                257 -16 69 -79 162 -137 205 -93 69 -229 93 -338 59z"/>
                <path d="M226 3660 c-96 -17 -189 -102 -216 -198 -6 -24 -10 -435 -10 -1188 0
                -1286 -5 -1202 72 -1289 22 -25 59 -56 82 -68 l41 -22 963 -3 963 -2 24 24
                c32 32 32 70 0 101 l-24 25 -938 0 c-913 0 -939 1 -970 20 -67 41 -63 -39 -63
                1215 0 1254 -4 1180 63 1220 28 18 55 20 214 23 197 4 204 6 218 63 5 20 0 33
                -18 55 l-25 29 -164 2 c-89 1 -185 -3 -212 -7z"/>
                <path d="M4094 3318 c-54 -26 -50 -105 6 -128 23 -9 34 -9 58 4 52 27 50 106
                -3 126 -31 12 -34 12 -61 -2z"/>
                <path d="M877 2749 c-176 -26 -346 -185 -386 -360 -29 -123 -3 -267 67 -375
                136 -210 420 -276 641 -149 l44 25 49 -46 c44 -41 53 -45 81 -40 40 8 55 23
                63 63 5 28 1 38 -41 82 l-47 51 21 36 c82 139 81 351 -2 488 -58 94 -183 189
                -282 214 -64 16 -146 21 -208 11z m134 -149 c110 -21 199 -91 244 -192 24 -53
                27 -70 23 -141 -2 -47 -11 -99 -21 -121 l-17 -39 -54 52 c-62 58 -93 65 -131
                26 -38 -38 -32 -69 26 -130 l51 -54 -24 -15 c-38 -24 -115 -39 -181 -34 -112
                9 -218 82 -267 183 -34 71 -39 182 -11 256 45 120 161 204 297 218 6 1 35 -4
                65 -9z"/>
                <path d="M1680 2747 c-49 -25 -51 -38 -48 -377 l3 -315 28 -56 c33 -69 99
                -132 175 -167 49 -23 70 -27 152 -27 82 0 103 4 152 27 76 35 142 98 175 167
                l28 56 0 327 c0 324 0 328 -22 349 -27 27 -75 29 -102 5 -19 -17 -20 -35 -23
                -335 -3 -362 -3 -360 -93 -416 -42 -26 -58 -30 -121 -30 -64 0 -77 3 -115 30
                -84 59 -84 58 -87 416 -3 283 -5 318 -20 332 -26 23 -55 28 -82 14z"/>
                <path d="M2573 2750 c-54 -22 -54 -22 -51 -485 l3 -427 24 -19 c30 -25 76 -24
                101 1 19 19 20 33 20 454 0 399 -1 435 -17 454 -22 25 -51 33 -80 22z"/>
                <path d="M2885 2748 c-50 -27 -60 -80 -22 -116 22 -21 32 -22 221 -22 155 0
                197 -3 193 -13 -2 -7 -100 -158 -216 -336 -244 -373 -244 -372 -235 -406 13
                -53 24 -55 344 -55 l297 0 21 23 c27 29 28 67 3 98 -19 24 -23 24 -235 29
                l-215 5 215 330 c119 182 223 344 231 362 21 42 4 87 -39 102 -42 15 -536 14
                -563 -1z"/>
            </g>
        </g>
    </svg>
  )
}
