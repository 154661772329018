import React, { useContext, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import CourseService from "../../../../services/course.service";
import "./MbanCont.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function MbanCont() {
  const { state } = useContext(UserContext);
  const { id } = state;
  const [loading, setLoading] = useState(false);
  const [newCourse, setNewCourse] = useState([]);

  useLayoutEffect(() => {
    const getNewCourses = async (val) => {
      try {
        setLoading(true);
        const { data } = await CourseService.getNewCourseById(id);
        const { response: res } = data;
        const results = [];
        res.map((value) => {
          results.push({
            id: value.code,
            name: value.course_name,
          });
        });
        setNewCourse([...results]);
      } catch (err) {
        setLoading(true);
        // console.log(err);
      }
    };
    getNewCourses();
  }, []);

  return (
    <div className="ban-cont">
      <div className="row">
        <div className="col-lg-6">
          <img src="images/matris.png" className="img-fluid" alt="" />
        </div>
        <div className="col-lg-6">
          <div className="txt-in">
            <h2 className="main-heading">
              <span>welcome to </span>
              Ashley Sleep University
            </h2>
            {newCourse.length > 0 && (
              <>
                <span className="gen-txt">
                  New Courses have been added Please click on it to view the new
                  courses.
                </span>
                <OwlCarousel
                  className={
                    newCourse.length > 2 ? "owl-theme centered" : "owl-theme"
                  }
                  margin={10}
                  autoWidth={true}
                  loop={newCourse.length > 2 ? true : false}
                  center={newCourse.length > 2 ? true : false}
                  autoplay={true}
                  autoplayTimeout={2000}
                  autoplayHoverPause={true}
                >
                  {newCourse.map((res, index) => {
                    return (
                      <div className="item" key={index}>
                        <Link
                          key={res.id}
                          to={"/course/" + res.id}
                          className="round-red-btn"
                        >
                          {res.name}
                        </Link>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
