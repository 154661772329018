import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_Link;

const login = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/login",
    {
      username: values.username,
      password: values.password,
    },
    {
      headers: headers,
    }
  );
};

const getProfile = () => {
  return axios.get(API_URL + "user/profile", {
    headers: authHeader(),
  });
};

const getUserState = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/state/list", {
    headers: headers,
  });
};

const getNameState = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/state/get-name-by-id/" + id, {
    headers: headers,
  });
};
const getNameCity = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/city/get-info-by-id/" + id, {
    headers: headers,
  });
};

const getNameStore = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/get-name-by-id/" + id, {
    headers: headers,
  });
};

const getCityByStateId = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/city/get-by-state-id/" + id, {
    headers: headers,
  });
};

const validateRegister = (values) => {
  let userType = "";
  if (values.role === "0") {
    userType = "rsa";
  } else if (values.role === "1") {
    userType = "manager";
  }

  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/add",
    {
      username: values.username,
      password: values.password,
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      state_id: values.stateprov2,
      city_id: values.city2,
      zip: values.zipcode,
      signature: values.signature,
      manager_signature: values.manager_signature,
      store_id: values.store,
      store_city_id: values.city,
      store_state_id: values.stateprov,
      user_type: userType,
      register_from: "web",
      created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const validateUser = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/check-username-exist/" + values.username, {
    headers: headers,
  });
};

const validateEmail = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/check-email-exist/" + values.email, {
    headers: headers,
  });
};

const UpdateData = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/add",
    {
      username: values.username,
      password: values.password,
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      zip: values.zipcode,
      store_city_id: values.city,
      store_state_id: values.stateprov,
      register_from: "web",
      created_ip: localStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const distroyLogin = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/logout",

    {
      headers: headers,
    }
  );
};

const forgotPass = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/forgot",
    {
      email: values.email,
    },
    {
      headers: headers,
    }
  );
};

const changePass = (values, forgotToken) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/update-password/" + forgotToken,
    {
      password: values.password,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const checkToken = (token) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/check-forgot-token/" + token, {
    headers: headers,
  });
};

const validateProfileEmail = (values, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/check-profile-email-exist/" + id + "/" + values.email,
    {
      headers: headers,
    }
  );
};

const UpdateStoreInfo = (values, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/update/" + id,
    {
      store_state_id: values.stateprov,
      store_city_id: values.city,
      store_id: values.store,
      updated_by: "1",
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const UpdateUserInfo = (values, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/update/" + id,
    {
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      state_id: values.stateprov2,
      city_id: values.city2,
      zip: values.zipcode,
      updated_by: "1",

      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const UpdateUserInfoWithPass = (values, id, pass) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/update/" + id,
    {
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      state_id: values.stateprov2,
      city_id: values.city2,
      zip: values.zipcode,
      updated_by: "1",
      password: pass,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const UpdateAdminInfo = (values, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/update/" + id,
    {
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      state: values.state,
      city: values.city,
      zip: values.zipcode,
      address: values.address,
      phone: values.phone,
      updated_by: "1",

      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const UpdateAdminInfoWithPass = (values, id, pass) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/update/" + id,
    {
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      state: values.state,
      city: values.city,
      zip: values.zipcode,
      address: values.address,
      phone: values.phone,
      updated_by: "1",
      password: pass,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const totalRsa = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/count-rsa", {
    headers: headers,
  });
};
const totalRsaForDos = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/count-dos-rsa/" + id, {
    headers: headers,
  });
};

const totalManager = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/count-manager", {
    headers: headers,
  });
};

const totalManagerForDos = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/count-dos-manager/" + id, {
    headers: headers,
  });
};

const UserService = {
  login,
  getProfile,
  getUserState,
  getCityByStateId,
  validateRegister,
  validateUser,
  validateEmail,
  UpdateData,
  distroyLogin,
  forgotPass,
  changePass,
  checkToken,
  validateProfileEmail,
  getNameState,
  getNameCity,
  getNameStore,
  UpdateStoreInfo,
  UpdateUserInfo,
  UpdateUserInfoWithPass,
  totalRsa,
  totalRsaForDos,
  totalManager,
  totalManagerForDos,
  UpdateAdminInfo,
  UpdateAdminInfoWithPass,
};

export default UserService;
