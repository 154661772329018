import React, { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../Includes/Footer";
import SlidingBg from "../Includes/SlidingBg";
import ToTop from "../Includes/ToTop";
import "./Results.css";
import { UserContext } from "../../../context/UserContext";
import QuizService from "../../../services/quiz.service";
import { useLayoutEffect } from "react";
import { useState } from "react";
// import { UserContext } from "../../context/UserContext";
import Skeleton from "react-loading-skeleton";
import { toast, ToastContainer } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import CourseService from "../../../services/course.service";
import { Helmet } from "react-helmet";

export default function PassResult() {
  const TITLE = "ASU | View Result";

  const { code } = useParams();
  const { state } = useContext(UserContext);
  const { id, accessToken, uType } = state;
  const [classType, setClassType] = useState("");
  const [percentClass, setPercentClass] = useState("");
  const [type, setType] = useState("");
  const [percentage, setPercentage] = useState("");
  const [noteText, setNoteText] = useState("");
  const [desc, setDesc] = useState("");
  const [image, setImage] = useState("");
  const usId = JSON.stringify(id);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const getResult = async () => {
      const { data } = await QuizService.submitResult(usId, code);
      const { response: res } = data;

      setType(res.type);
      setPercentage(res.percentage);
      setNoteText(res.noteText);
      setDesc(res.description);

      setImage(res.image);
      setClassType(res.class);
      setPercentClass(res.percentageClass);
    };

    const getEligibility = async () => {
      try {
        const response = await CourseService.getEligibilityById(
          code,
          secureLocalStorage.getItem("_id")
        );

        if (response.status === 200) {
          getResult();
        }
      } catch (err) {
        if (err.response.status === 302) {
          toast.error("No Result avaliable", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (err.response.status === 404) {
          toast.error(err.response.data.error, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        setTimeout(() => {
          navigate("/welcome", { replace: true });
        }, 1500);
      }
    };
    if (
      accessToken &&
      uType !== "admin" &&
      uType !== "dos" &&
      uType !== "ndos"
    ) {
      getEligibility();
    }
  }, [code]);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="pad-bot">
        <ToTop />
        <SlidingBg />
        <ToastContainer limit={1} />
        <section className="slide-up">
          <div className="container">
            <div className="slides-here placeholder-glow">
              {type ? (
                <h2 className="slide-heading">
                  <span>RESULT: </span>
                  {type}
                </h2>
              ) : (
                <h2 className="slide-heading placeholder"></h2>
              )}

              <div className="review-que">
                <div className="row ">
                  <div className="col-lg-4 h-100">
                    {image ? (
                      <img
                        src={"/images/" + image}
                        className="img-fluid rzl-pa"
                      />
                    ) : (
                      <div className="placeholder w-100 h-100"></div>
                    )}
                  </div>
                  <div className="col-lg-8 h-100 d-flex flex-column justify-content-center">
                    <h3 className={` ${percentage ? "" : "placeholder"}`}>
                      Your Score:{" "}
                      <span className={percentClass}>{percentage}</span>
                    </h3>
                    <h3 className="mb-2 pb-1">{noteText}</h3>

                    <p className={`not-pass ${desc ? "" : "placeholder"}`}>
                      {desc}
                    </p>

                    <ul className="circle-list">
                      <li>
                        Remember, the more you know, the more you sell, the more
                        you earn!
                      </li>
                    </ul>
                    <Link to="/welcome" className="round-red-btn rezlt-bton">
                      TAKE ANOTHER COURSE
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}
