import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import QuizService from "../../../../services/quiz.service";
import "./KnowEnhance.css";

export default function KnowEnhance() {
  const { state } = useContext(UserContext);
  const { id } = state;
  const [passedLevel, setPassedLevel] = useState("");
  const [passedCounter, setPassedCounter] = useState("0");

  const [freshman, setFreshman] = useState([]);
  const [sophomore, setSophomore] = useState([]);
  const [junior, setJunior] = useState([]);
  const [senior, setSenior] = useState([]);

  useLayoutEffect(() => {
    const getPassedLevel = async () => {
      const { data } = await QuizService.getPassedLevel(id);
      const { response: res } = data;
      setPassedLevel(res);
      // console.log(res)
      // setPassedLevel("passed");
    };
    getPassedLevel();

    const getPassedCounter = async () => {
      const { data } = await QuizService.getPassedCounter(id);
      setPassedCounter(data.records);

      // setPassedLevel("passed");
    };
    getPassedCounter();
  }, [id]);

  useEffect(() => {
    const getFreshman = async () => {
      const { data } = await QuizService.getLevelsByMain("freshman", id);
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          key: value.id,
          code: value.code,
          value: value.course_name,
          status: value.status,
        });
      });
      setFreshman([...results]);
    };
    getFreshman();
  }, []);

  useEffect(() => {
    const getSophomore = async () => {
      const { data } = await QuizService.getLevelsByMain("sophomore", id);
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          key: value.id,
          code: value.code,
          value: value.course_name,
          status: value.status,
        });
      });
      setSophomore([...results]);
    };
    getSophomore();
  }, [id]);

  useEffect(() => {
    const getJunior = async () => {
      const { data } = await QuizService.getLevelsByMain("junior", id);
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          key: value.id,
          code: value.code,
          value: value.course_name,
          status: value.status,
        });
      });
      setJunior([...results]);
    };
    getJunior();
  }, [id]);

  useEffect(() => {
    const getSenior = async () => {
      const { data } = await QuizService.getLevelsByMain("senior", id);
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          key: value.id,
          code: value.code,
          value: value.course_name,
          status: value.status,
        });
      });
      setSenior([...results]);
    };
    getSenior();
  }, []);

  return (
    <div className="knwg-enhnc">
      <h5>ENHANCE YOUR KNOWLEDGE</h5>
      <div className="justify-content-center row">
        <div className="col-lg-4">
          <div className="ench-div" data-target="#Freshman">
            <img src="images/icon-1.png" alt="" />
            <div>
              <span>Freshman</span>
              <p>View More</p>
            </div>
            {passedLevel !== "sophomore" &&
            passedLevel !== "junior" &&
            passedLevel !== "senior" &&
            passedLevel !== "passed" ? (
              ""
            ) : (
              <img
                src="/images/done-level.png"
                alt="/images/done-level.png"
                className="completed-level"
              />
            )}
            <div className="menu-box" id="Freshman">
              <ul className={freshman.length % 2 === 0 ? "even" : "odd"}>
                {freshman.length ? (
                  freshman.map((res) => {
                    return (
                      <li key={res.key}>
                        <Link to={"/course/" + res.code}>
                          <span>{res.code}</span> {res.value}
                          {res.status === "no" ? (
                            ""
                          ) : (
                            <img
                              src="/images/done-level.png"
                              alt="/images/done-level.png"
                              className="completed-inner-level"
                            />
                          )}
                        </Link>
                      </li>
                    );
                  })
                ) : (
                  <li class="not-eligible w-100">
                    <strong class="text-black-50">Loading...</strong>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="ench-div" data-target="#Sophomore">
            <img src="images/icon-2.png" alt="" />
            <div>
              <span>Sophomore </span>
              <p>View More</p>
            </div>

            {passedLevel !== "junior" &&
            passedLevel !== "senior" &&
            passedLevel !== "passed" ? (
              ""
            ) : (
              <img
                src="/images/done-level.png"
                alt="/images/done-level.png"
                className="completed-level"
              />
            )}

            <div className="menu-box" id="Sophomore">
              <ul className={sophomore.length % 2 === 0 ? "even" : "odd"}>
                {passedLevel !== "sophomore" &&
                passedLevel !== "junior" &&
                passedLevel !== "senior" &&
                passedLevel !== "passed" ? (
                  <li className="w-100 not-eligible">
                    <strong>Sorry, You are not eligible for this Level</strong>
                  </li>
                ) : (
                  sophomore.map((res) => {
                    return (
                      <li key={res.key}>
                        <Link to={"/course/" + res.code}>
                          <span>{res.code}</span> {res.value}
                          {res.status === "no" ? (
                            ""
                          ) : (
                            <img
                              src="/images/done-level.png"
                              alt="/images/done-level.png"
                              className="completed-inner-level"
                            />
                          )}
                        </Link>
                      </li>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="ench-div" data-target="#Junior">
            <img src="images/icon-3.png" alt="" />
            <div>
              <span>Junior</span>
              <p>View More</p>
            </div>
            {passedLevel !== "senior" && passedLevel !== "passed" ? (
              ""
            ) : (
              <img
                src="/images/done-level.png"
                alt="/images/done-level.png"
                className="completed-level"
              />
            )}
            <div className="menu-box" id="Junior">
              <ul className={junior.length % 2 === 0 ? "even" : "odd"}>
                {passedLevel !== "junior" &&
                passedLevel !== "senior" &&
                passedLevel !== "passed" ? (
                  <li className="w-100 not-eligible">
                    <strong>Sorry, You are not eligible for this Level</strong>
                  </li>
                ) : (
                  junior.map((res) => {
                    return (
                      <li key={res.key}>
                        <Link to={"/course/" + res.code}>
                          <span>{res.code}</span> {res.value}
                          {res.status === "no" ? (
                            ""
                          ) : (
                            <img
                              src="/images/done-level.png"
                              alt="/images/done-level.png"
                              className="completed-inner-level"
                            />
                          )}
                        </Link>
                      </li>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="ench-div" data-target="#Senior">
            <img src="images/icon-4.png" alt="" />
            <div>
              <span>Senior</span>
              <p>View More</p>
            </div>
            {passedLevel !== "passed" ? (
              ""
            ) : (
              <img
                src="/images/done-level.png"
                alt="/images/done-level.png"
                className="completed-level"
              />
            )}
            <div className="menu-box" id="Senior">
              <ul className={senior.length % 2 === 0 ? "even" : "odd"}>
                {passedLevel !== "senior" && passedLevel !== "passed" ? (
                  <li className="w-100 not-eligible">
                    <strong>Sorry, You are not eligible for this Level</strong>
                  </li>
                ) : (
                  senior.map((res) => {
                    return (
                      <li key={res.key}>
                        <Link to={"/course/" + res.code}>
                          <span>{res.code}</span> {res.value}
                          {res.status === "no" ? (
                            ""
                          ) : (
                            <img
                              src="/images/done-level.png"
                              alt="/images/done-level.png"
                              className="completed-inner-level"
                            />
                          )}
                        </Link>
                      </li>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <Link to="/GetPassedResult" className="red-box">
            <span>View Passed Results [{passedCounter}] </span>
            You can search your required data
          </Link>
        </div>
      </div>
    </div>
  );
}
