import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { courseIntro } from "../../../schema";
import HeaderSidebar from "../includes/HeaderSidebar";
import ToTop from "../includes/ToTop";

export default function AddCourse() {
  const [loading, setLoading] = useState(true);
  const [introImg, setIntroImg] = useState("");
  const [bgImg, setBgImg] = useState("");
  const navigate = useNavigate();
  const [introImgError, setIntroImgError] = useState(false);
  const [bgImgError, setBgImgError] = useState(false);
  const [disable, setdisable] = useState(false);

  setTimeout(() => {
    setLoading(false);
  }, 1000);

  const handleIntroImgUpload = async (event) => {
    setIntroImg(event.target.files[0]);
  };
  const handleBgImgUpload = async (event) => {
    setBgImg(event.target.files[0]);
  };

  function next(values, action) {
    if (introImg === "" && bgImg === "") {
      setIntroImgError(true);
      setBgImgError(true);
      console.log(introImg);
    } else if (introImg !== "" && bgImg === "") {
      setBgImgError(true);
      setIntroImgError(false);
    } else if (introImg === "" && bgImg !== "") {
      setIntroImgError(true);
      setBgImgError(false);
    } else {
      setIntroImgError(false);
      setBgImgError(false);
      Swal.fire({
        title: "Added!",
        text: "You need to uploadm slides!",
        icon: "success",
        confirmButtonText: "Add Slide",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/admin/add_courses/slideTemplates");
        }
      });
      action.resetForm();
    }
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        title: "",
        question: "",
      },
      validationSchema: courseIntro,
      onSubmit: (values, action) => {
        next(values, action);
      },
    });

  return (
    <div className="semi-dark">
      <div className="wrapper">
        <HeaderSidebar />

        <main className="page-content">
          <div className="row">
            <div className="col">
              <div className="manage-heading-2">
                <h2>Add Courses</h2>
              </div>

              <div className="main-content-box position-relative">
                <h2 className="manage-territories-heading">
                  Add Course Introduction
                </h2>
                <form onSubmit={handleSubmit} noValidate>
                  <div className="row">
                    <div className="col-lg-12">
                      <input
                        type="text"
                        placeholder="Course Title"
                        className={`main-head ${
                          errors.title && touched.title ? "is-danger" : ""
                        }`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="title"
                        value={values.title || ""}
                        required
                      />
                      {errors.title && touched.title ? (
                        <p className="help is-danger">{errors.title}</p>
                      ) : null}
                    </div>

                    <div className="col-lg-4">
                      <label className="form-label">
                        Course background image:
                      </label>
                      <div className="control-fileupload ">
                        <input
                          type="file"
                          className="w-100 cursor-pointer "
                          accept=".jpg,.png,.svg,.webp"
                          onClick={(e) => {
                            e.target.value = null;
                            setBgImg("");
                          }}
                          onChange={handleBgImgUpload}
                        />
                      </div>
                      {bgImgError ? (
                        <p className="help is-danger">
                          Please select background image
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-4">
                      <label className="form-label">
                        Course introduction image:
                      </label>
                      <div className="control-fileupload ">
                        <input
                          type="file"
                          className="w-100 cursor-pointer "
                          accept=".jpg,.png,.svg,.webp"
                          onClick={(e) => {
                            e.target.value = null;
                            setIntroImg("");
                          }}
                          onChange={handleIntroImgUpload}
                        />
                      </div>
                      {introImgError ? (
                        <p className="help is-danger">
                          Please select introduction image
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-4">
                      <label className="form-label">Total Questions:</label>
                      <div className="form-floating">
                        <input
                          type="text"
                          placeholder="Number Of Questions"
                          className={`form-control ${
                            errors.question && touched.question
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="question"
                          value={values.question || ""}
                          required
                        />
                        <label>Number Of Questions</label>
                      </div>
                      {errors.question && touched.question ? (
                        <p className="help is-danger">{errors.question}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row justify-content-end">
                    <div className="col-lg-2 ">
                      <button
                        className="btn btn-primary d-block px-4 mt-lg-3 width-100 back-blue"
                        type="submit"
                        onClick={() => {
                          if (introImg === "" && bgImg === "") {
                            setIntroImgError(true);
                            setBgImgError(true);
                          } else if (introImg !== "" && bgImg === "") {
                            setBgImgError(true);
                            setIntroImgError(false);
                          } else if (introImg === "" && bgImg !== "") {
                            setIntroImgError(true);
                            setBgImgError(false);
                          } else {
                            setIntroImgError(false);
                            setBgImgError(false);
                          }
                        }}
                        disabled={disable}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>

        <ToTop />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </div>
  );
}
