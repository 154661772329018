export default function authHeader() {
  const token = localStorage.getItem("access-token").replaceAll('"','');

  if (token) {
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
}
