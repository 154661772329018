import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { UserContext } from "../../../context/UserContext";
import UserService from "../../../services/user.service";

export default function AdminCheckForgotToken() {
  const navigate = useNavigate();
  const { dispatch: ctxDispatch } = useContext(UserContext);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate("/admin");
    } else {
      CheckForgotToken(token);
    }
  }, [token]);

  const logOut = async () => {
    ctxDispatch({
      type: "LOGOUT",
    });
  };
  const CheckForgotToken = async (token) => {
    setLoading(true);
    try {
      const response = await UserService.checkToken(token);

      if (response.status === 200) {
        setLoading(false);
        ctxDispatch({
          type: "FORGOT",
          forgotToken: token,
        });

        navigate("/admin/change_pass");
        logOut();
      }
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
        Swal.fire({
          title: "Error!",
          text: err.response.statusText,
          icon: "error",
          confirmButtonText: "Try Again",
        });
        navigate("/admin");
      } else {
        setLoading(false);
        Swal.fire({
          title: "Error!",
          text: "Some thing went wronge",
          icon: "error",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  return (
    <div className={`loader ${loading ? "in" : ""}`}>
      <div className="spinner-border main-spin"></div>
    </div>
  );
}
