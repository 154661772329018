import React from "react";
import Footer from "../Includes/Footer";
import SlidingBg from "../Includes/SlidingBg";
import ToTop from "../Includes/ToTop";
import "./PrivacyTerms.css";
import { Helmet } from "react-helmet";

export default function PrivacyStatement() {
  const TITLE = "ASU | Privacy Statement";

  return (
    <div className="pad-bot">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ToTop />
      <SlidingBg />
      <section className="slide-up">
        <div className="container">
          <div className="slides-here">
            <h2 className="slide-heading">
              <span>PRIVACY </span>
              STATEMENT
            </h2>

            <div className="form-box terms-box">
              <h3 className="text-uppercase">
                Ashley sleep university PRIVACY STATEMENT
              </h3>
              <h4>Site Security Features</h4>
              <p>
                Ashley sleep university (ASU) realizes how important security is
                to our clients and to their participants, so we’ve taken a
                number of steps to enhance the protection of personal or
                confidential information sent to or from ASU or in accessing
                information resident at ASU. This includes username, password,
                and any debit card information. First, we require unique
                usernames and password establishment that are not easily
                determined by someone other than the intended participant. This
                requirement protects personal information and access to personal
                earnings that are available for redemption.
              </p>

              <h4>SSL Technology</h4>
              <p>
                ASU requires that a “secure session” is established, using
                Secure Socket Layer (SSL) technology. This is done anytime a
                participant supplies ASU with personal or confidential
                information in one of the secure areas of a website.
              </p>

              <p>
                SSL technology creates a private conversation that only the
                participant’s computer and ASU can understand. The SSL
                technology encodes information as it is being sent over the
                Internet between the participant’s computer and ER’s system
                helping to ensure that the transmitted information remains
                confidential.
              </p>

              <p>
                The use of SSL requires two components: and SSL-compatible
                browser and a Web server to perform “key-exchange” that
                establishes a secure connection to ASU web servers.
              </p>

              <h4>Browser Compliance</h4>
              <p>
                Participants and client will need a browser with SSL
                capabilities. Examples of SSL browsers include, Microsoft’s
                Internet Explorer, Firefox, Chrome and Safari. If a participant
                does not have a browser with SSL capabilities, the participant
                can download an SSL browser from the above mentioned SSL browser
                list and will be required in order to securely access personal
                or confidential information via the Internet. ASU codes sites to
                current browser version minus 1. ASU recommends the use of the
                latest browser versions available. Accessing secure online areas
                requires SSL capable browsers due to security concerns.
              </p>

              <h4>ISP Compliance</h4>
              <p>
                Nearly all Internet Service Providers (ISPs) automatically
                enable the SSL session described above. If a participant or a
                client contact uses their company's internal connection to
                access the Internet they may find they cannot access the ASU
                secure pages with an SSL browser described above, the company
                may be blocking access via a "firewall." The company’s Internet
                access systems administrator would have to be contacted for
                further details on Internet access..
              </p>

              <h4>Cookies</h4>
              <p>
                A participant or client must have enabled cookies on their
                browser in order to access confidential information. If they
                have chosen to disable cookies on their browser, they will not
                be able to access confidential information.
              </p>

              <h4>User ID and Password</h4>
              <p>
                Access to all ASU sites require the use of a Username and
                Password as a security measure that helps protect confidential
                information. This allows ASU to verify who is accessing the
                site, thereby allowing access to account information, and
                preventing unauthorized access.
              </p>
              <p className="mb-0">
                In establishing unique Username and Password, the following
                criteria should be adhered to:
              </p>

              <ul className="circle-list">
                <li>
                  The Username and Password cannot be the same (e.g. password /
                  password)
                </li>
                <li>
                  The Username and Password must be at least six (6) characters
                  in length.
                </li>
                <li>
                  Commonly used configurations or easily determined schema
                  should not be used (e.g. 123456 / 654321)
                </li>
                <li>
                  Usernames and Passwords should be personally protected the
                  same way debit or debit card PINs are treated.
                </li>
              </ul>

              <p>
                Non-compliance with these criteria may permit others to access
                the account and create mis-redemptions or fraud. ASU is not
                liable for accounts that do not adhere to these criteria.
              </p>
              <p>
                You should be aware that browser software often "caches" a page
                as you look at it, meaning that some pages are saved in your
                computer's temporary memory. Therefore, you may find that
                clicking on your "Back" button shows you a saved version of a
                previously viewed page. Caching in no way affects the security
                of your confidential Username or Password. If a participant or
                client uses a computer in a public place to access account
                information, they should simply quit/exit the browser software
                before leaving to minimize the possibility of anyone else
                viewing their confidential information.
              </p>

              <h4>Third Party Use</h4>
              <p>
                ASU does not sell, trade or rent personal information to third
                parties. We do, however, share your shipping information with
                our suppliers for the sole purpose of delivering your redemption
                items.
              </p>

              <h4>Secure Password Guidelines</h4>
              <p>
                The combination of username and password define the identity of
                users on a system. Adopting a{" "}
                <strong>
                  {" "}
                  good personal password policy is the most important barrier to
                  unauthorized access in current systems.
                </strong>
              </p>

              <p className="mb-0">Password Content</p>

              <ul className="circle-list">
                <li>
                  Mixture of numbers, capital letters, small letters,
                  punctuation.
                </li>
                <li>Easy to remember (don't need to write it down).</li>
                <li>Easy to type quickly (difficult for an observer).</li>
                <li>
                  Minimum acceptable format is six (6) characters in length.
                </li>
              </ul>

              <p className="mb-0">Examples</p>

              <ul className="circle-list">
                <li>
                  Choose a line or two of a poem, song etc. and use just the
                  first letters.
                </li>
                <li>Join two small words with a strange character.</li>
                <li>Invent an acronym.</li>
              </ul>

              <p className="mb-0">
                Password cracking software is fairly advanced and in a lot of
                cases relies on a users habit of choosing insecure passwords.
                Here are some common habits that should be avoided. Bad examples
              </p>

              <ul className="circle-list">
                <li>
                  Name of your spouse, parent, colleague, friend, pet, towns,
                  months, days.
                </li>
                <li>Number of car/motorbike registration, telephone.</li>
                <li>
                  Common dictionary words (French, German, English, Italian
                  etc).
                </li>
                <li>A series of identical numbers/letters.</li>
                <li>Obvious keyboard sequences.</li>
                <li>
                  Any of the above in inverse or with a number before or after.
                </li>
              </ul>

              <h4>Guidelines</h4>

              <ul className="circle-list">
                <li>Don't write it down, or disclose via email.</li>
                <li>Default passwords should not be used.</li>
                <li>Don't give your password to others.</li>
                <li>
                  If passwords are disclosed on a system, change them
                  immediately.
                </li>
                <li>Always change a default password.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
