import React, { useContext, useLayoutEffect, useState } from "react";
import Pagination from "react-js-pagination";

import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import AdminListService from "../../../services/admin-list.service";

import HeaderSidebar from "../includes/HeaderSidebar";
import ToTop from "../includes/ToTop";

import MoveTable from "../includes/MoveTable";
import AdminFooter from "../includes/AdminFooter";
import CheckDosPages from "../includes/CheckDosPages";
import { useFormik } from "formik";
import { quizSearch } from "../../../schema";
import { UserContext } from "../../../context/UserContext";

export default function ManageDosTerritory() {
  const { state } = useContext(UserContext);
  const { id } = state;
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState("");
  const [resultData, setResultData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("");
  const [totalPages, setTotalPages] = useState("");

  const changeLimit = (e) => {
    let newLimit = e;
    // console.log(limit);
    const getResultData = async () => {
      try {
        if (values.fieldtype === "" && values.searchval === "") {
          setLoading(true);

          setLimit(newLimit);

          const response = await AdminListService.getNewLimitDosTerritoriesList(
            newLimit,
            id
          );

          let resultData;
          if (response.status === 206) {
            setLoading(false);
            resultData = response.data.response;
            setResultData(resultData);
            setTotalResults(response.data.records);
            setCurrentPage(1);
            setTotalPages(response.data.total_pages);
            setLimit(response.data.per_page);
          } else {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          }
        } else if (values.fieldtype !== "" && values.searchval !== "") {
          setLoading(true);

          setLimit(newLimit);

          const response = await AdminListService.getSearchDosTerritoriesList(
            values.fieldtype,
            values.searchval,
            newLimit,
            id,
            1
          );

          let resultData;
          if (response.status === 206) {
            setLoading(false);
            resultData = response.data.response;

            setResultData(resultData);
            setTotalResults(response.data.records);
            setTotalPages(response.data.total_pages);
            setCurrentPage(1);
            setLimit(response.data.per_page);
          } else {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          }
        } else if (values.fieldtype !== "" || values.searchval !== "") {
          handleSubmit();
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setResultData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  };

  const changePagination = (e) => {
    // console.log(e)
    let pageNo = e;
    // setLimit(newLimit);
    // console.log(limit);
    const getResultData = async () => {
      try {
        if (values.fieldtype === "" && values.searchval === "") {
          setLoading(true);
          setCurrentPage(e);
          const response =
            await AdminListService.getPaginationDosTerritoriesList(
              pageNo,
              limit,
              id
            );

          let resultData;
          if (response.status === 206) {
            resultData = response.data.response;

            setResultData(resultData);
            setLimit(response.data.per_page);
            setLoading(false);
          }
        } else if (values.fieldtype !== "" && values.searchval !== "") {
          setLoading(true);
          setCurrentPage(e);
          const response = await AdminListService.getSearchDosTerritoriesList(
            values.fieldtype,
            values.searchval,
            limit,
            id,
            pageNo
          );

          let resultData;
          if (response.status === 206) {
            resultData = response.data.response;

            setResultData(resultData);
            setLimit(response.data.per_page);
            setLoading(false);
          }
        } else if (values.fieldtype !== "" || values.searchval !== "") {
          handleSubmit();
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  };

  const searchData = async (action) => {
    setLoading(true);

    try {
      const response = await AdminListService.getSearchDosTerritoriesList(
        values.fieldtype,
        values.searchval,
        limit,
        id,
        1
      );

      let resultData;
      if (response.status === 206) {
        setLoading(false);
        resultData = response.data.response;
        setResultData(resultData);
        setTotalResults(response.data.records);
        setTotalPages(response.data.total_pages);
        setCurrentPage(1);
        setLimit(response.data.per_page);
      } else {
        setTotalResults("0");
        setTotalPages("1");
        setResultData([]);
        setLoading(false);
      }
    } catch (err) {
      setTotalPages("1");
      setTotalResults("0");
      if (err.response.status === 500) {
        setLoading(false);
      } else {
        setLoading(false);
        setResultData([]);
      }
    }
  };

  useLayoutEffect(() => {
    const getTerritoriesData = async () => {
      setLoading(true);

      try {
        const response = await AdminListService.getDosTerritoriesList(id);

        let resultData;
        if (response.status === 206) {
          resultData = response.data.response;
          setResultData(resultData);
          setLimit(response.data.per_page);
          setTotalResults(response.data.records);
          setTotalPages(response.data.total_pages);
          setLoading(false);
        }
      } catch (err) {
        setTotalPages("1");
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };

    getTerritoriesData();
  }, []);

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table caption-top align-middle table-borderless table-style-1">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("id")}
                className={getClassNamesFor("id")}
              >
                No
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Store Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("address")}
                className={getClassNamesFor("address")}
              >
                Store Address
              </th>
              <th
                scope="col"
                onClick={() => requestSort("store_state")}
                className={getClassNamesFor("store_state")}
              >
                Store State
              </th>
              <th
                scope="col"
                onClick={() => requestSort("store_city")}
                className={getClassNamesFor("store_city")}
              >
                Store City
              </th>
              <th
                scope="col"
                onClick={() => requestSort("zip")}
                className={getClassNamesFor("zip")}
              >
                Zip
              </th>
              <th
                scope="col"
                onClick={() => requestSort("account")}
                className={getClassNamesFor("account")}
              >
                Account
              </th>
              <th
                scope="col"
                onClick={() => requestSort("dos_name")}
                className={getClassNamesFor("dos_name")}
              >
                DOS
              </th>

              <th
                scope="col"
                onClick={() => requestSort("visa_card")}
                className={getClassNamesFor("visa_card")}
              >
                Visa Card
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={el.id}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.name}</td>
                  <td>{el.address}</td>
                  <td>{el.store_state}</td>
                  <td>{el.store_city}</td>
                  <td>{el.zip}</td>

                  <td>{el.account}</td>
                  <td>{el.dos_name}</td>

                  <td style={{ textTransform: "capitalize" }}>
                    {el.visa_card}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values, action) => {
        searchData(action);
      },
    });

  return (
    <div className="semi-dark">
      <div className="wrapper">
        <CheckDosPages />
        <HeaderSidebar />
        <main className="page-content">
          <div className="row">
            <div className="col">
              <div className="manage-heading-2">
                <h2>
                  Manage DOS Territories <span>[{totalResults}]</span>
                </h2>
              </div>

              <div className="slides-here">
                <div className="alert alert-info">
                  <strong>Info!</strong> You can search your required data by
                  putting text in search box
                </div>
                <div className="main-content-box rzl-tabl">
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="row mb-3">
                      <div className="col-lg-3">
                        <select
                          className={`form-select ${
                            errors.fieldtype && touched.fieldtype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="fieldtype"
                          value={values.fieldtype || ""}
                          required
                        >
                          <option value="">Select</option>
                          <option value="1">Store Name</option>
                          <option value="2">Store Address </option>
                          <option value="3">Store State </option>
                          <option value="4">Store City </option>
                          <option value="5">Zip</option>
                          <option value="6">Account </option>
                          <option value="7">DOS </option>
                          <option value="8">Visa Card</option>
                        </select>
                        {errors.fieldtype && touched.fieldtype ? (
                          <p className="help is-danger">{errors.fieldtype}</p>
                        ) : null}
                      </div>

                      <div className="col-lg-4 ">
                        <div className="input-group">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="searchval"
                            value={values.searchval || ""}
                            required
                          />

                          <button
                            className="btn btn-outline-secondary sebmit-dat"
                            type="submit"
                          >
                            Search
                          </button>
                        </div>
                        {errors.searchval && touched.searchval ? (
                          <p className="help is-danger">{errors.searchval}</p>
                        ) : null}
                      </div>

                      <div className="col-lg-2 ">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => changeLimit(e.target.value)}
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                      <div className="col-lg-1 ">
                        <button
                          className="btn btn-outline-secondary"
                          type="reset"
                          onClick={() => window.location.reload(false)}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </form>
                  <MoveTable />
                  <div className="table-responsive">
                    <Table data={resultData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToTop />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
}
