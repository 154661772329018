import axios from "axios";
import React, { useLayoutEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { toast, ToastContainer } from "react-toastify";
import AdminListService from "../../../services/admin-list.service";
import AdminFooter from "../includes/AdminFooter";
import CheckUtype from "../includes/CheckUtype";

import HeaderSidebar from "../includes/HeaderSidebar";
import ToTop from "../includes/ToTop";

export default function UploadStores() {
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState(false);
  const [errorData, setErrorData] = useState([]);

  setTimeout(() => {
    setLoading(false);
  }, 1500);

  const handleUpload = async (event) => {
    setFile(event.target.files[0]);
  };

  const putStore = async () => {
    if (file === "") {
      setFileError(true);
    } else {
      setFileError(false);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("created_by", "123");
      formData.append("created_ip", secureLocalStorage.getItem("ip"));
      setLoading(true);

      try {
        const response = await AdminListService.uploadStoreList(formData);

        if (response.status === 200) {
          setLoading(false);

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setErrorData([]);
        }
      } catch (err) {
        if (err.response.status === 422) {
          if (err.response.data.data.failure !== "") {
            const errors = [];

            err.response.data.data.failure.map((value) => {
              errors.push({
                row: value.row,
                errors: value.errors.toString().replaceAll("_", " "),
              });
            });

            setErrorData([...errors]);
            toast.error(err.response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            setErrorData([]);

            toast.error(err.response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }

          setLoading(false);
        } else {
          toast.error("Some thing went wronge !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    }
  };

  useLayoutEffect(() => {
    const getIp = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");
      const weIp = res.data.IPv4;

      secureLocalStorage.setItem("ip", weIp);
    };
    getIp();
  }, []);
  return (
    <div className="semi-dark">
      <div className="wrapper">
        <CheckUtype />

        <HeaderSidebar />
        <ToastContainer />

        <main className="page-content">
          <div className="row">
            <div className="col">
              <div className="manage-heading-2">
                <h2>Upload Stores</h2>
              </div>

              <div className="slides-here">
                <div className="alert alert-info">
                  <b>Information!</b> You May Upload .xls, .xlsx files.{" "}
                </div>
                <div className="alert alert-primary">
                  <b>Note!</b> Address and zip should be unique. <b>DOS</b> and
                  <b> VP username</b> should be used in sheet
                </div>
                <div className="main-content-box">
                  {errorData.length ? (
                    <div className="error-msg">
                      {errorData.map((res, index) => {
                        return (
                          <div className="alert alert-danger " key={index}>
                            <b>Note!</b> Problem in row number {res.row} ,
                            <b> Error! </b>
                            {res.errors}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-lg-6">
                      <div
                        className={`control-fileupload ${
                          fileError ? "is-danger" : ""
                        }`}
                      >
                        <input
                          type="file"
                          className={`w-100 cursor-pointer ${
                            fileError ? "is-danger" : ""
                          }`}
                          id="file"
                          accept=".xlsx,.xls"
                          onClick={(e) => {
                            e.target.value = null;
                            setFile("");
                          }}
                          onChange={handleUpload}
                        />
                      </div>
                      {fileError ? (
                        <p className="help is-danger">Please Select File</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-2">
                      <button
                        type="submit"
                        className="btn btn-primary d-block    width-100 back-blue"
                        onClick={putStore}
                      >
                        Upload Stores
                      </button>
                    </div>
                    <div className="col-lg-4">
                      <a
                        className="btn-success btn btn-success-control width-100 back-blue"
                        href="/admin_assets/sample.xlsx"
                        download=""
                      >
                        <span>
                          {" "}
                          <i className="fadeIn animated bx bx-file"></i>{" "}
                        </span>
                        Download Store Sample Excel Sheet Here
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToTop />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
}
