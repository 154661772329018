import React, { useState } from "react";
import HeaderSidebar from "../../includes/HeaderSidebar";
import ToTop from "../../includes/ToTop";

export default function Templete_2() {
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 1000);
  return (
    <div className="semi-dark">
      <div className="wrapper">
        <HeaderSidebar />

        <main className="page-content">
          <div className="row">
            <div className="col">
              <div className="manage-heading-2">
                <h2>Add Courses</h2>
              </div>

              <div className="main-content-box position-relative">
                <h2 className="manage-territories-heading">Slide Templete 2</h2>
                <div className="row">
                  <div className="col-lg-12">
                    <input
                      type="text"
                      className="main-head"
                      placeholder="Title"
                    />
                    <ul className="circle-list" id="circle-list">
                      <li>
                        <div className=" input-group ">
                          <input
                            type="text"
                            className="form-control list-input"
                            placeholder="List data"
                          />
                          <div className="input-group-prepend">
                            <button className="list-item-add" type="button">
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-12">
                    <div className="drop-zone mt-4">
                      <span className="drop-zone__prompt">
                        Drop Image here or click to upload
                      </span>
                      <input
                        type="file"
                        accept=".jpg,.png,.svg,.webp"
                        name="myFile"
                        className="drop-zone__input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <ToTop />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </div>
  );
}
