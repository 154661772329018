import React, { useState } from "react";
import { Link } from "react-router-dom";
import HeaderSidebar from "../../includes/HeaderSidebar";
import ToTop from "../../includes/ToTop";

export default function SlideSelect() {
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 1000);
  return (
    <div className="semi-dark">
      <div className="wrapper">
        <HeaderSidebar />

        <main className="page-content">
          <div className="row">
            <div className="col">
              <div className="manage-heading-2">
                <h2>Slide Templates</h2>
              </div>

              <div className="main-content-box position-relative">
                <h2 className="manage-territories-heading">
                  Select any Templates
                </h2>
                <div className="row">
                  <div className="col-lg-4">
                    <Link to="/admin/add_courses/templete-1">
                      <img
                        src="/admin_assets/images/template-1.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="col-lg-4">
                    <Link to="/admin/add_courses/templete-2">
                      <img
                        src="/admin_assets/images/template-2.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="col-lg-4">
                    <Link to="/admin/add_courses/templete-3">
                      <img
                        src="/admin_assets/images/template-3.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="col-lg-4">
                    <Link to="/admin/add_courses/templete-4">
                      <img
                        src="/admin_assets/images/template-4.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="col-lg-4">
                    <Link to="/admin/add_courses/templete-5">
                      <img
                        src="/admin_assets/images/template-5.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="col-lg-4">
                    <Link to="/admin/add_courses/templete-6">
                      <img
                        src="/admin_assets/images/template-6.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <ToTop />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </div>
  );
}
