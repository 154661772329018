import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { UserContext } from "../../../context/UserContext";
import ToTop from "./ToTop";
import "./general.css";
import Swal from "sweetalert2";

export default function Header() {
  let locatEURL = useLocation();
  const navigate = useNavigate();
  const { dispatch: ctxDispatch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const logOut = async (event) => {
    event.preventDefault();

    setLoading(true);
    ctxDispatch({
      type: "LOGOUT",
    });
    setLoading(false);
    navigate("/");
  };

  const openAlert = async (event, path) => {
    event.preventDefault();
    Swal.fire({
      title: "LEAVING?",
      text: "Are you sure leaving this page!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a9e0",
      cancelButtonColor: "#e0876c",

      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(path, { replace: true });
      }
    });
  };

  const openAlertLogout = async (event) => {
    event.preventDefault();
    Swal.fire({
      title: "LEAVING?",
      text: "Are you sure leaving this page!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a9e0",
      cancelButtonColor: "#e0876c",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then((result) => {
      if (result.isConfirmed) {
        logOut(event);
      }
    });
  };
  var welcomeFname;
  if (secureLocalStorage.getItem("name")) {
    const welcomeName = secureLocalStorage.getItem("name");

    welcomeFname = welcomeName.split(" ");
  } else {
    const welcomeName = "Unknown Person";
    welcomeFname = welcomeName.split(" ");
  }

  return (
    <>
      <ToTop />
      <header className="main-header">
        <nav className="navbar navbar-expand-lg navbar-light ">
          <Link className="navbar-brand" to="/welcome">
            <img src="/images/logo.png" className="img-fluid" alt="" />
          </Link>
          <button
            className="navbar-toggler hmbrgr"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="round-black ms-auto">
              <div className="navbar-nav">
                <Link
                  className={`nav-link ${
                    locatEURL.pathname === "/welcome" ? "active" : ""
                  }`}
                  onClick={(e) => {
                    if (
                      locatEURL.pathname === "/quiz/" + id ||
                      locatEURL.pathname === "/course/" + id
                    ) {
                      openAlert(e, "/welcome");
                    }
                  }}
                  aria-current="page"
                  to="/welcome"
                >
                  Home
                </Link>
                <Link
                  className={`nav-link ${
                    locatEURL.pathname === "/profileInfo" ? "active" : ""
                  }`}
                  to="/profileInfo"
                  onClick={(e) => {
                    if (
                      locatEURL.pathname === "/quiz/" + id ||
                      locatEURL.pathname === "/course/" + id
                    ) {
                      openAlert(e, "/profileInfo");
                    }
                  }}
                >
                  Account
                </Link>
                <Link
                  className={`nav-link ${
                    locatEURL.pathname === "/faq" ? "active" : ""
                  }`}
                  to="/faq"
                  onClick={(e) => {
                    if (
                      locatEURL.pathname === "/quiz/" + id ||
                      locatEURL.pathname === "/course/" + id
                    ) {
                      openAlert(e, "/faq");
                    }
                  }}
                >
                  Faq's
                </Link>
                <Link
                  className={`nav-link ${
                    locatEURL.pathname === "/login" ? "active" : ""
                  }`}
                  to="/login"
                  onClick={(e) => {
                    if (
                      locatEURL.pathname === "/quiz/" + id ||
                      locatEURL.pathname === "/course/" + id
                    ) {
                      openAlertLogout(e);
                    } else {
                      logOut(e);
                    }
                  }}
                >
                  Logout
                </Link>
              </div>
              <div className="red-welcome">
                <div className="trianles">
                  <img
                    src="/images/lef-red.png"
                    className="lef-red"
                    alt="lef-red.png"
                  />
                  <img
                    src="/images/rig-red.png"
                    className="rig-red"
                    alt="rig-red.png"
                  />
                  <div className="welcome-txt">
                    Welcome - {welcomeFname[0] ? welcomeFname[0] : "Unknown"}
                    {/* Welcome - {welcomeName ? welcomeName : "Unknown"} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}
