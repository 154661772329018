import axios from "axios";
import { useFormik } from "formik";
import React, { useLayoutEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { toast, ToastContainer } from "react-toastify";
import { addStore } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";
import AdminFooter from "../includes/AdminFooter";
import CheckUtype from "../includes/CheckUtype";

import HeaderSidebar from "../includes/HeaderSidebar";
import ToTop from "../includes/ToTop";

export default function AddStore() {
  const [loading, setLoading] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [dosData, setDosData] = useState([]);
  const [ndosData, setNdosData] = useState([]);

  useLayoutEffect(() => {
    const getCityData = async () => {
      setLoading(true);

      try {
        const { data } = await AdminListService.getCitySelectOptions();

        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            key: value.name,
            value: value.id,
          });
        });

        setCityData([{ key: "Select City", value: "" }, ...results]);

        setLoading(false);
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    const getDosData = async () => {
      setLoading(true);

      try {
        const { data } = await AdminListService.getDosSelectOptions();

        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            key: value.first_name + " " + value.last_name,
            value: value.id,
          });
        });

        setDosData([{ key: "Select DOS", value: "" }, ...results]);

        setLoading(false);
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    const getNdosData = async () => {
      setLoading(true);

      try {
        const { data } = await AdminListService.getNdosSelectOptions();

        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            key: value.first_name + " " + value.last_name,
            value: value.id,
          });
        });

        setNdosData([{ key: "Select VP", value: "" }, ...results]);

        setLoading(false);
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };

    getCityData();
    getDosData();
    getNdosData();
  }, []);

  const getIp = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    const weIp = res.data.IPv4;
    secureLocalStorage.setItem("ip", weIp);
  };

  const putStore = async (values, action) => {
    setLoading(true);

    try {
      const response = await AdminListService.puttStoreList(values);

      if (response.status === 201) {
        setLoading(false);
        toast.success("Store Added !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 422) {
        toast.error("Unprocessable Content !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 409) {
        toast.error(err.response.data.response, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Some thing went wronge !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        storeName: "",
        storeAccount: "",
        storeAddress: "",
        zipCode: "",
        city: "",
        dos: "",
        ndos: "",
      },
      validationSchema: addStore,
      onSubmit: (values, action) => {
        putStore(values, action);
      },
    });

  useLayoutEffect(() => {
    getIp();
  }, [handleSubmit]);

  return (
    <div className="semi-dark">
      <div className="wrapper">
        <CheckUtype />
        <HeaderSidebar />
        <ToastContainer />
        <main className="page-content">
          <div className="row">
            <div className="col">
              <div className="manage-heading-2">
                <h2>Add Store</h2>
              </div>

              <div className="slides-here">
                <div className="alert alert-info">
                  Name,Address of the Store is required
                </div>
                <div className="main-content-box">
                  <div className="manage-territories-box">
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="row">
                        <div className="col-lg-6">
                          <label className="form-label">Store Name:</label>
                          <div className="form-floating">
                            <input
                              type="text"
                              placeholder="Store Name"
                              className={`form-control ${
                                errors.storeName && touched.storeName
                                  ? "is-danger"
                                  : ""
                              }`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="storeName"
                              value={values.storeName || ""}
                              required
                            />
                            <label>Store Name</label>
                            {errors.storeName && touched.storeName ? (
                              <p className="help is-danger">
                                {errors.storeName}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 mt-3 mt-lg-0">
                          <label className="form-label">Store Address:</label>
                          <div className="form-floating">
                            <input
                              type="text"
                              placeholder="Store Address"
                              className={`form-control ${
                                errors.storeAddress && touched.storeAddress
                                  ? "is-danger"
                                  : ""
                              }`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="storeAddress"
                              value={values.storeAddress || ""}
                              required
                            />
                            <label>Store Address</label>
                            {errors.storeAddress && touched.storeAddress ? (
                              <p className="help is-danger">
                                {errors.storeAddress}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 mt-3">
                          <label className="form-label">Select City:</label>
                          <select
                            className={`form-control form-select ${
                              errors.city && touched.city ? "is-danger" : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="city"
                            value={values.city || ""}
                            required
                          >
                            {cityData.map((res) => {
                              return (
                                <option key={res.value} value={res.value}>
                                  {res.key}
                                </option>
                              );
                            })}
                          </select>
                          {errors.city && touched.city ? (
                            <p className="help is-danger">{errors.city}</p>
                          ) : null}
                        </div>

                        <div className="col-lg-6 mt-3">
                          <label className="form-label">Select DOS:</label>
                          <select
                            className={`form-control form-select ${
                              errors.dos && touched.dos ? "is-danger" : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="dos"
                            value={values.dos || ""}
                            required
                          >
                            {dosData.map((res) => {
                              return (
                                <option key={res.value} value={res.value}>
                                  {res.key}
                                </option>
                              );
                            })}
                          </select>
                          {errors.dos && touched.dos ? (
                            <p className="help is-danger">{errors.dos}</p>
                          ) : null}
                        </div>

                        <div className="col-lg-6 mt-3">
                          <label className="form-label">Select VP:</label>
                          <select
                            className={`form-control form-select ${
                              errors.ndos && touched.ndos ? "is-danger" : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="ndos"
                            value={values.ndos || ""}
                            required
                          >
                            {ndosData.map((res) => {
                              return (
                                <option key={res.value} value={res.value}>
                                  {res.key}
                                </option>
                              );
                            })}
                          </select>
                          {errors.ndos && touched.ndos ? (
                            <p className="help is-danger">{errors.ndos}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-6 mt-3">
                          <label className="form-label">Store Account:</label>
                          <div className="form-floating">
                            <input
                              type="number"
                              placeholder="Store Account"
                              className={`form-control ${
                                errors.storeAccount && touched.storeAccount
                                  ? "is-danger"
                                  : ""
                              }`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="storeAccount"
                              value={values.storeAccount || ""}
                              required
                            />
                            <label>Store Account</label>
                            {errors.storeAccount && touched.storeAccount ? (
                              <p className="help is-danger">
                                {errors.storeAccount}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 mt-3">
                          <label className="form-label">Store Zip:</label>
                          <div className="form-floating">
                            <input
                              type="text"
                              placeholder="Zip Code"
                              className={`form-control ${
                                errors.zipCode && touched.zipCode
                                  ? "is-danger"
                                  : ""
                              }`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="zipCode"
                              value={values.zipCode || ""}
                              required
                            />
                            <label>Store Zip</label>
                            {errors.zipCode && touched.zipCode ? (
                              <p className="help is-danger">{errors.zipCode}</p>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-2 mt-lg-3">
                          <button
                            type="submit"
                            className="btn btn-primary d-block px-4 mt-30 width-100 back-blue"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToTop />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
}
