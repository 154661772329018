import axios from "axios";
import { useFormik } from "formik";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { toast, ToastContainer } from "react-toastify";
import { checkBoxSchema } from "../../../schema";
import CourseService from "../../../services/course.service";
import Footer from "../Includes/Footer";
import SlidingBg from "../Includes/SlidingBg";
import ToTop from "../Includes/ToTop";
import $ from "jquery";
import "./Quiz.css";
import { Helmet } from "react-helmet";
import { UserContext } from "../../../context/UserContext";

export default function Quiz() {
  const { state } = useContext(UserContext);
  const { accessToken } = state;
  const { id } = useParams();
  const navigate = useNavigate();
  const [maxToast, setMaxToast] = useState(10);

  const [courseFname, setCourseFname] = useState("");
  const [courseLname, setCourseLname] = useState("");
  const [loading, setLoading] = useState(false);
  const [quizSlide, setQuizSlide] = useState([]);
  const [quizAns, setQuizAns] = useState("");
  const [quesId, setQuesId] = useState("");
  const [disable, setDisable] = useState(false);

  useLayoutEffect(() => {
    const getCourse = async () => {
      setLoading(true);
      try {
        const { status, data } = await CourseService.getCourseNameById(id);
        if (status === 200) {
          setCourseFname(data.fname);

          setCourseLname(data.lname);

          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    };

    const getQuiz = async () => {
      setLoading(true);
      try {
        const { status, data } = await CourseService.getQuizById(id);
        if (status === 200) {
          const { response: res } = data;

          const results = [];

          res.map((value) => {
            results.push({
              key: value.id,
              quesnum: res.length,
              statement: value.statement,
              choice1: value.choice1,
              choice2: value.choice2,
              choice3: value.choice3,
              choice4: value.choice4,
              choice5: value.choice5,
              choice6: value.choice6,
              type: value.type,
              corect: value.correct_ans,
            });
          });
          setQuizSlide([...results]);
        }
      } catch (err) {
        setLoading(false);
      }
    };

    if (accessToken) {
      const getEligibility = async () => {
        setLoading(true);
        try {
          const response = await CourseService.getEligibilityById(
            id,
            secureLocalStorage.getItem("_id")
          );

          if (response.status === 200) {
            getCourse();
            getQuiz();
          }
        } catch (err) {
          setMaxToast("1");
          if (err.response.status === 302) {
            toast.error("You are not eligible for this quiz", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else if (err.response.status === 404) {
            toast.error(err.response.data.error, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          setLoading(false);
          setTimeout(() => {
            navigate("/welcome", { replace: true });
          }, 1500);
        }
      };
      getEligibility();
    }
  }, []);

  const checkAns = async () => {
    setDisable(true);

    try {
      const response = await CourseService.getQuizAnsById(
        secureLocalStorage.getItem("_id"),
        quesId,
        quizAns,
        id
      );

      if (response.status === 200) {
        toast.success("Correct Answer!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          run();
          setDisable(false);
        }, 1000);
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast.error("Incorrect Answer!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          run();
          setDisable(false);
        }, 1000);
      } else if (err.response.status === 422) {
        setDisable(false);

        toast.error("Error, Unprocessable Content!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setDisable(false);

        toast.error("Error, Some thing went wronge!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const handleSubmitRadio = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();

      form.classList.add("was-validated");
    } else {
      event.preventDefault();
      checkAns();
    }
  };

  const checkAnsMultiple = async (quizMultiAns) => {
    setDisable(true);

    try {
      const response = await CourseService.getQuizAnsById(
        secureLocalStorage.getItem("_id"),
        quesId,
        quizMultiAns,
        id
      );

      if (response.status === 200) {
        toast.success("Correct Answer!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          run();
          setDisable(false);
        }, 1000);
      }
    } catch (err) {
      if (err.response.status === 404) {
        toast.error("Incorrect Answer!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          run();
          setDisable(false);
        }, 1000);
      } else if (err.response.status === 422) {
        setDisable(false);

        toast.error("Error, Unprocessable Content!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setDisable(false);

        toast.error("Error, Some thing went wronge!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        checkBoxAns: [],
      },
      validationSchema: checkBoxSchema,
      onSubmit: (values, action) => {
        var divLength = values.checkBoxAns;

        const answersAll = divLength.join("@");
        var selectedAnswer = $(".tab-pane.active input:checkbox:checked")
          .map(function () {
            return this.value;
          })
          .get()
          .join("@");
        checkAnsMultiple(selectedAnswer);
      },
    });
  const getIp = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");

    const weIp = res.data.IPv4;
    secureLocalStorage.setItem("ip", weIp);
  };
  useEffect(() => {
    getIp();
  }, [handleSubmit]);

  const run = window["nextQuiz"];

  return (
    <>
      <Helmet>
        <title>ASU | {courseFname + courseLname}</title>
      </Helmet>
      <div className="pad-bot">
        <ToastContainer limit={maxToast} />
        <ToTop />
        <SlidingBg />
        <section className="slide-up">
          <div className="container">
            {!loading ? (
              <div className="tab-content">
                {quizSlide.map((res, index) => {
                  if (index === 0) {
                    return (
                      <div key={res.key}>
                        <div
                          className="tab-pane fade active in show"
                          id="first"
                        >
                          <div className="slides-here">
                            <h2 className="slide-heading">
                              <span>{courseFname} </span>
                              {courseLname}
                            </h2>
                            <div className="review-que">
                              <h3>
                                <span>{index + 1}:</span> {res.statement}
                                {res.type === "radio"
                                  ? ""
                                  : "(select all that apply)"}
                              </h3>
                              <form
                                onSubmit={
                                  res.type === "radio"
                                    ? handleSubmitRadio
                                    : handleSubmit
                                }
                                noValidate
                              >
                                {res.choice1 !== "" ? (
                                  <>
                                    <input
                                      id={
                                        res.choice1.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom"
                                      name={
                                        res.type === "radio"
                                          ? "radio-group"
                                          : "checkBoxAns"
                                      }
                                      onChange={(e) => {
                                        if (res.type === "radio") {
                                          setQuizAns(e.target.value);
                                          setQuesId(res.key);
                                        } else {
                                          handleChange(e);
                                          setQuesId(res.key);
                                        }
                                      }}
                                      onBlur={
                                        res.type === "radio" ? null : handleBlur
                                      }
                                      type={res.type}
                                      value={res.choice1}
                                      required
                                    />

                                    <label
                                      htmlFor={
                                        res.choice1.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom-label"
                                    >
                                      {res.choice1}
                                    </label>
                                  </>
                                ) : null}

                                {res.choice2 !== "" ? (
                                  <>
                                    <input
                                      id={
                                        res.choice2.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom"
                                      name={
                                        res.type === "radio"
                                          ? "radio-group"
                                          : "checkBoxAns"
                                      }
                                      onChange={(e) => {
                                        if (res.type === "radio") {
                                          setQuizAns(e.target.value);
                                          setQuesId(res.key);
                                        } else {
                                          handleChange(e);
                                          setQuesId(res.key);
                                        }
                                      }}
                                      onBlur={
                                        res.type === "radio" ? null : handleBlur
                                      }
                                      type={res.type}
                                      value={res.choice2}
                                      required
                                    />
                                    <label
                                      htmlFor={
                                        res.choice2.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom-label"
                                    >
                                      {res.choice2}
                                    </label>
                                  </>
                                ) : null}

                                {res.choice3 !== "" ? (
                                  <>
                                    <input
                                      id={
                                        res.choice3.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom"
                                      name={
                                        res.type === "radio"
                                          ? "radio-group"
                                          : "checkBoxAns"
                                      }
                                      onChange={(e) => {
                                        if (res.type === "radio") {
                                          setQuizAns(e.target.value);
                                          setQuesId(res.key);
                                        } else {
                                          handleChange(e);
                                          setQuesId(res.key);
                                        }
                                      }}
                                      onBlur={
                                        res.type === "radio" ? null : handleBlur
                                      }
                                      type={res.type}
                                      value={res.choice3}
                                      required
                                    />
                                    <label
                                      htmlFor={
                                        res.choice3.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom-label"
                                    >
                                      {res.choice3}
                                    </label>
                                  </>
                                ) : null}

                                {res.choice4 !== "" ? (
                                  <>
                                    <input
                                      id={
                                        res.choice4.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom"
                                      name={
                                        res.type === "radio"
                                          ? "radio-group"
                                          : "checkBoxAns"
                                      }
                                      onChange={(e) => {
                                        if (res.type === "radio") {
                                          setQuizAns(e.target.value);
                                          setQuesId(res.key);
                                        } else {
                                          handleChange(e);
                                          setQuesId(res.key);
                                        }
                                      }}
                                      onBlur={
                                        res.type === "radio" ? null : handleBlur
                                      }
                                      type={res.type}
                                      value={res.choice4}
                                      required
                                    />
                                    <label
                                      htmlFor={
                                        res.choice4.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom-label"
                                    >
                                      {res.choice4}
                                    </label>
                                  </>
                                ) : null}

                                {res.choice5 !== "" ? (
                                  <>
                                    <input
                                      id={res.choice5.replace(/\s+/g, "")}
                                      className="radio-custom"
                                      name={
                                        res.type === "radio"
                                          ? "radio-group"
                                          : "checkBoxAns"
                                      }
                                      onChange={(e) => {
                                        if (res.type === "radio") {
                                          setQuizAns(e.target.value);
                                          setQuesId(res.key);
                                        } else {
                                          handleChange(e);
                                          setQuesId(res.key);
                                        }
                                      }}
                                      onBlur={
                                        res.type === "radio" ? null : handleBlur
                                      }
                                      type={res.type}
                                      value={res.choice5}
                                      required
                                    />
                                    <label
                                      htmlFor={res.choice5.replace(/\s+/g, "")}
                                      className="radio-custom-label"
                                    >
                                      {res.choice5}
                                    </label>
                                  </>
                                ) : null}

                                {res.choice6 !== "" ? (
                                  <>
                                    <input
                                      id={res.choice6.replace(/\s+/g, "")}
                                      className="radio-custom"
                                      name={
                                        res.type === "radio"
                                          ? "radio-group"
                                          : "checkBoxAns"
                                      }
                                      onChange={(e) => {
                                        if (res.type === "radio") {
                                          setQuizAns(e.target.value);
                                          setQuesId(res.key);
                                        } else {
                                          handleChange(e);
                                          setQuesId(res.key);
                                        }
                                      }}
                                      onBlur={
                                        res.type === "radio" ? null : handleBlur
                                      }
                                      type={res.type}
                                      value={res.choice6}
                                      required
                                    />
                                    <label
                                      htmlFor={res.choice6.replace(/\s+/g, "")}
                                      className="radio-custom-label"
                                    >
                                      {res.choice6}
                                    </label>
                                  </>
                                ) : null}
                                {res.type === "radio" ? (
                                  <div className="invalid-feedback">
                                    You must select an answer
                                  </div>
                                ) : null}
                                {res.type === "checkbox" &&
                                errors.checkBoxAns &&
                                touched.checkBoxAns ? (
                                  <p className="help is-danger">
                                    {errors.checkBoxAns}
                                  </p>
                                ) : null}

                                <button
                                  type="submit"
                                  className="round-red-btn "
                                  disabled={disable}
                                >
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  NEXT
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={res.key}>
                        <div className="tab-pane fade " id={res.key}>
                          <div className="slides-here">
                            <h2 className="slide-heading">
                              <span>{courseFname} </span>
                              {courseLname}
                            </h2>
                            <div className="review-que">
                              <h3>
                                <span>{index + 1}:</span> {res.statement}{" "}
                                {res.type === "radio"
                                  ? ""
                                  : "(select all that apply)"}
                              </h3>
                              <form
                                onSubmit={
                                  res.type === "radio"
                                    ? handleSubmitRadio
                                    : handleSubmit
                                }
                                noValidate
                              >
                                {res.choice1 !== "" ? (
                                  <>
                                    <input
                                      id={
                                        res.choice1.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom"
                                      name={
                                        res.type === "radio"
                                          ? "radio-group"
                                          : "checkBoxAns"
                                      }
                                      onChange={(e) => {
                                        if (res.type === "radio") {
                                          setQuizAns(e.target.value);
                                          setQuesId(res.key);
                                        } else {
                                          handleChange(e);
                                          setQuesId(res.key);
                                        }
                                      }}
                                      onBlur={
                                        res.type === "radio" ? null : handleBlur
                                      }
                                      type={res.type}
                                      value={res.choice1}
                                      required
                                    />
                                    <label
                                      htmlFor={
                                        res.choice1.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom-label"
                                    >
                                      {res.choice1}
                                    </label>
                                  </>
                                ) : null}

                                {res.choice2 !== "" ? (
                                  <>
                                    <input
                                      id={
                                        res.choice2.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom"
                                      name={
                                        res.type === "radio"
                                          ? "radio-group"
                                          : "checkBoxAns"
                                      }
                                      onChange={(e) => {
                                        if (res.type === "radio") {
                                          setQuizAns(e.target.value);
                                          setQuesId(res.key);
                                        } else {
                                          handleChange(e);
                                          setQuesId(res.key);
                                        }
                                      }}
                                      onBlur={
                                        res.type === "radio" ? null : handleBlur
                                      }
                                      type={res.type}
                                      value={res.choice2}
                                      required
                                    />
                                    <label
                                      htmlFor={
                                        res.choice2.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom-label"
                                    >
                                      {res.choice2}
                                    </label>
                                  </>
                                ) : null}

                                {res.choice3 !== "" ? (
                                  <>
                                    <input
                                      id={
                                        res.choice3.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom"
                                      name={
                                        res.type === "radio"
                                          ? "radio-group"
                                          : "checkBoxAns"
                                      }
                                      onChange={(e) => {
                                        if (res.type === "radio") {
                                          setQuizAns(e.target.value);
                                          setQuesId(res.key);
                                        } else {
                                          handleChange(e);
                                          setQuesId(res.key);
                                        }
                                      }}
                                      onBlur={
                                        res.type === "radio" ? null : handleBlur
                                      }
                                      type={res.type}
                                      value={res.choice3}
                                      required
                                    />
                                    <label
                                      htmlFor={
                                        res.choice3.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom-label"
                                    >
                                      {res.choice3}
                                    </label>
                                  </>
                                ) : null}

                                {res.choice4 !== "" ? (
                                  <>
                                    <input
                                      id={
                                        res.choice4.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom"
                                      name={
                                        res.type === "radio"
                                          ? "radio-group"
                                          : "checkBoxAns"
                                      }
                                      onChange={(e) => {
                                        if (res.type === "radio") {
                                          setQuizAns(e.target.value);
                                          setQuesId(res.key);
                                        } else {
                                          handleChange(e);
                                          setQuesId(res.key);
                                        }
                                      }}
                                      onBlur={
                                        res.type === "radio" ? null : handleBlur
                                      }
                                      type={res.type}
                                      value={res.choice4}
                                      required
                                    />
                                    <label
                                      htmlFor={
                                        res.choice4.replace(/\s+/g, "") +
                                        res.key
                                      }
                                      className="radio-custom-label"
                                    >
                                      {res.choice4}
                                    </label>
                                  </>
                                ) : null}

                                {res.choice5 !== "" ? (
                                  <>
                                    <input
                                      id={res.choice5.replace(/\s+/g, "")}
                                      className="radio-custom"
                                      name={
                                        res.type === "radio"
                                          ? "radio-group"
                                          : "checkBoxAns"
                                      }
                                      onChange={(e) => {
                                        if (res.type === "radio") {
                                          setQuizAns(e.target.value);
                                          setQuesId(res.key);
                                        } else {
                                          handleChange(e);
                                          setQuesId(res.key);
                                        }
                                      }}
                                      onBlur={
                                        res.type === "radio" ? null : handleBlur
                                      }
                                      type={res.type}
                                      value={res.choice5}
                                      required
                                    />
                                    <label
                                      htmlFor={res.choice5.replace(/\s+/g, "")}
                                      className="radio-custom-label"
                                    >
                                      {res.choice5}
                                    </label>
                                  </>
                                ) : null}

                                {res.choice6 !== "" ? (
                                  <>
                                    <input
                                      id={res.choice6.replace(/\s+/g, "")}
                                      className="radio-custom"
                                      name={
                                        res.type === "radio"
                                          ? "radio-group"
                                          : "checkBoxAns"
                                      }
                                      onChange={(e) => {
                                        if (res.type === "radio") {
                                          setQuizAns(e.target.value);
                                          setQuesId(res.key);
                                        } else {
                                          handleChange(e);
                                          setQuesId(res.key);
                                        }
                                      }}
                                      onBlur={
                                        res.type === "radio" ? null : handleBlur
                                      }
                                      type={res.type}
                                      value={res.choice6}
                                      required
                                    />
                                    <label
                                      htmlFor={res.choice6.replace(/\s+/g, "")}
                                      className="radio-custom-label"
                                    >
                                      {res.choice6}
                                    </label>
                                  </>
                                ) : null}
                                {res.type === "radio" ? (
                                  <div className="invalid-feedback">
                                    You must select an answer
                                  </div>
                                ) : null}
                                {res.type === "checkbox" &&
                                errors.checkBoxAns &&
                                touched.checkBoxAns ? (
                                  <p className="help is-danger">
                                    {errors.checkBoxAns}
                                  </p>
                                ) : null}

                                <button
                                  type="submit"
                                  className="round-red-btn "
                                  disabled={disable}
                                >
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  NEXT
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
                <div>
                  <div className="tab-pane fade " id="thr">
                    <div className="slides-here">
                      <h2 className="slide-heading">
                        <span>{courseFname} </span>
                        {courseLname}
                      </h2>

                      <div className="back-slid thank-bg">
                        <div className="text-contain">
                          <div className="row">
                            <div className="col-lg-7 inc-z">
                              <div className="content-div">
                                <h3>THANK YOU</h3>
                                <p>
                                  Thank you for completing the quiz. Click on
                                  the button below to view your result
                                </p>
                                <Link
                                  to={"/passResult/" + id}
                                  className="round-red-btn "
                                >
                                  View Result
                                </Link>
                              </div>
                            </div>
                            <div className="col-lg-5 inc-z">
                              <div className="round-with-bar">
                                <div className="round-bor">
                                  <img
                                    src="/images/round-img-tahnku.jpg"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="tab-content placeholder-glow bg-light">
                <div className="review-que placeholder bg-dark"></div>
              </div>
            )}
          </div>
        </section>
        <Footer />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </>
  );
}
