import axios from "axios";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { updateStoreSchema, updateUserSchema } from "../../../schema";
import UserService from "../../../services/user.service";
import Footer from "../Includes/Footer";
import InputField from "../Includes/InputField";
import SlidingBg from "../Includes/SlidingBg";
import ToTop from "../Includes/ToTop";
import secureLocalStorage from "react-secure-storage";
import { UserContext } from "../../../context/UserContext";
import { useNavigate } from "react-router-dom";

import StoreDetailUp from "../Includes/StoreDetailUp";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import Select from "react-select";

export default function ProfileInfo() {
  const TITLE = "ASU | Profile";
  const navigate = useNavigate();

  const { state, dispatch: ctxDispatch } = useContext(UserContext);
  const { id } = state;

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");

  const [zip, setZip] = useState("");
  const [disable, setDisable] = useState(false);
  const [userCityLoader, setUserCityLoader] = useState(false);
  const [userState, setUserState] = useState([]);
  const [userCity, setUserCity] = useState([]);
  const [usererror, setUsererror] = useState("");
  const [emailerror, setEmailerror] = useState("");
  const [loading, setLoading] = useState(false);

  const [userSelectedState, setSelectedUserState] = useState("");
  const [userSelectedCity, setSelectedUserCity] = useState("");
  const [userSelectedStateText, setSelectedUserStateText] = useState("");
  const [userSelectedCityText, setSelectedUserCityText] = useState("");

  useEffect(() => {
    const getUserCity = async (val) => {
      try {
        setUserCityLoader(true);
        const { data } = await UserService.getCityByStateId(val);
        const { response: res } = data;
        const results = [];
        res.map((value) => {
          results.push({
            label: value.city,
            value: value.id,
          });
        });
        setUserCity([...results]);
        setUserCityLoader(false);
      } catch (error) {
        setUserCity([]);
        setUserCityLoader(false);
      }
    };

    const getProfile = async () => {
      setLoading(true);
      try {
        const response = await UserService.getProfile();

        if (response.status === 200) {
          ctxDispatch({
            type: "UPDATE_PROFILE",
            id: response.data.id,
            uName: response.data.username,
            uType: response.data.user_type,
            name: response.data.first_name + " " + response.data.last_name,
          });
          setFname(response.data.first_name);
          setLname(response.data.last_name);
          setEmail(response.data.email);
          setZip(response.data.zip);
          setSelectedUserState(response.data.state_id);
          setSelectedUserCity(response.data.city_id);
          setSelectedUserStateText(response.data.state);
          setSelectedUserCityText(response.data.city);
          getUserCity(response.data.state_id);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        ctxDispatch({
          type: "LOGOUT",
        });
        setLoading(false);
        navigate("/");
      }
    };

    const getUserState = async () => {
      const { data } = await UserService.getUserState();
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          label: value.name,
          value: value.id,
        });
      });
      setUserState([...results]);
    };

    getProfile();
    getUserState();
    // getUserCity();
  }, [ctxDispatch, id, navigate]);

  const changeUserCity = (state) => {
    setUserCity([]);

    const getUserCity = async () => {
      try {
        setUserCityLoader(true);
        const { data } = await UserService.getCityByStateId(state);
        const { response: res } = data;
        const results = [];
        res.map((value) => {
          results.push({
            label: value.city,
            value: value.id,
          });
        });
        setUserCity([...results]);
        setUserCityLoader(false);
      } catch (error) {
        setUserCityLoader(false);
      }
    };

    if (state !== "") {
      getUserCity();
    }
  };

  const UpdateUserData = async (values) => {
    try {
      if (values.password === "") {
        setLoading(true);
        const response = await UserService.UpdateUserInfo(values, id);
        const { data: res } = response;

        if (response.status === 200) {
          ctxDispatch({
            type: "UPDATE_NAME",
            name: res.response.first_name + " " + res.response.last_name,
          });

          setLoading(false);

          toast.success("Your contact information has been updated!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else if (values.password !== "") {
        setLoading(true);
        const response = await UserService.UpdateUserInfoWithPass(
          values,
          id,
          values.password
        );

        if (response.status === 200) {
          setLoading(false);

          toast.success("Your contact information has been updated!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    } catch (err) {
      if (err.response.status === 401) {
        setLoading(false);

        toast.error("Error, Try Again Later!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 422) {
        setLoading(false);
        toast.error("Error, Unprocessable Content!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoading(false);

        toast.error("Error, Some thing went wronge!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,

    initialValues: {
      username: secureLocalStorage.getItem("uName"),
      password: "",
      confirm_password: "",
      fname: fname,
      lname: lname,
      email: email,
      zipcode: zip,
      stateprov2: userSelectedState,
      city2: userSelectedCity,
    },
    validationSchema: updateUserSchema,
    onSubmit: (values, action) => {
      if (emailerror === "") {
        UpdateUserData(values);
      }
    },
  });

  const checkuser = async () => {
    if (values.username !== "") {
      setDisable(true);
      try {
        const response = await UserService.validateUser(values);

        if (response.status === 200) {
          setDisable(false);

          setUsererror("");
          // checkemail();
        }
      } catch (err) {
        if (err.response.status === 409) {
          setDisable(false);
          setUsererror(err.response.data.response);
          // Swal.fire({
          // title: 'Error!',
          // text: err.response.data.response,
          // icon: 'error',
          // confirmButtonText: 'Change User Name'
          // })
        } else {
          Swal.fire({
            title: "Error!",
            text: "Some thing went wronge",
            icon: "error",
            confirmButtonText: "Try Again",
          });
        }
      }
    } else {
      setDisable(false);

      setUsererror("");
    }
  };

  const checkemail = async (action) => {
    if (values.email !== "") {
      setDisable(true);
      try {
        const response = await UserService.validateProfileEmail(values, id);

        if (response.status === 200) {
          setDisable(false);

          setEmailerror("");
          // register(action);
        }
      } catch (err) {
        if (err.response.status === 409) {
          setDisable(true);
          setEmailerror(err.response.data.response);
          // Swal.fire({
          //     title: 'Error!',
          //     text: err.response.data.response,
          //     icon: 'error',
          //     confirmButtonText: 'Change Email'
          //     })
        } else {
          setDisable(false);
          Swal.fire({
            title: "Error!",
            text: "Some thing went wronge",
            icon: "error",
            confirmButtonText: "Try Again",
          });
        }
      }
    } else {
      setDisable(false);

      setEmailerror("");
    }
  };

  useEffect(() => {
    const getIp = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");

      const weIp = res.data.IPv4;
      secureLocalStorage.setItem("ip", weIp);
    };
    getIp();
  }, [handleSubmit]);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="pad-bot">
        <ToastContainer />

        <ToTop />
        <SlidingBg />

        <section className="slide-up">
          <div className="container">
            <div className="slides-here">
              <h2 className="slide-heading">
                <span>YOUR ACCOUNT </span>
                SETTINGS
              </h2>
              <StoreDetailUp />

              <form onSubmit={handleSubmit} noValidate>
                <ul className="timeline">
                  <li>
                    <div className="prfil-set">
                      <div className="form-box">
                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="prf-hed">MY CONTACT INFORMATION</h4>{" "}
                          </div>
                        </div>
                        <div className="form-filds">
                          <div className="row">
                            <div className="col-lg-4">
                              <InputField
                                errors={errors.fname}
                                touched={touched.fname}
                                values={values.fname}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="FIRST NAME"
                                spanText="FIRST NAME"
                                fieldName="fname"
                                fieldType="text"
                                required={true}
                              />
                            </div>
                            <div className="col-lg-4">
                              <InputField
                                errors={errors.lname}
                                touched={touched.lname}
                                values={values.lname}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="LAST NAME"
                                spanText="LAST NAME"
                                fieldName="lname"
                                fieldType="text"
                                required={true}
                              />
                            </div>
                            <div className="col-lg-4">
                              <InputField
                                errors={errors.email}
                                checkEmail={checkemail}
                                touched={touched.email}
                                values={values.email}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="E-MAIL"
                                spanText="E-MAIL"
                                fieldName="email"
                                fieldType="email"
                                required={true}
                              />

                              {emailerror ? (
                                <p className="help is-danger">{emailerror}</p>
                              ) : null}
                            </div>
                            <div className="col-lg-4">
                              <div
                                className={`form-floating ${
                                  errors.stateprov2 && touched.stateprov2
                                    ? "is-danger"
                                    : ""
                                }`}
                              >
                                <Select
                                  className={
                                    errors.stateprov2 && touched.stateprov2
                                      ? "error-select-search"
                                      : ""
                                  }
                                  placeholder={
                                    userSelectedStateText !== ""
                                      ? userSelectedStateText
                                      : "SELECT STATE"
                                  }
                                  options={userState}
                                  isSearchable={true}
                                  name="stateprov2"
                                  onChange={(selectedOption) => {
                                    setFieldValue("city2", "");
                                    setSelectedUserCityText("");

                                    if (selectedOption) {
                                      setFieldValue(
                                        "stateprov2",
                                        selectedOption.value
                                      );
                                      changeUserCity(selectedOption.value);
                                    } else {
                                      setFieldValue("stateprov2", "");
                                    }
                                  }}
                                  onBlur={() =>
                                    setFieldTouched("stateprov2", true)
                                  }
                                />
                                <label className="with-select">
                                  <span>*</span> STATE
                                </label>
                                {errors.stateprov2 && touched.stateprov2 ? (
                                  <p className="help is-danger">
                                    {errors.stateprov2}
                                  </p>
                                ) : null}
                              </div>
                              {/* <div
                                className={`form-floating ${
                                  errors.stateprov2 && touched.stateprov2
                                    ? "is-danger"
                                    : ""
                                }`}
                              >
                                <select
                                  className="form-select"
                                  onChange={(e) => {
                                    changeUserCity(e);
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  name="stateprov2"
                                  value={values.stateprov2 || ""}
                                  required
                                >
                                  {userState.map((res) => {
                                    return (
                                      <option key={res.value} value={res.value}>
                                        {res.label}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label>
                                  <span>*</span> SELECT STATE/PROVINCE
                                </label>
                                {errors.stateprov2 && touched.stateprov2 ? (
                                  <p className="help is-danger">
                                    {errors.stateprov2}
                                  </p>
                                ) : null}
                              </div> */}
                            </div>
                            <div className="col-lg-4">
                              {userCityLoader ? (
                                <h5 className="form-floating placeholder-glow mb-0">
                                  <span
                                    className="placeholder col-12"
                                    style={{
                                      height: "58px",
                                    }}
                                  ></span>
                                </h5>
                              ) : (
                                <div
                                  className={`form-floating ${
                                    errors.city2 && touched.city2
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  <Select
                                    className={
                                      errors.city2 && touched.city2
                                        ? "error-select-search"
                                        : ""
                                    }
                                    placeholder={
                                      userSelectedCityText !== ""
                                        ? userSelectedCityText
                                        : "SELECT CITY"
                                    }
                                    options={userCity}
                                    isSearchable={true}
                                    name="city2"
                                    onChange={(selectedOption) => {
                                      if (selectedOption) {
                                        setFieldValue(
                                          "city2",
                                          selectedOption.value
                                        );
                                      } else {
                                        setFieldValue("city2", "");
                                      }
                                    }}
                                    onBlur={() =>
                                      setFieldTouched("city2", true)
                                    }
                                  />
                                  <label className="with-select">
                                    <span>*</span> CITY
                                  </label>
                                  {errors.city2 && touched.city2 ? (
                                    <p className="help is-danger">
                                      {errors.city2}
                                    </p>
                                  ) : null}
                                </div>
                              )}
                              {/* <div className="select-leading">
                                {userCityLoader ? (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : null}
                                <div
                                  className={`form-floating ${
                                    errors.city2 && touched.city2
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  <select
                                    className="form-select"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="city2"
                                    value={values.city2 || ""}
                                    required
                                  >
                                    <option value="">SELECT CITY</option>
                                    {userCity.map((res) => {
                                      return (
                                        <option
                                          key={res.value}
                                          value={res.value}
                                        >
                                          {res.label}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <label>
                                    <span>*</span> CITY
                                  </label>
                                  {errors.city2 && touched.city2 ? (
                                    <p className="help is-danger">
                                      {errors.city2}
                                    </p>
                                  ) : null}
                                </div>
                              </div> */}
                            </div>
                            <div className="col-lg-4">
                              <InputField
                                errors={errors.zipcode}
                                touched={touched.zipcode}
                                values={values.zipcode}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="ZIP CODE"
                                spanText="ZIP CODE"
                                fieldName="zipcode"
                                fieldType="text"
                                required={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="prfil-set">
                      <div className="form-box">
                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="prf-hed">
                              MY USER NAME AND PASSWORD
                            </h4>{" "}
                          </div>
                        </div>
                        <div className="form-filds">
                          <div className="row">
                            <div className="col-lg-4">
                              <InputField
                                errors={errors.username}
                                checkUser={checkuser}
                                touched={touched.username}
                                values={values.username}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="USERNAME"
                                spanText="USERNAME"
                                fieldName="username"
                                fieldType="text"
                                disabled={true}
                              />
                              {usererror ? (
                                <p className="help is-danger">{usererror}</p>
                              ) : null}
                            </div>
                            <div className="col-lg-4">
                              <InputField
                                errors={errors.password}
                                touched={touched.password}
                                values={values.password}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="PASSWORD"
                                spanText="PASSWORD"
                                fieldName="password"
                                fieldType="password"
                                required={false}
                              />
                            </div>
                            <div className="col-lg-4">
                              <InputField
                                errors={errors.confirm_password}
                                touched={touched.confirm_password}
                                values={values.confirm_password}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="RE-TYPE PASSWORD"
                                spanText="RE-TYPE PASSWORD"
                                fieldName="confirm_password"
                                fieldType="password"
                                required={false}
                              />
                            </div>
                            <div className="col-lg-4"> </div>
                            <div className="col-lg-4">
                              <input
                                type="submit"
                                className="round-red-btn w-100 mt-3"
                                value="UPDATE MY INFORMATION"
                                disabled={disable}
                              />{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </section>

        <Footer />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </>
  );
}
