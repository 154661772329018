import axios from "axios";
import { useFormik } from "formik";
import React, { useLayoutEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

import { toast, ToastContainer } from "react-toastify";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { quizSearch } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";
import HeaderSidebar from "../includes/HeaderSidebar";
import ToTop from "../includes/ToTop";

export default function ManageCourse() {
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState("");
  const [resultData, setResultData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("");
  const [totalPages, setTotalPages] = useState("");

  const changeLimit = (e) => {
    let newLimit = e;
    // console.log(limit);
    const getResultData = async () => {
      try {
        if (values.fieldtype === "" && values.searchval === "") {
          setLoading(true);

          setLimit(newLimit);

          const response = await AdminListService.getNewLimitCourseList(
            newLimit
          );

          let resultData;
          if (response.status === 206) {
            setLoading(false);
            resultData = response.data.response;
            setResultData(resultData);
            setTotalResults(response.data.records);
            setCurrentPage(1);
            setTotalPages(response.data.total_pages);
            setLimit(response.data.per_page);
          } else {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          }
        } else if (values.fieldtype !== "" && values.searchval !== "") {
          setLoading(true);

          setLimit(newLimit);

          const response = await AdminListService.getSearchCourseList(
            values.fieldtype,
            values.searchval,
            newLimit,
            1
          );

          let resultData;
          if (response.status === 206) {
            setLoading(false);
            resultData = response.data.response;
            console.log(response.data.response);
            setResultData(resultData);
            setTotalResults(response.data.records);
            setTotalPages(response.data.total_pages);
            setCurrentPage(1);
            setLimit(response.data.per_page);
          } else {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          }
        } else if (values.fieldtype !== "" || values.searchval !== "") {
          handleSubmit();
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setResultData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  };

  const changePagination = (e) => {
    // console.log(e)
    let pageNo = e;
    // setLimit(newLimit);
    // console.log(limit);
    const getResultData = async () => {
      try {
        if (values.fieldtype === "" && values.searchval === "") {
          setLoading(true);
          setCurrentPage(e);
          const response = await AdminListService.getPaginationCourseList(
            pageNo,
            limit
          );

          let resultData;
          if (response.status === 206) {
            resultData = response.data.response;

            setResultData(resultData);
            setLimit(response.data.per_page);
            setLoading(false);
          }
        } else if (values.fieldtype !== "" && values.searchval !== "") {
          setLoading(true);
          setCurrentPage(e);
          const response = await AdminListService.getSearchCourseList(
            values.fieldtype,
            values.searchval,
            limit,
            pageNo
          );

          let resultData;
          if (response.status === 206) {
            setLoading(false);
            resultData = response.data.response;
            console.log(response.data.response);
            setResultData(resultData);
            setTotalResults(response.data.records);
            setTotalPages(response.data.total_pages);
            setLimit(response.data.per_page);
          } else {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          }
        } else if (values.fieldtype !== "" || values.searchval !== "") {
          handleSubmit();
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    };
    getResultData();
  };

  const searchData = async (action) => {
    setLoading(true);

    try {
      const response = await AdminListService.getSearchCourseList(
        values.fieldtype,
        values.searchval,
        limit,
        1
      );

      let resultData;
      if (response.status === 206) {
        setLoading(false);
        resultData = response.data.response;
        setResultData(resultData);
        setTotalResults(response.data.records);
        setTotalPages(response.data.total_pages);
        setCurrentPage(1);
        setLimit(response.data.per_page);
      } else {
        setTotalResults("0");
        setTotalPages("1");
        setResultData([]);
        setLoading(false);
      }
    } catch (err) {
      setTotalPages("1");
      setTotalResults("0");
      if (err.response.status === 500) {
        setLoading(false);
      } else {
        setLoading(false);
        setResultData([]);
      }
    }
  };

  const getCourseData = async () => {
    setLoading(true);

    try {
      const response = await AdminListService.getCourseList();

      let resultData;
      if (response.status === 206) {
        resultData = response.data.response;
        setResultData(resultData);
        setLimit(response.data.per_page);
        setTotalResults(response.data.records);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      }
    } catch (err) {
      setTotalPages("1");
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const delCourse = async (code) => {
    setLoading(true);
    try {
      const response = await AdminListService.deleteCourseSlideById(code);
      if (response.status === 200) {
        toast.success("Deleted Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
        setCurrentPage(1);
        getCourseData();
      }
    } catch (err) {
      toast.error("Something went wronge!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const updateStatusByCode = async (flag, code) => {
    setLoading(true);
    try {
      const response = await AdminListService.updateCourseStatusByCode(
        flag,
        code
      );
      if (response.status === 200) {
        toast.success("Status Updated Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getCourseData();
        setCurrentPage(1);
      }
    } catch (err) {
      toast.error("Something went wronge!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    getCourseData();
  }, []);

  useLayoutEffect(() => {
    const getIp = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");

      const weIp = res.data.IPv4;
      secureLocalStorage.setItem("ip", weIp);
    };
    getIp();
  }, [updateStatusByCode]);

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table caption-top align-middle table-borderless table-style-1">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("id")}
                className={getClassNamesFor("id")}
              >
                No
              </th>
              <th
                scope="col"
                onClick={() => requestSort("course_name")}
                className={getClassNamesFor("course_name")}
              >
                Courses
              </th>
              <th
                scope="col"
                onClick={() => requestSort("code")}
                className={getClassNamesFor("code")}
              >
                Code
              </th>
              <th
                scope="col"
                onClick={() => requestSort("flag")}
                className={getClassNamesFor("flag")}
              >
                Status
              </th>
              <th
                scope="col"
                onClick={() => requestSort("Action")}
                className={getClassNamesFor("Action")}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={el.id}>
                  <td>{el.id}</td>
                  <td>{el.course_name}</td>
                  <td>{el.code}</td>
                  <td>
                    <div className="form-check form-switch old-new">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={el.code}
                        defaultChecked={el.flag === "old" ? false : true}
                        onChange={(e) => {
                          if (e.target.checked) {
                            updateStatusByCode("new", el.code);
                          } else {
                            updateStatusByCode("old", el.code);
                          }
                        }}
                      />
                      <label className="form-check-label" htmlFor={el.code}>
                        {el.flag}
                      </label>
                    </div>
                  </td>
                  <td className="add-edit-delete-inline">
                    <Link
                      to="/admin/add_courses"
                      className="btn btn-primary px-4 back-blue"
                    >
                      Add <i className="fadeIn animated bx bx-add-to-queue"></i>
                    </Link>
                    <Link
                      to={"/admin/edit_courses/" + el.code}
                      className="btn btn-primary px-4 back-blue"
                    >
                      Edit <i className="bi bi-pencil-fill"></i>{" "}
                    </Link>
                    <button
                      type="button"
                      className="btn btn-primary px-4 back-blue"
                      onClick={() => {
                        delCourse(el.code);
                      }}
                    >
                      Delete <i className="bi bi-trash-fill"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values, action) => {
        // action.resetForm();
        searchData(action);
      },
    });

  useLayoutEffect(() => {
    const getIp = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");
      const weIp = res.data.IPv4;
      secureLocalStorage.setItem("ip", weIp);
    };
    getIp();
  }, [handleSubmit]);

  return (
    <div className="semi-dark">
      <div className="wrapper">
        <HeaderSidebar />
        <ToastContainer />
        <main className="page-content">
          <div className="row">
            <div className="col">
              <div className="manage-heading-2">
                <h2>
                  Manage Courses <span>[{totalResults}]</span>
                </h2>
              </div>

              <div className="main-content-box rzl-tabl">
                <form onSubmit={handleSubmit} noValidate>
                  <div className="row mb-3">
                    <div className="col-lg-3">
                      <select
                        className={`form-select ${
                          errors.fieldtype && touched.fieldtype
                            ? "is-danger"
                            : ""
                        }`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="fieldtype"
                        value={values.fieldtype || ""}
                        required
                      >
                        <option value="">Select</option>
                        <option value="1">Courses</option>
                        <option value="2">Code</option>
                        <option value="3">Status</option>
                      </select>
                      {errors.fieldtype && touched.fieldtype ? (
                        <p className="help is-danger">{errors.fieldtype}</p>
                      ) : null}
                    </div>

                    <div className="col-lg-4 ">
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control ${
                            errors.searchval && touched.searchval
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="searchval"
                          value={values.searchval || ""}
                          required
                        />

                        <button
                          className="btn btn-outline-secondary sebmit-dat"
                          type="submit"
                        >
                          Search
                        </button>
                      </div>
                      {errors.searchval && touched.searchval ? (
                        <p className="help is-danger">{errors.searchval}</p>
                      ) : null}
                    </div>

                    <div className="col-lg-2 ">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => changeLimit(e.target.value)}
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                    <div className="col-lg-1 ">
                      <button
                        className="btn btn-outline-secondary"
                        type="reset"
                        onClick={() => window.location.reload(false)}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
                <div className="table-responsive">
                  <Table data={resultData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
          </div>
        </main>

        <ToTop />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </div>
  );
}
