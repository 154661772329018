import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { UserContext } from "../../../context/UserContext";

export default function HeaderSidebar() {
  const navigate = useNavigate();
  const { dispatch: ctxDispatch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const logOut = async (event) => {
    event.preventDefault();

    setLoading(true);
    ctxDispatch({
      type: "LOGOUT",
    });
    setLoading(false);
    navigate("/admin");
  };

  var welcomeFname;
  if (secureLocalStorage.getItem("name")) {
    const welcomeName = secureLocalStorage.getItem("name");

    welcomeFname = welcomeName.split(" ");
  } else {
    const welcomeName = "Unknown Person";
    welcomeFname = welcomeName.split(" ");
  }

  const userType = secureLocalStorage.getItem("uType");
  return (
    <>
      <header className="top-header">
        <nav className="navbar navbar-expand gap-3">
          <div className="mobile-toggle-icon fs-3">
            <i className="bi bi-list"></i>
          </div>
          <div className="top-navbar-right ms-auto">
            <ul className="navbar-nav align-items-center">
              <li className="nav-item">
                <Link className="nav-link" to="/admin/dashboard">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/profile_admin">
                  Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/logout" onClick={logOut}>
                  Logout
                </Link>
              </li>
              <li className="nav-item">
                <div className="red-welcome">
                  <div className="trianles">
                    {" "}
                    <img
                      src="/admin_assets/images/lef-red.png"
                      className="lef-red"
                      alt=""
                    />{" "}
                    <img
                      src="/admin_assets/images/rig-red.png"
                      className="rig-red"
                      alt=""
                    />{" "}
                    <div className="welcome-txt">
                      {/* Welcome - {welcomeName !== " " ? welcomeName : "Unknown"} */}
                      Welcome - {welcomeFname[0] ? welcomeFname[0] : "Unknown"}
                      {/* Welcome - {welcomeName ? welcomeName : "Unknown"} */}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <aside className="sidebar-wrapper" data-simplebar="true">
        <div className="sidebar-header">
          <Link to="/admin/dashboard">
            <img
              src="/admin_assets/images/logo-icon.png"
              className="logo-icon"
              alt="logo icon"
            />
          </Link>
          <div>
            <img
              src="/admin_assets/images/logo-icon-2.png"
              className="logo-icon-2"
              alt="logo icon"
            />
          </div>
          <div className="toggle-icon ms-auto">
            {" "}
            <i className="bi bi-list"></i>
          </div>
        </div>

        <ul className="metismenu" id="menu">
          <li>
            <Link to="/admin/dashboard">
              <div className="parent-icon">
                <i className="fadeIn animated bx bx-home-circle"></i>
              </div>
              <div className="menu-title">Dashboard</div>
            </Link>
          </li>
          {/* <li>
            <a
              href="/"
              className="has-arrow"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div className="parent-icon">
                <i className="lni lni-book"></i>
              </div>
              <div className="menu-title">Courses</div>
            </a>
            <ul>
              <li>
                {" "}
                <Link to="/admin/add_courses">
                  <i className="bi bi-circle"></i>Add Courses
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/admin/manage_courses">
                  <i className="bi bi-circle"></i>Manage Courses
                </Link>
              </li>
            </ul>
          </li> */}
          <li>
            <a
              href="/"
              className="has-arrow"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div className="parent-icon">
                <i className="bi bi-grid-fill"></i>
              </div>
              <div className="menu-title">Stores</div>
            </a>
            <ul>
              {userType === "dos" ? (
                <li>
                  {" "}
                  <Link to="/admin/manage_dos_territory">
                    <i className="bi bi-circle"></i>Manage Territories
                  </Link>
                </li>
              ) : (
                <>
                  <li>
                    {" "}
                    <Link to="/admin/manage_territory">
                      <i className="bi bi-circle"></i>Manage Territories
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/admin/manage_dos">
                      <i className="bi bi-circle"></i>Manage DOS
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/admin/manage_vp">
                      <i className="bi bi-circle"></i>Manage VP
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </li>
          {userType === "dos" ? (
            <li>
              <a
                href="/"
                className="has-arrow"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="parent-icon">
                  <i className="bi bi-person-lines-fill"></i>
                </div>
                <div className="menu-title">Manage RSA's</div>
              </a>
              <ul>
                <li>
                  {" "}
                  <Link to="/admin/view_accepted_rsa">
                    <i className="bi bi-circle"></i>
                    View Accepted RSA
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/admin/view_accepted_manager">
                    <i className="bi bi-circle"></i>
                    View Accepted Manager
                  </Link>
                </li>
              </ul>
            </li>
          ) : (
            <li>
              <a
                href="/"
                className="has-arrow"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="parent-icon">
                  <i className="bi bi-person-lines-fill"></i>
                </div>
                <div className="menu-title">Participants</div>
              </a>
              <ul>
                <li>
                  {" "}
                  <Link to="/admin/view_licensed_rsa">
                    <i className="bi bi-circle"></i>View Licensed RSA's
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/admin/view_manager">
                    <i className="bi bi-circle"></i>View Manager's
                  </Link>
                </li>
              </ul>
            </li>
          )}

          <li>
            <a
              href="/"
              className="has-arrow"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div className="parent-icon">
                <i className="lni lni-pencil-alt"></i>
              </div>
              <div className="menu-title">Manage Quiz</div>
            </a>
            <ul>
              <li>
                {" "}
                <Link to="/admin/view_quiz_results">
                  <i className="bi bi-circle"></i>View Quiz Results
                </Link>
              </li>
              <li>
                {" "}
                <a
                  href={
                    process.env.REACT_APP_API_Link + "quiz/export-all-modules"
                  }
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="bi bi-circle"></i>Export All Modules
                </a>
              </li>
            </ul>
          </li>
          {userType === "dos" ? null : (
            <>
              <li>
                <a
                  className="has-arrow"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="bi bi-pin-map-fill"></i>
                  </div>
                  <div className="menu-title">States</div>
                </a>
                <ul>
                  <li>
                    {" "}
                    <Link to="/admin/add_state">
                      <i className="bi bi-circle"></i>Add State
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/admin/view_all_states">
                      <i className="bi bi-circle"></i>View All States
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  className="has-arrow"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="lni lni-telegram-original"></i>
                  </div>
                  <div className="menu-title">Cities</div>
                </a>
                <ul>
                  <li>
                    {" "}
                    <Link to="/admin/add_city">
                      <i className="bi bi-circle"></i>Add City
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/admin/view_all_cities">
                      <i className="bi bi-circle"></i>View All Cities
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  className="has-arrow"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="bi bi-file-earmark-break-fill"></i>
                  </div>
                  <div className="menu-title">Manage Stores</div>
                </a>
                <ul>
                  <li>
                    {" "}
                    <Link to="/admin/add_store">
                      <i className="bi bi-circle"></i>Add Store
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/admin/view_all_stores">
                      <i className="bi bi-circle"></i>View All Stores
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/admin/upload_store">
                      <i className="bi bi-circle"></i>Upload Store
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          )}
        </ul>
      </aside>
    </>
  );
}
