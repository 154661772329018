import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { UserContext } from "../../context/UserContext";
import UserService from "../../services/user.service";
import AdminFooter from "./includes/AdminFooter";
import HeaderSidebar from "./includes/HeaderSidebar";
import ToTop from "./includes/ToTop";

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [rsaCounter, setRsaCounter] = useState("");
  const [managerCounter, setManagerCounter] = useState("");
  const { state } = useContext(UserContext);
  const { id, uType } = state;

  useEffect(() => {
    const resultData = async () => {
      setLoading(true);
      try {
        if (uType === "dos") {
          const response = await UserService.totalRsaForDos(id);
          setRsaCounter(response.data.response);
          setLoading(false);
        } else {
          const response = await UserService.totalRsa();
          setRsaCounter(response.data.response);
          setLoading(false);
        }
      } catch (err) {
        setRsaCounter("0");

        setLoading(false);
      }
    };

    resultData();
  }, []);

  useEffect(() => {
    const resultData = async () => {
      setLoading(true);
      try {
        if (uType === "dos") {
          const response = await UserService.totalManagerForDos(id);
          setManagerCounter(response.data.response);
          setLoading(false);
        } else {
          const response = await UserService.totalManager();
          setManagerCounter(response.data.response);
          setLoading(false);
        }
      } catch (err) {
        setManagerCounter("0");

        setLoading(false);
      }
    };

    resultData();
  }, []);
  const welcomeName = secureLocalStorage.getItem("uType");
  return (
    <div className="semi-dark">
      <div className="wrapper">
        <HeaderSidebar />

        <main className="page-content">
          <div className="manage-heading-2">
            <h2>
              Welcome <span>{welcomeName}</span>
            </h2>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xxl-4 gy-3">
            <div className="col">
              <div className="card radius-10">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-1">Total RSA's Registered</p>
                      <h4 className="mb-0 text-orange">{rsaCounter}</h4>
                    </div>
                    <div className="ms-auto fs-2 text-orange text-center">
                      <i className="bi bi-book"></i>
                      <Link
                        to={
                          uType === "dos"
                            ? "/admin/view_accepted_rsa"
                            : "/admin/view_licensed_rsa"
                        }
                        className="btn btn-primary px-4 d-block back-blue"
                      >
                        More
                      </Link>
                    </div>
                  </div>
                  <div
                    className="progress radius-10 my-2"
                    style={{ height: "5px" }}
                  >
                    <div
                      className="progress-bar bg-orange"
                      role="progressbar"
                      style={{ width: "65%" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card radius-10">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <p className="mb-1">Total Manager's Registered</p>
                      <h4 className="mb-0 text-orange">{managerCounter}</h4>
                    </div>
                    <div className="ms-auto fs-2 text-orange text-center">
                      <i className="bi bi-book"></i>
                      <Link
                        to={
                          uType === "dos"
                            ? "/admin/view_accepted_manager"
                            : "/admin/view_manager"
                        }
                        className="btn btn-primary px-4 d-block back-blue"
                      >
                        More
                      </Link>
                    </div>
                  </div>
                  <div
                    className="progress radius-10 my-2"
                    style={{ height: "5px" }}
                  >
                    <div
                      className="progress-bar bg-orange"
                      role="progressbar"
                      style={{ width: "65%" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <ToTop />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
}
