import React, { useState, useEffect } from "react";

const NoInternet = (props) => {
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  if (isOnline) {
    return props.children;
  } else {
    return (
      <>
        <div className="pad-bot error-404">
          <section className="main-ban sliding-bg">
            <div className="container">
              <header className="main-header">
                <nav className="navbar navbar-expand-lg navbar-light flex-column">
                  <a className="navbar-brand" href="/">
                    <img src="/images/logo.png" className="img-fluid" alt="" />
                  </a>
                </nav>
              </header>
            </div>
          </section>

          <section className="slide-up">
            <div className="container">
              <div className="slides-here">
                <h2 className="slide-heading">
                  <span>No internet </span> connection!
                </h2>

                <div className="review-que">
                  <div className="row justify-content-center row">
                    <div className="col-lg-8 h-100 d-flex flex-column justify-content-center">
                      <h3 className="mb-2 text-center">
                        NO INTERNET CONNECTION
                      </h3>

                      <p className="not-pass text-center">
                        Please re-connect to the internet to continue use{" "}
                        <strong>ashleysleepuniversity</strong>
                      </p>
                      <h3 className="mb-3 mt-4">If you encounter problems:</h3>

                      <ul className="circle-list">
                        <li>
                          Try restarting wireless connection on this device.
                        </li>
                        <li>Move close to your wireless access point.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <footer className="text-center">
            <div className="container">
              <span>
                © Copyrights {new Date().getFullYear()} all rights reserved by
                ashleysleepuniversity.com
              </span>
            </div>
          </footer>
        </div>
      </>
    );
  }
};

export default NoInternet;
