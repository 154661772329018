import React, { useContext, useLayoutEffect, useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { UserContext } from "../../../context/UserContext";
import QuizService from "../../../services/quiz.service";
import "./diploma.css";
import $ from "jquery";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default function Diploma() {
  const TITLE = "ASU | Diploma";

  const { state } = useContext(UserContext);
  const { name, resultDate, id, accessToken, uType } = state;
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (
      accessToken &&
      uType !== "admin" &&
      uType !== "dos" &&
      uType !== "ndos"
    ) {
      const getLevel = async () => {
        try {
          setLoading(true);
          const { status } = await QuizService.getLevelEligibility(id);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          Swal.fire({
            title: "Not Eligible!",
            text: "You are not yet Eligible for a Diploma!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });

          navigate("/welcome", { replace: true });
        }
      };

      getLevel();
    }
  }, [id]);
  const generatePdf = async (el) => {
    $(".priv-btn").hide();
    setGenerating(true);

    const canvas = await html2canvas(el, {
      scale: 3, // Set the scale to 1 to get the actual size of the element
      allowTaint: true, // Allow cross-origin images to be drawn on the canvas
      useCORS: true, // Use CORS to fetch the images if needed
    });
    const image = canvas.toDataURL("image/png", 1.0);
    const pdf = new jsPDF("p", "mm");

    const imgData = canvas.toDataURL("image/png");
    pdf.addImage(imgData, "PNG", 8.3, 15, 190, 190);
    const pdfData = pdf.output("blob");
    const pdfURL = URL.createObjectURL(pdfData);

    $(".bg").hide();

    $("#dis").css("display", "block");
    $("#printableArea").append(canvas);
    setGenerating(false);
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = "Diploma";

    fakeLink.href = pdfURL;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
    window.open(pdfURL, "_blank");
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="diploma">
        <div className="container">
          <div className="priv-btn">
            <button
              className="print-btn"
              // id="btns"
              onClick={() => {
                generatePdf(document.getElementById("printableArea"));
              }}
            >
              <img src="./images/printing.png" alt="..." />
              Print
            </button>
          </div>
        </div>
        <div className="container" id="printableArea">
          <div className="alert alert-info alert-dismissible " id="dis">
            <strong>Info!</strong> Please save the image by right clicking on
            it.
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <div className="bg">
            <img src="images/bg.jpg" className="main-bg" alt="..." />
            <div className="content-outer">
              <h1>Ashley Sleep University</h1>
              <div className="dec">
                {" "}
                <img src="./images/style-one.png" alt="..." />
              </div>
              <h2>
                THIS CERTIFICATE IS PROUDLY
                <br />
                PRESENTED FOR HONORABLE ACHIEVEMENT TO
              </h2>
              <div className="part">{name}</div>

              <h2>awarded this day of {resultDate}</h2>
              <p>
                You have enhanced your knowledge and achieved{" "}
                <b>SENIOR LEVEL</b> by <br />
                successfully completing all courses
              </p>
              <div className="dec">
                {" "}
                <img src="./images/style-two.png" alt=".." />
              </div>
              <div className="footer-area">
                <div className="left-input">
                  <input type="text" value={resultDate} disabled={true} />
                  <label>Date</label>
                </div>
                <div className="stamp">
                  <img src="./images/stamp.png" alt="stamp" />
                </div>
                <div className="right-input">
                  <div className="signature-holder">
                    {/* <input type="text" /> */}
                    <img src="./images/signatureforcertificate.png" alt="..." />
                  </div>
                  <label>Signature</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`loader ${loading ? "in diploma-loader" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
        {generating ? (
          <div className="loader in diploma-loader">
            <h3 className="text-success">Generating PDF...</h3>
          </div>
        ) : null}
      </div>
    </>
  );
}
