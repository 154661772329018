import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <span>
              © Copyrights {new Date().getFullYear()} all rights reserved by
              ashleysleepuniversity.com
            </span>
          </div>
          <div className="col-lg-3 text-end">
            <Link to="/privacyStatement">Privacy Statement</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
