import axios from "axios";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useLayoutEffect, useRef, useState } from "react";

import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { toast, ToastContainer } from "react-toastify";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { quizSearch } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";
import HeaderSidebar from "../includes/HeaderSidebar";
import ToTop from "../includes/ToTop";
import $ from "jquery";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import MoveTable from "../includes/MoveTable";
import AdminFooter from "../includes/AdminFooter";
import CheckUtype from "../includes/CheckUtype";
import { UserContext } from "../../../context/UserContext";

export default function ViewRsa() {
  const { state } = useContext(UserContext);
  const { id: adminId, uType } = state;
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState("10");
  const [resultData, setResultData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const dateRef = useRef(null);

  const showPassword = (el) => {
    let value = el.currentTarget.value;
    // console.log(value);
    Swal.fire({
      title: "Enter password",
      html: '<input type="password" id="swal-input1" class="swal2-input" placeholder="Enter your password" required>',
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      preConfirm: async () => {
        const inputValue = document.getElementById("swal-input1").value;
        if (!inputValue) {
          Swal.showValidationMessage("Please enter your password");
        }

        if (inputValue) {
          setLoading(true);
          values.adminId = adminId;
          values.adminPassword = inputValue;
          values.userPassword = value;
          try {
            const response = await AdminListService.decryptPasswordByAdmin(
              values
            );

            Swal.fire({
              title: "",
              text: response.data.response,
              icon: "success",
            });
            setLoading(false);
          } catch (err) {
            Swal.fire({
              title: "",
              text: err?.response?.data?.response,
              icon: "error",
            });
            setLoading(false);
          }
        } else {
          Swal.fire("Error retrieving password");
        }

        return inputValue;
      },
    });
  };

  const changeDate = (e) => {
    setLoading(true);

    const getResultData = async () => {
      setLoading(true);

      try {
        if (values.fieldtype === "" && values.searchval === "") {
          const response = await AdminListService.getSearchLicenseeRsaList(
            "",
            e,
            limit,
            1
          );
          let resultData;
          if (response.status === 206) {
            resultData = response.data.response;
            setResultData(resultData);
            setTotalResults(response.data.records);
            setTotalPages(response.data.total_pages);
            setLimit(response.data.per_page);
            setLoading(false);
          }
        } else if (values.fieldtype === "" || values.searchval === "") {
          setLoading(false);

          handleSubmit();
        } else {
          const response = await AdminListService.getSearchLicenseeRsaByDate(
            values.fieldtype,
            values.searchval,
            e,
            limit,
            1
          );
          let resultData;
          if (response.status === 206) {
            resultData = response.data.response;
            setResultData(resultData);
            setTotalResults(response.data.records);
            setTotalPages(response.data.total_pages);
            setLimit(response.data.per_page);
            setLoading(false);
          }
        }
      } catch (err) {
        setResultData([]);
        setTotalResults("0");
        setTotalPages("1");
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  };
  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);

    // console.log(limit);
    const getResultData = async () => {
      try {
        if (
          values.fieldtype === "" &&
          values.searchval === "" &&
          dateRef.current.value === ""
        ) {
          setLoading(true);

          const response = await AdminListService.getNewLimitLicenseeRsaList(
            newLimit
          );

          let resultData;
          if (response.status === 206) {
            setLoading(false);
            resultData = response.data.response;
            setResultData(resultData);
            setTotalResults(response.data.records);
            setCurrentPage(1);
            setTotalPages(response.data.total_pages);
            setLimit(response.data.per_page);
          } else {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          }
        } else if (values.fieldtype === "" && values.searchval === "") {
          if (dateRef.current.value !== "") {
            setLoading(true);

            const response = await AdminListService.getSearchLicenseeRsaList(
              "",
              dateRef.current.value,
              newLimit,
              1
            );
            let resultData;
            if (response.status === 206) {
              resultData = response.data.response;
              setResultData(resultData);
              setTotalResults(response.data.records);
              setTotalPages(response.data.total_pages);
              setLimit(response.data.per_page);
              setLoading(false);
            }
          }
        } else if (values.fieldtype !== "" && values.searchval !== "") {
          if (dateRef.current.value !== "") {
            setLoading(true);

            const response = await AdminListService.getSearchLicenseeRsaByDate(
              values.fieldtype,
              values.searchval,
              dateRef.current.value,
              newLimit,
              1
            );
            let resultData;
            if (response.status === 206) {
              setLoading(false);
              resultData = response.data.response;

              setResultData(resultData);
              setTotalResults(response.data.records);
              setTotalPages(response.data.total_pages);
              setLimit(response.data.per_page);
            } else {
              setTotalResults("0");
              setTotalPages("1");
              setResultData([]);
              setLoading(false);
            }
          } else {
            setLoading(true);

            const response = await AdminListService.getSearchLicenseeRsaList(
              values.fieldtype,
              values.searchval,
              newLimit,
              1
            );

            let resultData;
            if (response.status === 206) {
              setLoading(false);
              resultData = response.data.response;

              setResultData(resultData);
              setTotalResults(response.data.records);
              setTotalPages(response.data.total_pages);
              setLimit(response.data.per_page);
            } else {
              setTotalResults("0");
              setTotalPages("1");
              setResultData([]);
              setLoading(false);
            }
          }
        } else if (values.fieldtype !== "" || values.searchval !== "") {
          handleSubmit();
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setResultData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  };

  const changePagination = (e) => {
    setCurrentPage(e);

    // console.log(e)
    let pageNo = e;
    // setLimit(newLimit);
    // console.log(limit);
    const getResultData = async () => {
      setLoading(true);

      try {
        if (
          values.fieldtype === "" &&
          values.searchval === "" &&
          dateRef.current.value === ""
        ) {
          const response = await AdminListService.getPaginationLicenseeRsaList(
            pageNo,
            limit
          );

          let resultData;
          if (response.status === 206) {
            resultData = response.data.response;

            setResultData(resultData);
            setLimit(response.data.per_page);
            setLoading(false);
          }
        } else if (values.fieldtype === "" && values.searchval === "") {
          if (dateRef.current.value !== "") {
            setLoading(true);

            const response = await AdminListService.getSearchLicenseeRsaList(
              "",
              dateRef.current.value,
              limit,
              pageNo
            );
            let resultData;
            if (response.status === 206) {
              resultData = response.data.response;
              setResultData(resultData);
              setTotalResults(response.data.records);
              setTotalPages(response.data.total_pages);
              setLimit(response.data.per_page);
              setLoading(false);
            }
          }
        } else if (values.fieldtype !== "" && values.searchval !== "") {
          if (dateRef.current.value !== "") {
            setLoading(true);

            const response = await AdminListService.getSearchLicenseeRsaByDate(
              values.fieldtype,
              values.searchval,
              dateRef.current.value,
              limit,
              pageNo
            );
            let resultData;
            if (response.status === 206) {
              setLoading(false);
              resultData = response.data.response;

              setResultData(resultData);
              setTotalResults(response.data.records);
              setTotalPages(response.data.total_pages);
              setLimit(response.data.per_page);
            } else {
              setTotalResults("0");
              setTotalPages("1");
              setResultData([]);
              setLoading(false);
            }
          } else {
            setLoading(true);

            const response = await AdminListService.getSearchLicenseeRsaList(
              values.fieldtype,
              values.searchval,
              limit,
              pageNo
            );

            let resultData;
            if (response.status === 206) {
              setLoading(false);
              resultData = response.data.response;

              setResultData(resultData);
              setTotalResults(response.data.records);
              setTotalPages(response.data.total_pages);
              setLimit(response.data.per_page);
            } else {
              setTotalResults("0");
              setTotalPages("1");
              setResultData([]);
              setLoading(false);
            }
          }
        } else if (values.fieldtype !== "" || values.searchval !== "") {
          handleSubmit();
        }
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  };

  const searchData = async (action) => {
    setLoading(true);

    try {
      if (dateRef.current.value === "") {
        const response = await AdminListService.getSearchLicenseeRsaList(
          values.fieldtype,
          values.searchval,
          limit,
          1
        );

        let resultData;
        if (response.status === 206) {
          setLoading(false);
          resultData = response.data.response;

          setResultData(resultData);
          setTotalResults(response.data.records);
          setTotalPages(response.data.total_pages);
          setLimit(response.data.per_page);
        } else {
          setTotalResults("0");
          setTotalPages("1");
          setResultData([]);
          setLoading(false);
        }
      } else {
        const response = await AdminListService.getSearchLicenseeRsaByDate(
          values.fieldtype,
          values.searchval,
          dateRef.current.value,
          limit,
          1
        );
        let resultData;
        if (response.status === 206) {
          setLoading(false);
          resultData = response.data.response;

          setResultData(resultData);
          setTotalResults(response.data.records);
          setTotalPages(response.data.total_pages);
          setLimit(response.data.per_page);
        } else {
          setTotalResults("0");
          setTotalPages("1");
          setResultData([]);
          setLoading(false);
        }
      }
    } catch (err) {
      setTotalPages("1");
      setTotalResults("0");
      if (err.response.status === 500) {
        setLoading(false);
      } else {
        setLoading(false);
        setResultData([]);
      }
    }
  };
  const getResultData = async () => {
    setLoading(true);

    try {
      const response = await AdminListService.getLicenseeRsaList();

      let resultData;
      if (response.status === 206) {
        resultData = response.data.response;
        setResultData(resultData);
        setLimit(response.data.per_page);
        setTotalResults(response.data.records);
        setTotalPages(response.data.total_pages);
        setCurrentPage(1);
        setLoading(false);
      }
    } catch (err) {
      setTotalPages("1");
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const delData = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a9e0",
      cancelButtonColor: "#e0876c",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        try {
          const response = await AdminListService.deleteParticipantsData(delId);
          if (response.status === 200) {
            setLoading(false);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Deleted Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });

            getResultData();
          }
        } catch (err) {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    });
  };

  const delSelected = async () => {
    var selectedRecords = $(".table-style-1 tbody tr td input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");

    if (!selectedRecords.length) {
      toast.error("Please select some record!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a9e0",
        cancelButtonColor: "#e0876c",
        confirmButtonText: "Yes",
        cancelButtonText: "Close",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          try {
            const response =
              await AdminListService.deleteSelectedParticipantsData(
                selectedRecords
              );
            if (response.status === 200) {
              setLoading(false);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Deleted Successfully!",
                showConfirmButton: false,
                timer: 1500,
              });

              getResultData();
            }
          } catch (err) {
            toast.error("Something went wronge!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setLoading(false);
          }
        }
      });
    }
  };

  useLayoutEffect(() => {
    getResultData();
  }, []);

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const allSelect = async () => {
      $("input:checkbox").prop("checked", $(".slect_all").prop("checked"));
    };

    return (
      <>
        <table className="table caption-top align-middle table-borderless table-style-1">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("Select")}
                className={getClassNamesFor("Select")}
              >
                <label htmlFor="Select">
                  <input
                    id="Select"
                    className="form-check-input slect_all"
                    type="checkbox"
                    onChange={allSelect}
                  />{" "}
                  Select
                </label>
              </th>
              <th
                scope="col"
                onClick={() => requestSort("id")}
                className={getClassNamesFor("id")}
              >
                No
              </th>

              <th
                scope="col"
                onClick={() => requestSort("first_name")}
                className={getClassNamesFor("first_name")}
              >
                Login Info
              </th>

              <th
                scope="col"
                onClick={() => requestSort("created_at")}
                className={getClassNamesFor("created_at")}
              >
                Date Registered
              </th>

              <th
                scope="col"
                onClick={() => requestSort("store_name")}
                className={getClassNamesFor("store_name")}
              >
                Store Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("last_course")}
                className={getClassNamesFor("last_course")}
              >
                Last Course
              </th>
              <th
                scope="col"
                onClick={() => requestSort("course_status")}
                className={getClassNamesFor("course_status")}
              >
                Status
              </th>
              <th
                scope="col"
                onClick={() => requestSort("Action")}
                className={getClassNamesFor("Action")}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={el.id}>
                  <td>
                    <input
                      className="form-check-input select_one"
                      id={el.id}
                      value={el.id}
                      type="checkbox"
                    />
                  </td>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>

                  <td>
                    <ul className="rob-rollmann">
                      <li>
                        <b>First Name:</b> {el.first_name}
                      </li>
                      <li>
                        <b>Last Name:</b> {el.last_name}
                      </li>
                      <li>
                        <b>Username:</b> {el.username}
                      </li>
                      <li className="d-flex flex-row">
                        {/* <b>Password:</b> {el.password} */}
                        <b>Password:</b>{" "}
                        <button
                          className="btn btn-primary px-4 d-block back-blue show"
                          value={el.new_password}
                          onClick={(el) => showPassword(el)}
                        >
                          <i className="bi bi-eye"></i>
                        </button>
                      </li>
                      <li>
                        <b>Email:</b> {el.email}
                      </li>
                    </ul>
                  </td>

                  <td>{moment(el.created_at).format("MM-DD-YYYY")}</td>
                  <td>
                    <ul className="rob-rollmann">
                      <li>
                        <b>Store Name:</b> {el.store_name}
                      </li>
                      <li>
                        <b>Address:</b> {el.store_address}
                      </li>
                      <li>
                        <b>Zip:</b> {el.store_zip}
                      </li>
                    </ul>
                  </td>
                  <td
                    className={` ${el.last_course === "Senior" ? "orng" : ""}
                    ${el.last_course === "Freshman" ? "gren" : ""}
                     ${el.last_course === "Sophomore" ? "yelow" : ""}
                     ${el.last_course === "Junior" ? "redd" : ""}
                     ${el.last_course === "Not Passed Yet" ? "initia" : ""}

                     `}
                  >
                    {el.last_course}
                  </td>
                  <td
                    className={` ${el.course_status === "Senior" ? "orng" : ""}
                      ${el.course_status === "On Freshman" ? "gren" : ""}
                       ${el.course_status === "Sophomore" ? "yelow" : ""}
                       ${el.course_status === "Junior" ? "redd" : ""}
                       ${el.course_status === "Graduated" ? "gradu" : ""}
                       `}
                  >
                    {el.course_status}
                  </td>

                  <td>
                    <button
                      className="btn btn-primary px-4 d-block back-blue"
                      onClick={() => delData(el.id)}
                    >
                      Delete <i className="bi bi-trash-fill"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values, action) => {
        searchData(action);
      },
    });

  const getIp = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    const weIp = res.data.IPv4;
    secureLocalStorage.setItem("ip", weIp);
  };
  useLayoutEffect(() => {
    getIp();
  }, [handleSubmit]);

  return (
    <div className="semi-dark">
      <div className="wrapper">
        <CheckUtype />
        <HeaderSidebar />
        <ToastContainer />

        <main className="page-content">
          <div className="row">
            <div className="col">
              <div className="manage-heading-2">
                <h2>
                  Licensee RSA <span>[{totalResults}]</span>
                </h2>
              </div>

              <div className="slides-here">
                <div className="alert alert-info">
                  <strong>Info!</strong> You can search your required data by
                  putting text in search box
                </div>
                <div className="main-content-box rzl-tabl">
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="row mb-3">
                      <div className="col-lg-2">
                        <select
                          className={`form-select ${
                            errors.fieldtype && touched.fieldtype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="fieldtype"
                          value={values.fieldtype || ""}
                          required
                        >
                          <option value="">Select</option>
                          <option value="1">First Name</option>
                          <option value="2">Last Name</option>
                          <option value="3">Username</option>
                          <option value="4">Email</option>
                          <option value="5">Store Name </option>
                          <option value="6">Address </option>
                          <option value="7">Zip </option>
                        </select>
                        {errors.fieldtype && touched.fieldtype ? (
                          <p className="help is-danger">{errors.fieldtype}</p>
                        ) : null}
                      </div>

                      <div className="col-lg-4 col-xl-3 col-xxl-4">
                        <div className="input-group">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="searchval"
                            value={values.searchval || ""}
                            required
                          />

                          <button
                            className="btn btn-outline-secondary sebmit-dat"
                            type="submit"
                          >
                            Search
                          </button>
                        </div>
                        {errors.searchval && touched.searchval ? (
                          <p className="help is-danger">{errors.searchval}</p>
                        ) : null}
                      </div>
                      <div className="col-lg-2 ">
                        <input
                          type="date"
                          ref={dateRef}
                          className="form-control"
                          onChange={(e) => changeDate(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-2 ">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => changeLimit(e.target.value)}
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                      <div className="col-lg-2 col-xl-1 text-lg-end text-xl-start">
                        <button
                          className="btn btn-outline-secondary"
                          type="reset"
                          onClick={() => window.location.reload(false)}
                        >
                          Reset
                        </button>
                      </div>
                      <div className="col-lg-12 col-xl-2  mt-3 mt-xl-0 col-xxl-1">
                        <div className="dropdown licensee-dropdown">
                          <button
                            className="btn dropdown-toggle d-block back-blue"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Action
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <button
                                className="dropdown-item"
                                onClick={delSelected}
                                type="button"
                              >
                                Delete Selected
                              </button>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href={
                                  process.env.REACT_APP_API_Link +
                                  "user/export-all-rsa"
                                }
                                target="_blank"
                              >
                                Export All
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </form>
                  <MoveTable />
                  <div className="table-responsive">
                    <Table data={resultData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToTop />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
}
