import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import { signInSchema } from "../../../schema";

import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import { toast, ToastContainer } from "react-toastify";
import { UserContext } from "../../../context/UserContext";
import AdminListService from "../../../services/admin-list.service";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(UserContext);
  const { accessToken, uType } = state;

  useEffect(() => {
    if (accessToken && uType === "admin") {
      navigate("/admin/dashboard");
    }
  }, [navigate, accessToken]);

  const login = async (action) => {
    setLoading(true);
    try {
      const response = await AdminListService.login(values);

      if (response.status === 200) {
        if (
          response.data.user.user_type !== "rsa" &&
          response.data.user.user_type !== "manager"
        ) {
          action.resetForm();
          ctxDispatch({
            type: "LOGIN",
            accessToken: response.data.access_token,
            id: response.data.user.id,
            uName: response.data.user.username,
            uType: response.data.user.user_type,
            name:
              response.data.user.first_name +
              " " +
              response.data.user.last_name,
          });
          navigate("/admin/dashboard");
          setLoading(false);
        } else {
          toast.error("Username or Password is invalid !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }

        // console.log(response.data.access_token);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        toast.error("Username or Password is invalid !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoading(false);

        toast.error("Something went wrong, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema: signInSchema,
      onSubmit: (values, action) => {
        login(action);
      },
    });

  return (
    <div className="login-2-admin">
      <ToastContainer />
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <Link to="/admin">
              <img
                src="/images/logo-white.png"
                className="img-fluid logo"
                alt=""
              />
            </Link>
          </div>
          <div className="col-lg-12">
            <h2>
              <span>Ashley Sleep</span>
              University
            </h2>
          </div>

          <div className="col-lg-12">
            <div className="white-box">
              <div className="rever-trans">
                <form action="" onSubmit={handleSubmit} noValidate>
                  <div className="row">
                    <div className="col-12">
                      <div
                        className={`input-group ${
                          errors.username && touched.username ? "is-danger" : ""
                        }`}
                      >
                        <span className="input-group-text" id="basic-addon1">
                          <img src="/images/black-person.png" alt="" />
                        </span>
                        <input
                          className="form-control"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="username"
                          value={values.username || ""}
                          required
                          placeholder={`${
                            errors.username && touched.username
                              ? errors.username
                              : "Username"
                          }`}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        className={`input-group ${
                          errors.password && touched.password ? "is-danger" : ""
                        }`}
                      >
                        <span className="input-group-text" id="basic-addon1">
                          <img src="/images/black-lock.png" alt="" />
                        </span>
                        <input
                          className="form-control"
                          type="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="password"
                          value={values.password || ""}
                          required
                          placeholder={`${
                            errors.password && touched.password
                              ? errors.password
                              : "Password"
                          }`}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                    </div>
                    <div className="col-l2">
                      <input type="submit" value="Login" />
                    </div>
                  </div>
                </form>
                <div className="row">
                  <div className="col-12">
                    <ul>
                      <li>
                        <span>If you have Forgot your Password.</span>
                        <Link to="/admin/forgot_pass">Click here</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
