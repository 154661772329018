import React, { useContext, useEffect } from "react";
import Header from "./Header";
import { UserContext } from "../../../context/UserContext";
import HeadForgot from "../ForgotPass/HeadForgot";

export default function SlidingBg() {
  const { state } = useContext(UserContext);
  const { accessToken } = state;

  return (
    <section className="main-ban sliding-bg">
      {accessToken ? (
        <div className="container">
          <Header />
        </div>
      ) : (
        <HeadForgot />
      )}
    </section>
  );
}
