import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import FootForgot from "../ForgotPass/FootForgot";

export default function NotFound() {
  let location = useLocation();
  const [goHome, setGoHome] = useState("/welcome");

  useEffect(() => {
    if (location.pathname.indexOf("admin") > -1) {
      setGoHome("/admin/dashboard");
    }
  }, [location]);

  return (
    <div className="pad-bot error-404">
      <section className="main-ban sliding-bg">
        <div className="container">
          <header className="main-header">
            <nav className="navbar navbar-expand-lg navbar-light flex-column">
              <Link className="navbar-brand" to={goHome}>
                <img src="/images/logo.png" className="img-fluid" alt="" />
              </Link>
            </nav>
          </header>
        </div>
      </section>
      <section className="slide-up">
        <div className="container">
          <div className="slides-here">
            <h2 className="slide-heading">
              <span>404 Page</span> Not Found
            </h2>

            <div className="back-slid notfound-bg">
              <div className="text-contain">
                <div className="row">
                  <div className="col-lg-7 inc-z">
                    <div className="content-div">
                      <h3>ERROR 404</h3>
                      <p>Looks Like you are lost in space </p>
                      <Link to={goHome} className="round-red-btn ">
                        Go Home
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-5 inc-z">
                    <div className="round-with-bar">
                      <div className="round-bor">
                        <img src="/images/404.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FootForgot />
    </div>
  );
}
