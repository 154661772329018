import React, { useContext, useMemo, useState } from "react";

import { UserContext } from "../../../context/UserContext";
import Footer from "../Includes/Footer";
import Header from "../Includes/Header";
import EduCenter from "./EduCenter/EduCenter";
import KnowEnhance from "./KnowEnhance/KnowEnhance";
import MbanCont from "./MbanCont/MbanCont";
import SvgsIco from "../Includes/SvgsIco";
import ToTop from "../Includes/ToTop";
import QuizService from "../../../services/quiz.service";
import { Helmet } from "react-helmet";

export default function Welcome() {
  const TITLE = "Ashley Sleep University";
  const { state, dispatch: ctxDispatch } = useContext(UserContext);
  const { id } = state;
  const [loading, setLoading] = useState(false);

  useMemo(() => {
    const resultData = async () => {
      setLoading(true);
      try {
        const response = await QuizService.getResultDate(id);

        setLoading(false);
        ctxDispatch({
          type: "UPDATE_RESULT",
          resultDate: response.data.response,
        });
      } catch (err) {
        setLoading(false);
      }
    };

    resultData();
  }, [ctxDispatch, id]);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="pad-bot">
        <ToTop />
        <section className="main-ban">
          <div className="container">
            <Header />
            <MbanCont />
          </div>
        </section>

        <section className="bg-grey">
          <div className="container">
            <EduCenter />
            <KnowEnhance />
          </div>
        </section>
        <div className="clearfix"></div>

        <Footer />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
        <SvgsIco />
      </div>
    </>
  );
}
