import DOMPurify from "dompurify";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import QuizService from "../../../../services/quiz.service";
import "./EduCenter.css";

export default function EduCenter() {
  const { state } = useContext(UserContext);
  const { id } = state;
  const [levelStatusIcon, setLevelStatusIcon] = useState(false);
  const [levelStatus, setLevelStatus] = useState(
    '<div class="px-app px-continer"><div class="px-gridbox"><div class="px-griditem"><div id="qt-loading-qtload009" class="qt-loading"><div></div><div></div><div></div></div></div></div></div>'
  );
  const [levelEligibilityIcon, setLevelEligibilityIcon] = useState(false);

  const [levelEligibility, setLevelEligibility] = useState(
    '<div class="px-app px-continer"><div class="px-gridbox"><div class="px-griditem"><div id="qt-loading-qtload009" class="qt-loading"><div></div><div></div><div></div></div></div></div></div>'
  );
  useEffect(() => {
    const getLevel = async () => {
      const { data } = await QuizService.getLevelStatus(id);
      const { response: res } = data;
      setLevelStatus(res);
      setLevelStatusIcon(true);
    };

    getLevel();
  }, [id]);

  useEffect(() => {
    const getLevel = async () => {
      try {
        const { data } = await QuizService.getLevelEligibility(id);

        const { response: res } = data;
        setLevelEligibility(res);
        setLevelEligibilityIcon(true);
      } catch (err) {
        setLevelEligibility(err.response.data.response);
        setLevelEligibilityIcon(true);
      }
    };

    getLevel();
  }, [id]);

  return (
    <div className="move-up">
      <h6 className="edu-cen">EDUCATION CENTER</h6>
      <ul>
        <li>
          {levelStatusIcon ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              className="svg-icon"
            >
              <use xlinkHref="#icon-02"></use>
            </svg>
          ) : null}

          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(levelStatus),
            }}
          ></div>
        </li>
        <li>
          {levelEligibilityIcon ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              className="svg-icon"
            >
              <use xlinkHref="#icon-01"></use>
            </svg>
          ) : null}

          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(levelEligibility),
            }}
          ></div>
        </li>
      </ul>
    </div>
  );
}
