import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import FootForgot from "../ForgotPass/FootForgot";
import InputField from "../Includes/InputField";

import { signUpSchema } from "../../../schema";
import UserService from "../../../services/user.service";
import StoreService from "../../../services/store.service";
import ToTop from "../Includes/ToTop";
import { toast, ToastContainer } from "react-toastify";
import "./Registration.css";
import secureLocalStorage from "react-secure-storage";
import { Helmet } from "react-helmet";
import Select from "react-select";

export default function Registration() {
  const TITLE = "Register";

  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [userState, setUserState] = useState([]);
  const [userCity, setUserCity] = useState([]);
  const [store, setStore] = useState([]);
  const [storeNdos, setStoreNdos] = useState("");
  const [storeDos, setStoreDos] = useState("");
  const [storeAddress, setStoreAddress] = useState("");
  const [userType, setUserType] = useState("");
  const [cityLoader, setCityLoader] = useState(false);
  const [storeLoader, setStoreLoader] = useState(false);
  const [userCityLoader, setUserCityLoader] = useState(false);

  const navigate = useNavigate();
  //creating function to load ip address from the API
  const getIp = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");

    const weIp = res.data.IPv4;
    secureLocalStorage.setItem("ip", weIp);
  };

  const changeCity = (state) => {
    setCity([]);
    setStore([]);
    setStoreLoader(true);

    const getStoreCity = async () => {
      setCityLoader(true);
      try {
        const { data } = await StoreService.getCityByStateId(state);
        const { response: res } = data;
        const results = [];
        res.map((value) => {
          results.push({
            label: value.name,
            value: value.id,
          });
        });
        setCity([...results]);
        setCityLoader(false);
        setStoreLoader(false);
      } catch (error) {
        setCityLoader(false);
        setStoreLoader(false);
      }
    };

    if (state !== "") {
      getStoreCity();
    }
  };

  const changeStore = (city) => {
    setStore([]);

    const getStore = async () => {
      try {
        setStoreLoader(true);
        const { data } = await StoreService.getStoreByCityId(city);
        const { response: res } = data;
        const results = [];
        res.map((value) => {
          results.push({
            label: value.name,
            value: value.id,
          });
        });
        setStore([...results]);
        setStoreLoader(false);
      } catch (error) {
        setStoreLoader(false);
      }
    };

    if (city !== "") {
      getStore();
    }
  };

  const getStoreInfo = (store) => {
    setStoreNdos("");
    setStoreDos("");
    setStoreAddress("");

    const getInfo = async () => {
      const { data } = await StoreService.getStoreInfoById(store);
      const { response: res } = data;

      setStoreNdos(res.ndos);
      setStoreDos(res.dos);
      setStoreAddress(res.address);
    };

    if (store !== "") {
      getInfo();
    }
  };

  useEffect(() => {
    setStoreAddress("");
    const getStoreState = async () => {
      const { data } = await StoreService.getStoreState();
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          label: value.name,
          value: value.id,
        });
      });
      setState([...results]);
    };

    getStoreState();
  }, []);

  useEffect(() => {
    const getUserState = async () => {
      const { data } = await UserService.getUserState();
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          label: value.name,
          value: value.id,
        });
      });
      setUserState([...results]);
    };

    getUserState();
  }, []);

  const changeUserType = (e) => {
    setUserType(e.target.value);
  };

  const changeUserCity = (userState) => {
    setUserCity([]);

    const getUserCity = async () => {
      setUserCityLoader(true);
      const { data } = await UserService.getCityByStateId(userState);
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          label: value.city,
          value: value.id,
        });
      });
      setUserCity([...results]);
      setUserCityLoader(false);
    };

    if (userState !== "") {
      getUserCity();
    }
  };

  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termsModel, setTermsModel] = useState(false);
  const [usererror, setUsererror] = useState("");
  const [emailerror, setEmailerror] = useState("");

  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      username: "",
      password: "",
      confirm_password: "",
      fname: "",
      lname: "",
      email: "",
      zipcode: "",
      signature: "",
      manager_signature: "",
      stateprov: "",
      city: "",
      store: "",
      role: "",
      stateprov2: "",
      city2: "",
      termsAndConditions: false,
    },
    validationSchema: signUpSchema,
    onSubmit: (values, action) => {
      // register(action);
      if (usererror === "" && emailerror === "") {
        setTermsModel(true);
      }
    },
  });

  const checkuser = async () => {
    if (values.username !== "") {
      setDisable(true);
      try {
        const response = await UserService.validateUser(values);

        if (response.status === 200) {
          setDisable(false);

          setUsererror("");
          // checkemail();
        }
      } catch (err) {
        if (err.response.status === 409) {
          setDisable(false);
          setUsererror(err.response.data.response);
          // Swal.fire({
          // title: 'Error!',
          // text: err.response.data.response,
          // icon: 'error',
          // confirmButtonText: 'Change User Name'
          // })
        } else {
          Swal.fire({
            title: "Error!",
            text: "Some thing went wronge",
            icon: "error",
            confirmButtonText: "Try Again",
          });
        }
      }
    } else {
      setDisable(false);

      setUsererror("");
    }
  };

  const checkemail = async (action) => {
    if (values.email !== "") {
      setDisable(true);
      try {
        const response = await UserService.validateEmail(values);

        if (response.status === 200) {
          setDisable(false);

          setEmailerror("");
          // register(action);
        }
      } catch (err) {
        if (err.response.status === 409) {
          setDisable(false);
          setEmailerror(err.response.data.response);
          // Swal.fire({
          //     title: 'Error!',
          //     text: err.response.data.response,
          //     icon: 'error',
          //     confirmButtonText: 'Change Email'
          //     })
        } else {
          setDisable(false);
          Swal.fire({
            title: "Error!",
            text: "Some thing went wronge",
            icon: "error",
            confirmButtonText: "Try Again",
          });
        }
      }
    } else {
      setDisable(false);

      setEmailerror("");
    }
  };

  const register = async () => {
    setDisable(true);
    setLoading(true);
    try {
      const response = await UserService.validateRegister(values);

      if (response.status === 201) {
        setDisable(false);
        setLoading(false);
        setTermsModel(false);

        toast.success("Registered, Login Now!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          navigate("/");
        }, 2000);
        // action.resetForm();
      }
    } catch (err) {
      if (err.response.status === 401) {
        setDisable(false);
        setLoading(false);
        setTermsModel(false);

        toast.error("Error, Try Agaim Later!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 422) {
        setDisable(false);
        setLoading(false);
        setTermsModel(false);

        toast.error("Error, Unprocessable Content!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setDisable(false);
        setLoading(false);
        setTermsModel(false);

        toast.error("Error, Some thing went wronge!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const hideMod = () => {
    setTermsModel(false);
  };

  useEffect(() => {
    getIp();
  }, [handleSubmit]);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="pad-bot">
        <ToastContainer />
        <ToTop />
        <section className="main-ban sliding-bg">
          <div className="container">
            <header className="main-header">
              <nav className="navbar navbar-expand-lg navbar-light ">
                <a className="navbar-brand" href="login.html">
                  {" "}
                  <img
                    src="images/logo.png"
                    className="img-fluid"
                    alt=""
                  />{" "}
                </a>
                <div className="alred-memb">
                  Already a member?
                  <Link to="/" className="round-red-btn">
                    LOGIN
                  </Link>
                </div>
              </nav>
            </header>
          </div>
        </section>
        <section className="slide-up">
          <div className="container">
            <div className="slides-here">
              <h2 className="slide-heading">
                <span>Register </span>
                YOUR ACCOUNT
              </h2>
              <form onSubmit={handleSubmit} noValidate>
                <ul className="timeline">
                  <li>
                    <div className="prfil-set">
                      <div className="form-box">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="alert alert-danger" role="alert">
                              <h6 className="astric-req">
                                Field(s) marked with <span>Asterisk (*)</span>{" "}
                                are mandatory.
                              </h6>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <h4 className="prf-hed">SELECT YOUR STORE</h4>
                          </div>
                        </div>

                        <div className="form-filds ">
                          <div className="row">
                            <div className="col-lg-4">
                              <div
                                className={`form-floating ${
                                  errors.stateprov && touched.stateprov
                                    ? "is-danger"
                                    : ""
                                }`}
                              >
                                <Select
                                  className={
                                    errors.stateprov && touched.stateprov
                                      ? "error-select-search"
                                      : ""
                                  }
                                  placeholder={"SELECT STATE"}
                                  // placeholder={"* SELECT STATE/PROVINCE"}
                                  options={state}
                                  isSearchable={true}
                                  name="stateprov"
                                  onChange={(selectedOption) => {
                                    setFieldValue("city", "");
                                    setFieldValue("store", "");

                                    if (selectedOption) {
                                      setFieldValue(
                                        "stateprov",
                                        selectedOption.value
                                      );
                                      changeCity(selectedOption.value);
                                    } else {
                                      setFieldValue("stateprov", "");
                                    }
                                  }}
                                  onBlur={() =>
                                    setFieldTouched("stateprov", true)
                                  }
                                />
                                <label
                                  htmlFor="floatingSelect"
                                  className="with-select"
                                >
                                  <span>*</span> SELECT STATE/PROVINCE
                                </label>
                                {errors.stateprov && touched.stateprov ? (
                                  <p className="help is-danger">
                                    {errors.stateprov}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              {cityLoader ? (
                                <h5 className="form-floating placeholder-glow mb-0">
                                  <span
                                    className="placeholder col-12"
                                    style={{
                                      height: "58px",
                                    }}
                                  ></span>
                                </h5>
                              ) : (
                                <div
                                  className={`form-floating ${
                                    errors.city && touched.city
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  <Select
                                    className={
                                      errors.city && touched.city
                                        ? "error-select-search"
                                        : ""
                                    }
                                    placeholder={"SELECT CITY"}
                                    options={city}
                                    isSearchable={true}
                                    name="city"
                                    onChange={(selectedOption) => {
                                      setFieldValue("store", "");

                                      if (selectedOption) {
                                        setFieldValue(
                                          "city",
                                          selectedOption.value
                                        );
                                        changeStore(selectedOption.value);
                                      } else {
                                        setFieldValue("city", "");
                                      }
                                    }}
                                    onBlur={() => setFieldTouched("city", true)}
                                  />
                                  <label htmlFor="" className="with-select">
                                    <span>*</span> SELECT CITY
                                  </label>
                                  {errors.city && touched.city ? (
                                    <p className="help is-danger">
                                      {errors.city}
                                    </p>
                                  ) : null}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-4">
                              {storeLoader ? (
                                <h5 className="form-floating  placeholder-glow mb-0">
                                  <span
                                    className="placeholder col-12"
                                    style={{
                                      height: "58px",
                                    }}
                                  ></span>
                                </h5>
                              ) : (
                                <div
                                  className={`form-floating ${
                                    errors.store && touched.store
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  <Select
                                    className={
                                      errors.store && touched.store
                                        ? "error-select-search"
                                        : ""
                                    }
                                    placeholder={"SELECT STORE "}
                                    options={store}
                                    isSearchable={true}
                                    name="store"
                                    onChange={(selectedOption) => {
                                      if (selectedOption) {
                                        setFieldValue(
                                          "store",
                                          selectedOption.value
                                        );
                                        getStoreInfo(selectedOption.value);
                                      } else {
                                        setFieldValue("store", "");
                                      }
                                    }}
                                    onBlur={() =>
                                      setFieldTouched("store", true)
                                    }
                                  />
                                  <label
                                    htmlFor="floatingSelect"
                                    className="with-select"
                                  >
                                    <span>*</span> SELECT STORE
                                  </label>
                                  {errors.store && touched.store ? (
                                    <p className="help is-danger">
                                      {errors.store}
                                    </p>
                                  ) : null}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {storeAddress && (
                          <div className="store-details les-tp">
                            <div className="row">
                              <div className="col-lg-4">
                                <h6>
                                  STORE ADDRESS
                                  <span>{storeAddress}</span>
                                </h6>{" "}
                              </div>
                              <div className="col-lg-4">
                                <h6>
                                  VP
                                  <span>{storeNdos}</span>
                                </h6>{" "}
                              </div>
                              <div className="col-lg-4">
                                <h6>
                                  DOS
                                  <span>{storeDos}</span>
                                </h6>{" "}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>
                  {storeAddress && (
                    <li>
                      <div className="prfil-set">
                        <div className="form-box">
                          <div className="row">
                            <div className="col-lg-12">
                              <h4 className="prf-hed">
                                PLEASE INDICATE YOUR FUNCTION
                              </h4>
                            </div>
                          </div>

                          <div className="form-filds ">
                            <div className="row">
                              <div className="col-lg-6">
                                <div
                                  className={`form-floating ${
                                    errors.role && touched.role
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  <select
                                    className="form-select"
                                    onChange={(e) => {
                                      changeUserType(e);
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    name="role"
                                    value={values.role || ""}
                                    required
                                  >
                                    <option value="">SELECT ROLE</option>
                                    <option value="0">
                                      A Retail Sales Associate
                                    </option>
                                    <option value="1">A Store Manager</option>
                                  </select>
                                  <label>
                                    <span>*</span> I AM
                                  </label>
                                  {errors.role && touched.role ? (
                                    <p className="help is-danger">
                                      {errors.role}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              {userType === "1" && (
                                <>
                                  <div className="col-lg-6">
                                    <InputField
                                      errors={errors.manager_signature}
                                      touched={touched.manager_signature}
                                      values={values.manager_signature}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      placeholder="Signature"
                                      spanText="Signature"
                                      fieldName="manager_signature"
                                      fieldType="text"
                                      required={true}
                                    />
                                  </div>

                                  <div className="col-lg-12">
                                    <ul className="circle-list">
                                      <li>
                                        By typing your name above you agree that
                                        you are the legitimate manager of this
                                        store. You will not be able to enter
                                        sales directly, but you will be able to
                                        access the manager awards and features
                                        of this website. You also understand
                                        that there can be no more than 2
                                        registered managers for this location.
                                      </li>
                                    </ul>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                  <li>
                    <div className="prfil-set">
                      <div className="form-box">
                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="prf-hed">
                              SELECT A USERNAME AND PASSWORD
                            </h4>

                            <ul className="circle-list">
                              <li>
                                Your password must be at least 6 characters
                                long. For example a uppercase letter, a
                                lowercase and digits.
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="form-filds">
                          <div className="row">
                            <div className="col-lg-4">
                              <InputField
                                errors={errors.username}
                                checkUser={checkuser}
                                touched={touched.username}
                                values={values.username}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="USERNAME"
                                spanText="USERNAME"
                                fieldName="username"
                                fieldType="text"
                                required={true}
                              />
                              {usererror ? (
                                <p className="help is-danger">{usererror}</p>
                              ) : null}
                            </div>
                            <div className="col-lg-4">
                              <InputField
                                errors={errors.password}
                                touched={touched.password}
                                values={values.password}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="PASSWORD"
                                spanText="PASSWORD"
                                fieldName="password"
                                fieldType="password"
                                required={true}
                              />
                            </div>
                            <div className="col-lg-4">
                              <InputField
                                errors={errors.confirm_password}
                                touched={touched.confirm_password}
                                values={values.confirm_password}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="RE-TYPE PASSWORD"
                                spanText="RE-TYPE PASSWORD"
                                fieldName="confirm_password"
                                fieldType="password"
                                required={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="prfil-set">
                      <div className="form-box">
                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="prf-hed">
                              ENTER PERSONAL INFORMATION
                            </h4>
                            <ul className="circle-list">
                              <li>
                                Please note this information will be used for
                                your 1099 form as well therefore make sure you
                                enter the right information. You are not
                                eligible for a card until you enter SSN.
                              </li>
                              <li>
                                We only accept residential address, please do
                                not enter P.O Box Number or commercial
                                addresses.
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="form-filds">
                          <div className="row">
                            <div className="col-lg-4">
                              <InputField
                                errors={errors.fname}
                                touched={touched.fname}
                                values={values.fname}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="FIRST NAME"
                                spanText="FIRST NAME"
                                fieldName="fname"
                                fieldType="text"
                                required={true}
                              />
                            </div>
                            <div className="col-lg-4">
                              <InputField
                                errors={errors.lname}
                                touched={touched.lname}
                                values={values.lname}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="LAST NAME"
                                spanText="LAST NAME"
                                fieldName="lname"
                                fieldType="text"
                                required={true}
                              />
                            </div>
                            <div className="col-lg-4">
                              <InputField
                                errors={errors.email}
                                checkEmail={checkemail}
                                touched={touched.email}
                                values={values.email}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="E-MAIL"
                                spanText="E-MAIL"
                                fieldName="email"
                                fieldType="email"
                                required={true}
                              />

                              {emailerror ? (
                                <p className="help is-danger">{emailerror}</p>
                              ) : null}
                            </div>
                            <div className="col-lg-4">
                              <div
                                className={`form-floating ${
                                  errors.stateprov2 && touched.stateprov2
                                    ? "is-danger"
                                    : ""
                                }`}
                              >
                                <Select
                                  className={
                                    errors.stateprov2 && touched.stateprov2
                                      ? "error-select-search"
                                      : ""
                                  }
                                  placeholder={"SELECT STATE"}
                                  options={userState}
                                  isSearchable={true}
                                  name="stateprov2"
                                  onChange={(selectedOption) => {
                                    setFieldValue("city2", "");

                                    if (selectedOption) {
                                      setFieldValue(
                                        "stateprov2",
                                        selectedOption.value
                                      );
                                      changeUserCity(selectedOption.value);
                                    } else {
                                      setFieldValue("stateprov2", "");
                                    }
                                  }}
                                  onBlur={() =>
                                    setFieldTouched("stateprov2", true)
                                  }
                                />
                                <label className="with-select">
                                  <span>*</span> STATE
                                </label>
                                {errors.stateprov2 && touched.stateprov2 ? (
                                  <p className="help is-danger">
                                    {errors.stateprov2}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4">
                              {userCityLoader ? (
                                <h5 className="form-floating placeholder-glow mb-0">
                                  <span
                                    className="placeholder col-12"
                                    style={{
                                      height: "58px",
                                    }}
                                  ></span>
                                </h5>
                              ) : (
                                <div
                                  className={`form-floating ${
                                    errors.city2 && touched.city2
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  <Select
                                    className={
                                      errors.city2 && touched.city2
                                        ? "error-select-search"
                                        : ""
                                    }
                                    placeholder={"SELECT CITY"}
                                    options={userCity}
                                    isSearchable={true}
                                    name="city2"
                                    onChange={(selectedOption) => {
                                      if (selectedOption) {
                                        setFieldValue(
                                          "city2",
                                          selectedOption.value
                                        );
                                      } else {
                                        setFieldValue("city2", "");
                                      }
                                    }}
                                    onBlur={() =>
                                      setFieldTouched("city2", true)
                                    }
                                  />
                                  <label className="with-select">
                                    <span>*</span> CITY
                                  </label>
                                  {errors.city2 && touched.city2 ? (
                                    <p className="help is-danger">
                                      {errors.city2}
                                    </p>
                                  ) : null}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-4">
                              <InputField
                                errors={errors.zipcode}
                                touched={touched.zipcode}
                                values={values.zipcode}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="ZIP CODE"
                                spanText="ZIP CODE"
                                fieldName="zipcode"
                                fieldType="text"
                                required={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="prfil-set">
                      <div className="form-box">
                        <div className="row">
                          <div className="col-lg-12">
                            <h4 className="prf-hed">
                              DIGITAL SIGNATURE AND AGREEMENT
                            </h4>
                          </div>
                        </div>
                        <div className="form-filds">
                          <div className="row">
                            <div className="col-lg-6">
                              <InputField
                                errors={errors.signature}
                                touched={touched.signature}
                                values={values.signature}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="ELECTRONIC SIGNATURE"
                                spanText="ELECTRONIC SIGNATURE"
                                fieldName="signature"
                                fieldType="text"
                                required={true}
                              />
                            </div>
                            <div className="col-12">
                              <div className="chek-term">
                                <input
                                  id="radio-4"
                                  className="radio-custom"
                                  name="termsAndConditions"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="checkbox"
                                />
                                <label
                                  htmlFor="radio-4"
                                  className={`radio-custom-label ${
                                    errors.termsAndConditions &&
                                    touched.termsAndConditions
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  I have read and agree to the{" "}
                                  <Link to="/">
                                    www.ashleysleepuniversity.com
                                  </Link>{" "}
                                  <Link to="/termAndConditions" target="_blank">
                                    terms and conditions
                                  </Link>
                                </label>

                                {errors.termsAndConditions &&
                                touched.termsAndConditions ? (
                                  <p className="help is-danger">
                                    {errors.termsAndConditions}
                                  </p>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <input
                                type="reset"
                                className="round-red-btn w-100 mt-3 reset-reg"
                                value="RESET FORM"
                                onClick={() => window.location.reload(false)}
                              />
                            </div>

                            <div className="col-lg-6">
                              <button
                                type="submit"
                                className="round-red-btn w-100 mt-3 "
                                value="REGISTER"
                                disabled={disable}
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                REGISTER
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </section>
        <FootForgot />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
        {termsModel ? (
          <>
            <div className="modal fade show d-block" id="exampleModal">
              <div className="modal-dialog  modal-xl modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      TERMS AND CONDITIONS
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        hideMod();
                      }}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="form-box terms-box ">
                      <h4>Agreement between user and Elite Marketing</h4>
                      <p>
                        Welcome to Elite Marketing. The
                        ashleysleepuniversity.com website (the "Site") is
                        comprised of various web pages operated by Elite
                        Marketing and is offered to you conditioned on your
                        acceptance without modification of the terms,
                        conditions, and notices contained herein (the "Terms").
                        Your use of a ashleysleepuniversity.com constitutes your
                        agreement to all such Terms. Please read these terms
                        carefully, and keep a copy of them for your reference.
                      </p>
                      <p>
                        <a href="/">www.ashleysleepuniversity.com</a> is a
                        submission site.
                      </p>
                      <h4>Privacy</h4>
                      <p>
                        Your use of ashleysleepuniversity.com is subject to
                        Elite Marketing's Privacy Policy. Please review our
                        Privacy Policy, which also governs the Site and informs
                        users of our data collection practices.
                      </p>
                      <h4>Electronic Communications</h4>
                      <p>
                        Visiting ashleysleepuniversity.com or sending emails to
                        Elite Marketing constitutes electronic communications.
                        You consent to receive electronic communications and you
                        agree that all agreements, notices, disclosures and
                        other communications that we provide to you
                        electronically, via email and on the Site, satisfy any
                        legal requirement that such communications be in
                        writing.
                      </p>
                      <h4>Your account</h4>
                      <p>
                        If you use this site, you are responsible for
                        maintaining the confidentiality of your account and
                        password and for restricting access to your computer,
                        and you agree to accept responsibility for all
                        activities that occur under your account or password.
                        You may not assign or otherwise transfer your account to
                        any other person or entity. You acknowledge that Ashley
                        Sleep or Elite Marketing are not responsible for third
                        party access to your account that results from theft or
                        misappropriation of your account. Ashley Sleep or Elite
                        Marketing and its associates reserve the right to refuse
                        or cancel service, terminate accounts, or remove or edit
                        content in our sole discretion.
                      </p>
                      <p>
                        Elite Marketing does not knowingly collect, either
                        online or offline, personal information from persons
                        under the age of thirteen. If you are under 18, you may
                        use ashleysleepuniversity.com only with permission of a
                        parent or guardian.
                      </p>
                      <h4>
                        No unlawful or prohibited use/Intellectual Property
                      </h4>
                      <p>
                        You are granted a non-exclusive, non-transferable,
                        revocable license to access and use{" "}
                        <a href="/">www.ashleysleepuniversity.com</a> strictly
                        in accordance with these terms of use. As a condition of
                        your use of the Site, you warrant to Elite Marketing
                        that you will not use the Site for any purpose that is
                        unlawful or prohibited by these Terms. You may not use
                        the Site in any manner which could damage, disable,
                        overburden, or impair the Site or interfere with any
                        other party's use and enjoyment of the Site. You may not
                        obtain or attempt to obtain any materials or information
                        through any means not intentionally made available or
                        provided for through the Site.
                      </p>
                      <p>
                        All content included as part of the Service, such as
                        text, graphics, logos, images, as well as the
                        compilation thereof, and any software used on the Site,
                        is the property of Elite Marketing or its suppliers and
                        protected by copyright and other laws that protect
                        intellectual property and proprietary rights. You agree
                        to observe and abide by all copyright and other
                        proprietary notices, legends or other restrictions
                        contained in any such content and will not make any
                        changes thereto. You will not modify, publish, transmit,
                        reverse engineer, participate in the transfer or sale,
                        create derivative works, or in any way exploit any of
                        the content, in whole or in part, found on the Site.
                        www.ashleysleepuniversity.com content is not for resale.
                        Your use of the Site does not entitle you to make any
                        unauthorized use of any protected content, and in
                        particular you will not delete or alter any proprietary
                        rights or attribution notices in any content. You will
                        use protected content solely for your personal use, and
                        will make no other use of the content without the
                        express written permission of Elite Marketing and the
                        copyright owner. You agree that you do not acquire any
                        ownership rights in any protected content. We do not
                        grant you any licenses, express or implied, to the
                        intellectual property of Elite Marketing or our
                        licensors except as expressly authorized by these Terms.
                      </p>
                      <h4>International Users</h4>
                      <p>
                        The Service is controlled, operated and administered by
                        Elite Marketing from our offices within the USA. If you
                        access the Service from a location outside the USA, you
                        are responsible for compliance with all local laws. You
                        agree that you will not use the Elite Marketing content
                        accessed through{" "}
                        <a href="/termAndConditions/login.html">
                          www.ashleysleepuniversity.com
                        </a>{" "}
                        in any country or in any manner prohibited by any
                        applicable laws, restrictions or regulations.
                      </p>
                      <h4>Indemnification</h4>
                      <p>
                        You agree to indemnify, defend and hold harmless Elite
                        Marketing, its officers, directors, employees, agents
                        and third parties, for any losses, costs, liabilities
                        and expenses (including reasonable attorney's fees)
                        relating to or arising out of your use of or inability
                        to use the Site or services, any user postings made by
                        you, your violation of any terms of this Agreement or
                        your violation of any rights of a third party, or your
                        violation of any applicable laws, rules or regulations.
                        Elite Marketing reserves the right, at its own cost, to
                        assume the exclusive defense and control of any matter
                        otherwise subject to indemnification by you, in which
                        event you will fully cooperate with Elite Marketing in
                        asserting any available defenses.
                      </p>
                      <p>
                        Arbitration In the event the parties are not able to
                        resolve any dispute between them arising out of or
                        concerning these Terms and Conditions, or any provisions
                        hereof, whether in contract, tort, or otherwise at law
                        or in equity for damages or any other relief, then such
                        dispute shall be resolved only by final and binding
                        arbitration pursuant to the Federal Arbitration Act,
                        conducted by a single neutral arbitrator and
                        administered by the American Arbitration Association, or
                        a similar arbitration service selected by the parties,
                        in a location mutually agreed upon by the parties. The
                        arbitrators award shall be final, and judgment may be
                        entered upon it in any court having jurisdiction. In the
                        event that any legal or equitable action, proceeding or
                        arbitration arises out of or concerns these Terms and
                        Conditions, the prevailing party shall be entitled to
                        recover its costs and reasonable attorney's fees. The
                        parties agree to arbitrate all disputes and claims in
                        regards to these Terms and Conditions or any disputes
                        arising as a result of these Terms and Conditions,
                        whether directly or indirectly, including Tort claims
                        that are a result of these Terms and Conditions. The
                        parties agree that the Federal Arbitration Act governs
                        the interpretation and enforcement of this provision.
                        The entire dispute, including the scope and
                        enforceability of this arbitration provision shall be
                        determined by the Arbitrator. This arbitration provision
                        shall survive the termination of these Terms and
                        Conditions.
                      </p>
                      <p>
                        Class Action Waiver Any arbitration under these Terms
                        and Conditions will take place on an individual basis;
                        class arbitrations and class/representative/collective
                        actions are not permitted. THE PARTIES AGREE THAT A
                        PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S
                        INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
                        MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR
                        REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A
                        PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER.
                        Further, unless both you and Employer agree otherwise,
                        the arbitrator may not consolidate more than one
                        person's claims, and may not otherwise preside over any
                        form of a representative or class proceeding.
                      </p>
                      <h4>Liability disclaimer</h4>
                      <p>
                        The information, software, products, and services
                        included in or available through the site may include
                        inaccuracies or typographical errors. Changes are
                        periodically added to the information herein. Elite
                        marketing and/or its suppliers may make improvements
                        and/or changes in the site at any time.
                      </p>
                      <p>
                        Elite marketing and/or its suppliers make no
                        representations about the suitability, reliability,
                        availability, timeliness, and accuracy of the
                        information, software, products, services and related
                        graphics contained on the site for any purpose. To the
                        maximum extent permitted by applicable law, all such
                        information, software, products, services and related
                        graphics are provided "as is" without warranty or
                        condition of any kind. Elite marketing and/or its
                        suppliers hereby disclaim all warranties and conditions
                        with regard to this information, software, products,
                        services and related graphics, including all implied
                        warranties or conditions of merchantability, fitness for
                        a particular purpose, title and noninfringement.
                      </p>
                      <p>
                        To the maximum extent permitted by applicable law, in no
                        event shallelite marketing and/or its suppliers be
                        liable for any direct, indirect, punitive, incidental,
                        special, consequential damages or any damages whatsoever
                        including, without limitation, damages for loss of use,
                        data or profits, arising out of or in any way connected
                        with the use or performance of the site, with the delay
                        or inability to use the site or related services, the
                        provision of or failure to provide services, or for any
                        information, software, products, services and related
                        graphics obtained through the site, or otherwise arising
                        out of the use of the site, whether based on contract,
                        tort, negligence, strict liability or otherwise, even if
                        elite marketing or any of its suppliers has been advised
                        of the possibility of damages. Because some
                        states/jurisdictions do not allow the exclusion or
                        limitation of liability for consequential or incidental
                        damages, the above limitation may not apply to you. If
                        you are dissatisfied with any portion of the site, or
                        with any of these terms of use, your sole and exclusive
                        remedy is to discontinue using the site.
                      </p>
                      <h4>Termination/access restriction</h4>
                      <p>
                        Elite Marketing and Ashley Sleep reserve the right, in
                        its sole discretion, to terminate your access to the
                        Site and the related services or any portion thereof at
                        any time, without notice. To the maximum extent
                        permitted by law, this agreement is governed by
                        applicable state laws and you hereby consent to the
                        exclusive jurisdiction in all disputes arising out of or
                        relating to the use of the Site. Use of the Site is
                        unauthorized in any jurisdiction that does not give
                        effect to all provisions of these Terms, including,
                        without limitation, this section.
                      </p>
                      <p>
                        You agree that no joint venture, partnership,
                        employment, or agency relationship exists between you
                        and Elite Marketing as a result of this agreement or use
                        of the Site. Elite Marketing's performance of this
                        agreement is subject to existing laws and legal process,
                        and nothing contained in this agreement is in derogation
                        of Elite Marketing's right to comply with governmental,
                        court and law enforcement requests or requirements
                        relating to your use of the Site or information provided
                        to or gathered by Elite Marketing with respect to such
                        use. If any part of this agreement is determined to be
                        invalid or unenforceable pursuant to applicable law
                        including, but not limited to, the warranty disclaimers
                        and liability limitations set forth above, then the
                        invalid or unenforceable provision will be deemed
                        superseded by a valid, enforceable provision that most
                        closely matches the intent of the original provision and
                        the remainder of the agreement shall continue in effect.
                      </p>
                      <p>
                        Unless otherwise specified herein, this agreement
                        constitutes the entire agreement between the user and
                        Elite Marketing with respect to the Site and it
                        supersedes all prior or contemporaneous communications
                        and proposals, whether electronic, oral or written,
                        between the user and Elite Marketing with respect to the
                        Site. A printed version of this agreement and of any
                        notice given in electronic form shall be admissible in
                        judicial or administrative proceedings based upon or
                        relating to this agreement to the same extent and
                        subject to the same conditions as other business
                        documents and records originally generated and
                        maintained in printed form. It is the express wish to
                        the parties that this agreement and all related
                        documents be written in English.
                      </p>
                      <h4>Changes to Terms</h4>
                      <p>
                        Elite Marketing reserves the right, in its sole
                        discretion, to change the Terms under which are offered.
                        The most current version of the Terms will supersede all
                        previous versions. Elite Marketing encourages you to
                        periodically review the Terms to stay informed of our
                        updates.
                      </p>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-lg-6">
                          <button
                            type="button"
                            onClick={() => {
                              register();
                            }}
                            className="round-red-btn w-100 mt-3 reset-reg border-0"
                          >
                            I agree with terms and conditions
                          </button>
                        </div>
                        <div className="col-lg-6">
                          <button
                            type="button"
                            onClick={() => {
                              hideMod();
                            }}
                            className="round-red-btn w-100 mt-3  border-0"
                            data-bs-dismiss="modal"
                          >
                            I disagree with terms and conditions
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-backdrop fade show"></div>
          </>
        ) : null}
      </div>
    </>
  );
}
