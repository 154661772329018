import React from 'react'

export default function FootForgot() {
  return (
    <footer className="text-center">
        <div className="container">
            <span>© Copyrights 2022 all rights reserved by ashleysleepuniversity.com</span>
        </div>
    </footer>
  )
}
