import { useFormik } from "formik";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import { UserContext } from "../../../context/UserContext";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { quizSearch } from "../../../schema";
import QuizService from "../../../services/quiz.service";
import Footer from "../Includes/Footer";
import SlidingBg from "../Includes/SlidingBg";

import ToTop from "../Includes/ToTop";
import Pagination from "react-js-pagination";
import moment from "moment";
import "./GetPassedResult.css";
import { Helmet } from "react-helmet";
// import resultData from "./table/data.js";

export default function GetPassedResult() {
  const TITLE = "ASU | View All Result";

  const { state } = useContext(UserContext);
  const { id } = state;

  const [resultData, setResultData] = useState([]);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [limit, setLimit] = useState("10");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const dateRef = useRef(null);
  const changeDate = (e) => {
    setLoading(true);

    const getResultData = async () => {
      setLoading(true);
      if (id) {
        try {
          if (values.fieldtype === "" && values.searchval === "") {
            const response = await QuizService.getSearchResultsById(
              id,
              "",
              e,
              limit
            );
            let resultData;
            if (response.status === 206) {
              resultData = response.data.response;
              setResultData(resultData);
              setTotalResults(response.data.records);
              setTotalPages(response.data.total_pages);
              setLimit(response.data.per_page);
              setLoading(false);
            }
          } else if (values.fieldtype === "" || values.searchval === "") {
            setLoading(false);

            handleSubmit();
          } else {
            const response = await QuizService.getSearchWithDateResultsById(
              id,
              values.fieldtype,
              values.searchval,
              e,
              limit
            );
            let resultData;
            if (response.status === 206) {
              resultData = response.data.response;
              setResultData(resultData);
              setTotalResults(response.data.records);
              setTotalPages(response.data.total_pages);
              setLimit(response.data.per_page);
              setLoading(false);
            }
          }
        } catch (err) {
          setResultData([]);
          setTotalResults("0");
          setTotalPages("1");
          if (err.response.status === 404) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      }
    };
    getResultData();
  };

  const changeLimit = (e) => {
    let newLimit = e;
    setLimit(newLimit);
    // console.log(limit);
    const getResultData = async () => {
      setLoading(true);
      if (id) {
        try {
          if (
            values.fieldtype === "" &&
            values.searchval === "" &&
            dateRef.current.value === ""
          ) {
            const response = await QuizService.getLimitResultsById(
              id,
              newLimit
            );

            let resultData;
            if (response.status === 206) {
              resultData = response.data.response;
              setResultData(resultData);
              setLimit(response.data.per_page);
              setLoading(false);
            }
          } else if (values.fieldtype === "" && values.searchval === "") {
            if (dateRef.current.value !== "") {
              const response = await QuizService.getSearchResultsById(
                id,
                "",
                dateRef.current.value,
                newLimit
              );
              let resultData;
              if (response.status === 206) {
                resultData = response.data.response;
                setResultData(resultData);
                setTotalResults(response.data.records);
                setTotalPages(response.data.total_pages);
                setLimit(response.data.per_page);
                setLoading(false);
              }
            }
          } else if (values.fieldtype === "" || values.searchval === "") {
            handleSubmit();
            setLoading(false);
          } else if (values.fieldtype !== "" && values.searchval !== "") {
            if (dateRef.current.value !== "") {
              const response = await QuizService.getSearchWithDateResultsById(
                id,
                values.fieldtype,
                values.searchval,
                dateRef.current.value,
                newLimit
              );
              let resultData;
              if (response.status === 206) {
                resultData = response.data.response;
                setResultData(resultData);
                setTotalResults(response.data.records);
                setTotalPages(response.data.total_pages);
                setLimit(response.data.per_page);
                setLoading(false);
              } else {
                setTotalResults("0");
                setTotalPages("1");
                setResultData([]);
                setLoading(false);
              }
            } else {
              const response = await QuizService.getSearchResultsById(
                id,
                values.fieldtype,
                values.searchval,
                newLimit
              );

              let resultData;
              if (response.status === 206) {
                setLoading(false);
                resultData = response.data.response;
                setResultData(resultData);
                setTotalResults(response.data.records);
                setTotalPages(response.data.total_pages);
                setLimit(response.data.per_page);
              } else {
                setTotalResults("0");
                setTotalPages("1");
                setResultData([]);
                setLoading(false);
                Swal.fire({
                  text: "Error fetching record",
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              }
            }
          }
        } catch (err) {
          if (err.response.status === 404) {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      }
    };
    getResultData();
  };

  const changePagination = (e) => {
    setCurrentPage(e);

    // console.log(e)
    let pageNo = e;
    // setLimit(newLimit);
    // console.log(limit);
    const getResultData = async () => {
      setLoading(true);
      if (id) {
        try {
          const response = await QuizService.getPaginatedResultsById(
            id,
            pageNo
          );

          let resultData;
          if (response.status === 206) {
            resultData = response.data.response;
            setResultData(resultData);
            setLimit(response.data.per_page);
            setLoading(false);
          }
        } catch (err) {
          if (err.response.status === 404) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      }
    };
    getResultData();
  };

  useLayoutEffect(() => {
    const getResultData = async () => {
      setLoading(true);
      if (id) {
        try {
          const response = await QuizService.getResultsById(id);

          let resultData;
          if (response.status === 206) {
            resultData = response.data.response;
            setResultData(resultData);
            setLimit(response.data.per_page);
            setTotalResults(response.data.records);
            setTotalPages(response.data.total_pages);
            setLoading(false);
          }
        } catch (err) {
          setTotalPages("1");
          if (err.response.status === 404) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      }
    };
    getResultData();
  }, [id]);

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table caption-top align-middle table-borderless mt-2">
          <caption>
            You can search your required data by putting text in search box
          </caption>

          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("id")}
                className={getClassNamesFor("id")}
              >
                No
              </th>
              <th
                scope="col"
                onClick={() => requestSort("course_name")}
                className={getClassNamesFor("course_name")}
              >
                Course Title
              </th>
              <th
                scope="col"
                onClick={() => requestSort("final_result")}
                className={getClassNamesFor("final_result")}
              >
                Percentage
              </th>
              <th
                scope="col"
                onClick={() => requestSort("Status")}
                className={getClassNamesFor("Status")}
              >
                Status
              </th>
              <th
                scope="col"
                onClick={() => requestSort("created_at")}
                className={getClassNamesFor("created_at")}
              >
                Test Date
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={el.id}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.course_name}</td>
                  <td>{el.final_result}</td>
                  <td
                    className={` ${"Passed" === "Passed" ? "pasad" : "faled"}`}
                  >
                    Passed
                  </td>
                  <td>{moment(el.created_at).format("MM-DD-YYYY")}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={limit}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Lage"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values, action) => {
        // action.resetForm();

        searchData(action);
      },
    });

  const searchData = async (action) => {
    setLoading(true);

    try {
      if (dateRef.current.value === "") {
        const response = await QuizService.getSearchResultsById(
          id,
          values.fieldtype,
          values.searchval,
          limit
        );

        let resultData;
        if (response.status === 206) {
          setLoading(false);
          resultData = response.data.response;
          setResultData(resultData);
          setTotalResults(response.data.records);
          setTotalPages(response.data.total_pages);
          setLimit(response.data.per_page);
        } else {
          setTotalResults("0");
          setTotalPages("1");
          setResultData([]);
          setLoading(false);
          Swal.fire({
            text: "Error fetching record",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      } else {
        const response = await QuizService.getSearchWithDateResultsById(
          id,
          values.fieldtype,
          values.searchval,
          dateRef.current.value,
          limit
        );
        let resultData;
        if (response.status === 206) {
          resultData = response.data.response;
          setResultData(resultData);
          setTotalResults(response.data.records);
          setTotalPages(response.data.total_pages);
          setLimit(response.data.per_page);
          setLoading(false);
        }
      }
    } catch (err) {
      setTotalPages("1");
      setTotalResults("0");
      if (err.response.status === 500) {
        setLoading(false);
        Swal.fire({
          text: "Something went wrong, try again",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        setLoading(false);
        setResultData([]);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="pad-bot">
        <ToTop />
        <SlidingBg />
        <section className="slide-up">
          <div className="container">
            <div className="slides-here">
              <h2 className="slide-heading">
                <span>QUIZ RESULT </span>[{totalResults}]
              </h2>

              <div className="form-box rzl-tabl">
                <h3>Quiz Result(s)</h3>
                <form onSubmit={handleSubmit} noValidate>
                  <div className="row mt-3">
                    <div className="col-lg-3">
                      <select
                        className={`form-select ${
                          errors.fieldtype && touched.fieldtype
                            ? "is-danger"
                            : ""
                        }`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="fieldtype"
                        value={values.fieldtype || ""}
                        required
                      >
                        <option value="">Select</option>
                        <option value="1">Title</option>
                        <option value="2">Percentage</option>
                        //FIXME
                        {/* <option value="3">Status</option> */}
                      </select>
                      {errors.fieldtype && touched.fieldtype ? (
                        <p className="help is-danger">{errors.fieldtype}</p>
                      ) : null}
                    </div>

                    <div className="col-lg-4 ">
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control ${
                            errors.searchval && touched.searchval
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="searchval"
                          value={values.searchval || ""}
                          required
                        />

                        <button
                          className="btn btn-outline-secondary sebmit-dat"
                          type="submit"
                        >
                          Search
                        </button>
                      </div>
                      {errors.searchval && touched.searchval ? (
                        <p className="help is-danger">{errors.searchval}</p>
                      ) : null}
                    </div>
                    <div className="col-lg-2 ">
                      <input
                        type="date"
                        ref={dateRef}
                        className="form-control"
                        onChange={(e) => changeDate(e.target.value)}
                        placeholder="dd-mm-yyyy"
                      />
                    </div>
                    <div className="col-lg-2 ">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => changeLimit(e.target.value)}
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                    <div className="col-lg-1 ">
                      <button
                        className="btn btn-outline-secondary"
                        type="reset"
                        onClick={() => window.location.reload(false)}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
                <div className="table-responsive">
                  <Table data={resultData} rowsPerPage={limit} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </>
  );
}
