import { useFormik } from "formik";
import React, { useContext, useLayoutEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { quizSearch } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";
import StoreService from "../../../services/store.service";
import HeaderSidebar from "../includes/HeaderSidebar";
import ToTop from "../includes/ToTop";
import $ from "jquery";
import MoveTable from "../includes/MoveTable";
import AdminFooter from "../includes/AdminFooter";
import { UserContext } from "../../../context/UserContext";

export default function QuizResult() {
  const { state } = useContext(UserContext);
  const { id, uType } = state;
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState("");
  const [resultData, setResultData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [LicenseeGroup, setLicenseeGroup] = useState([]);
  const [exportResult, setExportResult] = useState(false);
  const [rsaId, setRsaId] = useState("0");
  const [storeCityId, setStoreCityId] = useState("0");
  const [storeStateId, setStoreStateId] = useState("0");

  const changeLimit = (e) => {
    let newLimit = e;
    // console.log(limit);
    $("#getRsa").val("");
    $("#store_state").val("");
    $("#store_city").val("");
    setExportResult(false);

    const getResultData = async () => {
      try {
        if (uType === "dos") {
          if (values.fieldtype === "" && values.searchval === "") {
            setLoading(true);

            setLimit(newLimit);

            const response = await AdminListService.getDosNewLimitQuizList(
              newLimit,
              id
            );

            let resultData;
            if (response.status === 206) {
              setLoading(false);
              resultData = response.data.response;
              setResultData(resultData);
              setTotalResults(response.data.records);
              setCurrentPage(1);
              setTotalPages(response.data.total_pages);
              setLimit(response.data.per_page);
            } else {
              setTotalResults("0");
              setTotalPages("1");
              setResultData([]);
              setLoading(false);
            }
          } else if (values.fieldtype !== "" && values.searchval !== "") {
            setLoading(true);

            setLimit(newLimit);

            const response = await AdminListService.getDosSearchQuizList(
              values.fieldtype,
              values.searchval,
              newLimit,
              id
            );

            let resultData;
            if (response.status === 206) {
              setLoading(false);
              resultData = response.data.response;

              setResultData(resultData);
              setCurrentPage(1);
              setTotalResults(response.data.records);
              setTotalPages(response.data.total_pages);
              setLimit(response.data.per_page);
            } else {
              setTotalResults("0");
              setTotalPages("1");
              setResultData([]);
              setLoading(false);
            }
          } else if (values.fieldtype !== "" || values.searchval !== "") {
            handleSubmit();
          }
        } else {
          if (values.fieldtype === "" && values.searchval === "") {
            setLoading(true);

            setLimit(newLimit);

            const response = await AdminListService.getNewLimitQuizList(
              newLimit
            );

            let resultData;
            if (response.status === 206) {
              setLoading(false);
              resultData = response.data.response;
              setResultData(resultData);
              setTotalResults(response.data.records);
              setCurrentPage(1);
              setTotalPages(response.data.total_pages);
              setLimit(response.data.per_page);
            } else {
              setTotalResults("0");
              setTotalPages("1");
              setResultData([]);
              setLoading(false);
            }
          } else if (values.fieldtype !== "" && values.searchval !== "") {
            setLoading(true);

            setLimit(newLimit);

            const response = await AdminListService.getSearchQuizList(
              values.fieldtype,
              values.searchval,
              newLimit
            );

            let resultData;
            if (response.status === 206) {
              setLoading(false);
              resultData = response.data.response;

              setResultData(resultData);
              setCurrentPage(1);
              setTotalResults(response.data.records);
              setTotalPages(response.data.total_pages);
              setLimit(response.data.per_page);
            } else {
              setTotalResults("0");
              setTotalPages("1");
              setResultData([]);
              setLoading(false);
            }
          } else if (values.fieldtype !== "" || values.searchval !== "") {
            handleSubmit();
          }
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setResultData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  };

  const changePagination = (e) => {
    // console.log(e)
    let pageNo = e;
    // setLimit(newLimit);
    // console.log(limit);
    const getResultData = async () => {
      try {
        if (uType === "dos") {
          if (values.fieldtype === "" && values.searchval === "") {
            setCurrentPage(e);
            setLoading(true);
            const response = await AdminListService.getDosPaginationQuizList(
              pageNo,
              limit,
              id
            );

            let resultData;
            if (response.status === 206) {
              resultData = response.data.response;

              setResultData(resultData);
              setLimit(response.data.per_page);
              setLoading(false);
            }
          } else if (values.fieldtype !== "" && values.searchval !== "") {
            setCurrentPage(e);
            setLoading(true);

            const response =
              await AdminListService.getDosSearchQuizListWithPagination(
                values.fieldtype,
                values.searchval,
                pageNo,
                limit,
                id
              );

            let resultData;
            if (response.status === 206) {
              setLoading(false);
              resultData = response.data.response;

              setResultData(resultData);
              setTotalResults(response.data.records);
              setTotalPages(response.data.total_pages);
              setLimit(response.data.per_page);
            } else {
              setTotalResults("0");
              setTotalPages("1");
              setResultData([]);
              setLoading(false);
            }
          } else if (values.fieldtype !== "" || values.searchval !== "") {
            handleSubmit();
            setLoading(false);
          }
        } else {
          if (values.fieldtype === "" && values.searchval === "") {
            setCurrentPage(e);
            setLoading(true);
            const response = await AdminListService.getPaginationQuizList(
              pageNo,
              limit
            );

            let resultData;
            if (response.status === 206) {
              resultData = response.data.response;

              setResultData(resultData);
              setLimit(response.data.per_page);
              setLoading(false);
            }
          } else if (values.fieldtype !== "" && values.searchval !== "") {
            setCurrentPage(e);
            setLoading(true);

            const response =
              await AdminListService.getSearchQuizListWithPagination(
                values.fieldtype,
                values.searchval,
                pageNo,
                limit
              );

            let resultData;
            if (response.status === 206) {
              setLoading(false);
              resultData = response.data.response;

              setResultData(resultData);
              setTotalResults(response.data.records);
              setTotalPages(response.data.total_pages);
              setLimit(response.data.per_page);
            } else {
              setTotalResults("0");
              setTotalPages("1");
              setResultData([]);
              setLoading(false);
            }
          } else if (values.fieldtype !== "" || values.searchval !== "") {
            handleSubmit();
            setLoading(false);
          }
        }
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  };
  const getCityData = async () => {
    setLoading(true);
    var st_state = $("#store_state").val();
    if (st_state === "all") {
      $("#store_city").val("");
      setCityData([]);
    } else {
      $("#store_city").val("");
      try {
        const { data } = await StoreService.getCityByStateId(
          $("#store_state").children(":selected").attr("id")
        );

        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            id: value.id,
            key: value.name,
            value: value.name,
          });
        });

        setCityData([
          { key: "Select City", value: "" },
          ...results,
          { key: "All", value: "all" },
        ]);

        setLoading(false);
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    }
  };

  const getStateData = async () => {
    setLoading(true);

    var st_rsa = $("#getRsa").val();
    $("#store_state").val("");
    $("#store_city").val("");
    try {
      const { status, data } = await AdminListService.getStateByLicenseeGroup(
        st_rsa
      );

      if (status === 200) {
        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            id: value.id,
            key: value.name,
            value: value.name,
          });
        });

        setStateData([
          { key: "Select State", value: "" },
          ...results,
          { key: "All", value: "all" },
        ]);
      }
      setLoading(false);
    } catch (err) {
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const searchData = async (action) => {
    setLoading(true);
    setCurrentPage(1);

    try {
      $("#getRsa").val("");
      $("#store_state").val("");
      $("#store_city").val("");
      setCityData([]); // empty the city list data
      setStateData([]); // empty the state list data
      setExportResult(false);

      if (limit === 10000) {
        if (uType === "dos") {
          const response = await AdminListService.getDosSearchQuizList(
            values.fieldtype,
            values.searchval,
            "10",
            id
          );

          let resultData;
          if (response.status === 206) {
            setLoading(false);
            resultData = response.data.response;
            setResultData(resultData);
            setCurrentPage(1);
            setTotalResults(response.data.records);
            setTotalPages(response.data.total_pages);
            setLimit(response.data.per_page);
          } else {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          }
        } else {
          const response = await AdminListService.getSearchQuizList(
            values.fieldtype,
            values.searchval,
            "10"
          );

          let resultData;
          if (response.status === 206) {
            setLoading(false);
            resultData = response.data.response;
            setResultData(resultData);
            setCurrentPage(1);
            setTotalResults(response.data.records);
            setTotalPages(response.data.total_pages);
            setLimit(response.data.per_page);
          } else {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          }
        }
      } else {
        if (uType === "dos") {
          const response = await AdminListService.getDosSearchQuizList(
            values.fieldtype,
            values.searchval,
            limit,
            id
          );

          let resultData;
          if (response.status === 206) {
            setLoading(false);
            resultData = response.data.response;
            setResultData(resultData);
            setCurrentPage(1);
            setTotalResults(response.data.records);
            setTotalPages(response.data.total_pages);
            setLimit(response.data.per_page);
          } else {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          }
        } else {
          const response = await AdminListService.getSearchQuizList(
            values.fieldtype,
            values.searchval,
            limit
          );

          let resultData;
          if (response.status === 206) {
            setLoading(false);
            resultData = response.data.response;
            setResultData(resultData);
            setCurrentPage(1);
            setTotalResults(response.data.records);
            setTotalPages(response.data.total_pages);
            setLimit(response.data.per_page);
          } else {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          }
        }
      }
    } catch (err) {
      setTotalPages("1");
      setTotalResults("0");
      if (err.response.status === 500) {
        setLoading(false);
      } else {
        setLoading(false);
        setResultData([]);
      }
    }
  };
  const searchQuiz = async () => {
    setLoading(true);
    setCurrentPage(1);
    values.fieldtype = "";
    values.searchval = "";
    var st_rsa = $("#getRsa").val();
    var st_state = $("#store_state").val();
    var st_city = $("#store_city").val();
    if (st_rsa === null) {
      st_rsa = "";
    }
    if (st_state === null) {
      st_state = "";
    }
    if (st_city === null) {
      st_city = "";
    }
    if (st_rsa !== "") {
      setRsaId($("#getRsa").children(":selected").attr("id"));
    } else {
      setRsaId("0");
    }

    if (st_state !== "") {
      if (st_state === "all") {
        setStoreStateId("0");
      } else {
        setStoreStateId($("#store_state").children(":selected").attr("id"));
      }
    } else {
      setStoreStateId("0");
    }

    if (st_city !== "") {
      if (st_city === "all") {
        setStoreCityId("0");
      } else {
        setStoreCityId($("#store_city").children(":selected").attr("id"));
      }
    } else {
      setStoreCityId("0");
    }

    try {
      if (uType === "dos") {
        const response =
          await AdminListService.getDosSearchQuizListWithchangeVal(
            st_rsa,
            st_state,
            st_city,
            "10000",
            id
          );

        let resultData;
        if (response.status === 206) {
          resultData = response.data.response;
          setResultData(resultData);
          setTotalResults(response.data.records);
          setTotalPages(response.data.total_pages);
          setLimit(response.data.per_page);
          setExportResult(true);
          setLoading(false);
        } else {
          setTotalResults("0");
          setTotalPages("1");
          setResultData([]);
          setLoading(false);
          setExportResult(false);
        }
      } else {
        const response = await AdminListService.getSearchQuizListWithchangeVal(
          st_rsa,
          st_state,
          st_city,
          "10000"
        );

        let resultData;
        if (response.status === 206) {
          resultData = response.data.response;
          setResultData(resultData);
          setTotalResults(response.data.records);
          setTotalPages(response.data.total_pages);
          setLimit(response.data.per_page);
          setExportResult(true);
          setLoading(false);
        } else {
          setTotalResults("0");
          setTotalPages("1");
          setResultData([]);
          setLoading(false);
          setExportResult(false);
        }
      }
    } catch (err) {
      setTotalPages("1");
      setTotalResults("0");
      setExportResult(false);

      if (err.response.status === 500) {
        setLoading(false);
      } else {
        setLoading(false);
        setResultData([]);
      }
    }
  };

  useLayoutEffect(() => {
    const getQuizData = async () => {
      setLoading(true);

      try {
        if (uType === "dos") {
          const response = await AdminListService.getDosQuizList(id);

          let resultData;
          if (response.status === 206) {
            resultData = response.data.response;
            setResultData(resultData);
            setLimit(response.data.per_page);
            setTotalResults(response.data.records);
            setTotalPages(response.data.total_pages);
            setLoading(false);
          }
        } else {
          const response = await AdminListService.getQuizList();

          let resultData;
          if (response.status === 206) {
            resultData = response.data.response;
            setResultData(resultData);
            setLimit(response.data.per_page);
            setTotalResults(response.data.records);
            setTotalPages(response.data.total_pages);
            setLoading(false);
          }
        }
      } catch (err) {
        setTotalPages("1");
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };

    const getLicenseeGroupData = async () => {
      try {
        const { data } = await AdminListService.getLicenseeGroup();

        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            id: value.id,
            key: value.name,
            value: value.name,
          });
        });

        setLicenseeGroup([
          { key: "Select Licensee Group ", value: "" },
          ...results,
        ]);
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };

    getQuizData();
    getLicenseeGroupData();
  }, []);

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table caption-top align-middle table-borderless table-style-1">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("id")}
                className={getClassNamesFor("id")}
              >
                No
              </th>
              <th
                scope="col"
                onClick={() => requestSort("store_name")}
                className={getClassNamesFor("store_name")}
              >
                Licensee Group/Store Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("state_name")}
                className={getClassNamesFor("state_name")}
              >
                Store State
              </th>
              <th
                scope="col"
                onClick={() => requestSort("city_name")}
                className={getClassNamesFor("city_name")}
              >
                Store City
              </th>
              <th
                scope="col"
                onClick={() => requestSort("first_name")}
                className={getClassNamesFor("first_name")}
              >
                Sales Associate Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("email")}
                className={getClassNamesFor("email")}
              >
                Email
              </th>
              <th
                scope="col"
                onClick={() => requestSort("last_course")}
                className={getClassNamesFor("last_course")}
              >
                Last Course Completed
              </th>
              <th
                scope="col"
                onClick={() => requestSort("status")}
                className={getClassNamesFor("status")}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.store_name}</td>
                  <td>{el.state_name}</td>
                  <td>{el.city_name}</td>
                  <td>{el.first_name + " " + el.last_name}</td>
                  <td>{el.email}</td>
                  <td
                    className={` ${el.last_course === "Senior" ? "orng" : ""}
                    ${el.last_course === "Freshman" ? "gren" : ""}
                     ${el.last_course === "Sophomore" ? "yelow" : ""}
                     ${el.last_course === "Junior" ? "redd" : ""}
                     ${el.last_course === "Not Passed Yet" ? "initia" : ""}

                     `}
                  >
                    {el.last_course}
                  </td>
                  <td
                    className={` ${el.status === "Senior" ? "orng" : ""}
                      ${el.status === "On Freshman" ? "gren" : ""}
                       ${el.status === "Sophomore" ? "yelow" : ""}
                       ${el.status === "Junior" ? "redd" : ""}
                       ${el.status === "Graduated" ? "gradu" : ""}
                       `}
                  >
                    {el.status}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values, action) => {
        // action.resetForm();
        searchData(action);
      },
    });
  return (
    <div className="semi-dark">
      <div className="wrapper">
        <HeaderSidebar />
        <main className="page-content">
          <div className="row">
            <div className="col">
              <div className="manage-heading-2">
                <h2>
                  View Quiz Results <span>[{totalResults}]</span>
                </h2>
              </div>

              <div className="slides-here">
                <div className="alert alert-info">
                  <strong>Info!</strong> You can search your required data by
                  putting text in search box
                </div>
                <div className="main-content-box rzl-tabl">
                  <div className="manage-territories-box">
                    <div className="row">
                      <div className="col-lg-12">
                        <h2 className="manage-territories-heading">
                          View Quiz Results
                        </h2>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group custom-group mb-0">
                          <label className="form-label">Show Quiz From:</label>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div
                          className={`form-group custom-group ${
                            LicenseeGroup.length ? "" : "placeholder-glow"
                          }`}
                        >
                          <select
                            className={`form-select ${
                              LicenseeGroup.length ? "" : "placeholder"
                            }`}
                            id="getRsa"
                            name="rsaname"
                            onChange={() => {
                              getStateData();
                              searchQuiz();
                            }}
                            // onChange={searchQuiz}
                          >
                            {LicenseeGroup.map((res, index) => {
                              return (
                                <option
                                  key={index}
                                  disabled={res.value === "" ? true : false}
                                  selected={res.value === "" ? true : false}
                                  value={res.value}
                                  id={res.id}
                                >
                                  {res.key}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-group custom-group ">
                          <select
                            id="store_state"
                            className="store_state form-select"
                            onChange={() => {
                              getCityData();
                              searchQuiz();
                            }}
                            disabled={stateData.length ? false : true}
                          >
                            {stateData.length === 0 ? (
                              <option
                                disabled={true}
                                selected={true}
                                value=""
                                id="s"
                              >
                                Select State
                              </option>
                            ) : null}
                            {stateData.map((res, index) => {
                              return (
                                <option
                                  key={index}
                                  disabled={res.value === "" ? true : false}
                                  selected={res.value === "" ? true : false}
                                  value={res.value}
                                  id={res.id}
                                >
                                  {res.key}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-group custom-group ">
                          <select
                            className="form-select "
                            id="store_city"
                            onChange={searchQuiz}
                            disabled={cityData.length ? false : true}
                          >
                            {cityData.length === 0 ? (
                              <option
                                disabled={true}
                                selected={true}
                                value=""
                                id="s"
                              >
                                Select City
                              </option>
                            ) : null}
                            {cityData.map((res, index) => {
                              return (
                                <option
                                  disabled={res.value === "" ? true : false}
                                  selected={res.value === "" ? true : false}
                                  key={index}
                                  value={res.value}
                                  id={res.id}
                                >
                                  {res.key}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <form onSubmit={handleSubmit} noValidate>
                    <div className="row mb-3">
                      <div className="col-lg-2">
                        <select
                          className={`form-select ${
                            errors.fieldtype && touched.fieldtype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="fieldtype"
                          value={values.fieldtype || ""}
                          required
                        >
                          <option value="">Select</option>
                          <option value="1">Licensee Group/Store Name </option>
                          <option value="2">Store State </option>
                          <option value="3">Store City </option>
                          <option value="4">Sales First Name </option>
                          <option value="6">Sales Last Name </option>
                          <option value="5">Email </option>
                        </select>
                        {errors.fieldtype && touched.fieldtype ? (
                          <p className="help is-danger">{errors.fieldtype}</p>
                        ) : null}
                      </div>

                      <div className="col-lg-3 ">
                        <div className="input-group">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="searchval"
                            value={values.searchval || ""}
                            required
                          />

                          <button
                            className="btn btn-outline-secondary sebmit-dat"
                            type="submit"
                          >
                            Search
                          </button>
                        </div>
                        {errors.searchval && touched.searchval ? (
                          <p className="help is-danger">{errors.searchval}</p>
                        ) : null}
                      </div>

                      <div className="col-lg-2 ">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => changeLimit(e.target.value)}
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                      <div className="col-lg-1 ">
                        <button
                          className="btn btn-outline-secondary"
                          type="reset"
                          onClick={() => window.location.reload(false)}
                        >
                          Reset
                        </button>
                      </div>
                      <div className="col-lg-2">
                        <a
                          href={
                            uType === "dos"
                              ? process.env.REACT_APP_API_Link +
                                "quiz/export-all-dos-results/" +
                                id
                              : process.env.REACT_APP_API_Link +
                                "quiz/export-all-results"
                          }
                          rel="noreferrer"
                          target="_blank"
                          className="btn btn-primary w-100  back-blue"
                        >
                          Export All
                        </a>
                      </div>
                      <div className="col-lg-2">
                        <a
                          href={
                            uType === "dos"
                              ? process.env.REACT_APP_API_Link +
                                "quiz/export-all-dos-emails/" +
                                id
                              : process.env.REACT_APP_API_Link +
                                "quiz/export-all-emails"
                          }
                          rel="noreferrer"
                          target="_blank"
                          className="btn btn-primary w-100 back-orange"
                        >
                          Export All Emails
                        </a>
                      </div>
                    </div>
                  </form>
                  {exportResult ? (
                    <div className="row mb-3 justify-content-end">
                      <div className="col-lg-2">
                        <a
                          href={
                            process.env.REACT_APP_API_Link +
                            "quiz/export-result/" +
                            rsaId +
                            "/" +
                            storeStateId +
                            "/" +
                            storeCityId
                          }
                          rel="noreferrer"
                          target="_blank"
                          className="btn btn-primary w-100  back-blue"
                        >
                          Export Result
                        </a>
                      </div>
                      <div className="col-lg-2">
                        <a
                          href={
                            process.env.REACT_APP_API_Link +
                            "quiz/export-email/" +
                            rsaId +
                            "/" +
                            storeStateId +
                            "/" +
                            storeCityId
                          }
                          className="btn btn-primary w-100 back-orange"
                          rel="noreferrer"
                          target="_blank"
                        >
                          Export Emails
                        </a>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <MoveTable />
                  <div className="table-responsive">
                    <Table data={resultData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToTop />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
}
