import axios from "axios";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { UserContext } from "../../../context/UserContext";
import { updateStoreSchema } from "../../../schema";
import StoreService from "../../../services/store.service";
import Select from "react-select";

import UserService from "../../../services/user.service";

export default function StoreDetailUp() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { id } = state;
  const [storeState, setStoreState] = useState([]);
  const [city, setCity] = useState([]);
  const [store, setStore] = useState([]);
  const [storeStateSelected, setStoreStateSelected] = useState("");
  const [citySelected, setCitySelected] = useState(null);
  const [storeSelected, setStoreSelected] = useState(null);
  const [cityLoader, setCityLoader] = useState(false);
  const [storeLoader, setStoreLoader] = useState(false);
  const [storeEdit, setStoreEdit] = useState(false);
  const [storeStateText, setStoreStateText] = useState("Loading");
  const [cityText, setCityText] = useState("Loading");
  const [storeText, setStoreText] = useState("Loading");

  const changeCity = (state) => {
    setCity([]);
    setStore([]);
    setCitySelected("");
    setStoreSelected("");

    const getStoreCity = async () => {
      try {
        setCityLoader(true);
        setStoreLoader(true);

        const { data } = await StoreService.getCityByStateId(state);
        const { response: res } = data;
        const results = [];
        res.map((value) => {
          results.push({
            label: value.name,
            value: value.id,
          });
        });
        setCity([...results]);
        setCityLoader(false);
        setStoreLoader(false);
      } catch (error) {
        setCityLoader(false);
        setStoreLoader(false);
      }
    };

    if (state !== "") {
      setStoreStateSelected(state);
      getStoreCity();
    } else {
      setStoreStateSelected("");
    }
  };

  const changeStore = (city) => {
    setStore([]);
    setStoreSelected("");
    const getStore = async () => {
      try {
        setStoreLoader(true);
        const { data } = await StoreService.getStoreByCityId(city);
        const { response: res } = data;
        const results = [];
        res.map((value) => {
          results.push({
            label: value.name,
            value: value.id,
          });
        });
        setStore([...results]);
        setStoreLoader(false);
      } catch (error) {
        setStoreLoader(false);
      }
    };

    if (city !== "") {
      getStore();
    } else {
      setCitySelected("");
    }
  };
  const UpdateStoreData = async (values, action) => {
    setLoading(true);
    try {
      const response = await UserService.UpdateStoreInfo(values, id);

      if (response.status === 200) {
        setLoading(false);
        setStoreEdit(false);
        action.resetForm();
        getProfile();
        toast.success("Your store information has been updated!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (err) {
      if (err.response.status === 401) {
        setLoading(false);

        toast.error("Error, Try Agaim Later!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 422) {
        setLoading(false);
        toast.error("Error, Unprocessable Content!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoading(false);

        toast.error("Error, Some thing went wronge!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,

    initialValues: {
      stateprov: storeStateSelected,
      city: citySelected,
      store: storeSelected,
    },
    validationSchema: updateStoreSchema,
    onSubmit: (values, action) => {
      UpdateStoreData(values, action);
    },
  });

  const getStoreState = async () => {
    const { data } = await StoreService.getStoreState();
    const { response: res } = data;
    const results = [];
    res.map((value) => {
      results.push({
        label: value.name,
        value: value.id,
      });
    });
    setStoreState([...results]);
  };

  const getCity = async (val) => {
    try {
      setCityLoader(true);
      const { data } = await StoreService.getCityByStateId(val);
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          label: value.name,
          value: value.id,
        });
      });
      setCity([...results]);
      setCityLoader(false);
    } catch (error) {
      setCity([]);
      setCityLoader(false);
    }
  };

  const getStore = async (val) => {
    try {
      setStoreLoader(true);
      const { data } = await StoreService.getStoreByCityId(val);
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          label: value.name,
          value: value.id,
        });
      });
      setStore([...results]);
      setStoreLoader(false);
    } catch (error) {
      setStore([]);
      setStoreLoader(false);
    }
  };

  const getStateName = async (val) => {
    try {
      const response = await UserService.getNameState(val);
      if (response.status === 200) {
        setStoreStateText(response.data.response.name);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getCityName = async (val) => {
    try {
      const response = await UserService.getNameCity(val);

      if (response.status === 200) {
        setCityText(response.data.response.name);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getStoreName = async (val) => {
    try {
      const response = await UserService.getNameStore(val);

      if (response.status === 200) {
        setStoreText(response.data.response.name);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getProfile = async () => {
    setLoading(true);
    try {
      const response = await UserService.getProfile(id);

      if (response.status === 200) {
        ctxDispatch({
          type: "UPDATE_PROFILE",
          id: response.data.id,
          uName: response.data.username,
          uType: response.data.user_type,
          name: response.data.first_name + " " + response.data.last_name,
        });

        setStoreStateSelected(response.data.store_state_id);
        setCitySelected(response.data.store_city_id);
        setStoreSelected(response.data.store_id);

        // store_city_id;
        // store_id;
        // store_state_id;
        getStoreState();
        getStore(response.data.store_city_id);
        getCity(response.data.store_state_id);
        getStateName(response.data.store_state_id);
        getCityName(response.data.store_city_id);
        getStoreName(response.data.store_id);
        setLoading(false);

        // console.log(response.data);
      }
    } catch (err) {
      console.log(err);
      ctxDispatch({
        type: "LOGOUT",
      });
      setLoading(false);
      navigate("/");
    }
  };

  useEffect(() => {
    getProfile();
  }, [ctxDispatch, id, navigate]);

  useEffect(() => {
    const getIp = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");

      const weIp = res.data.IPv4;
      secureLocalStorage.setItem("ip", weIp);
    };
    getIp();
  }, [handleSubmit]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <ul className="timeline single-li">
        <li>
          <div className="prfil-set">
            <div className="form-box">
              <div className="row">
                <div className="alert alert-danger" role="alert">
                  <h6 className="astric-req">
                    Field(s) marked with <span>Asterisk (*)</span> are
                    mandatory.
                  </h6>
                </div>
                <div className="col-lg-8">
                  <h4 className="prf-hed">STORE INFORMATION</h4>{" "}
                </div>
                <div className="col-lg-4">
                  {!storeEdit ? (
                    <button
                      type="button"
                      className="round-red-btn edit-acc shown ms-auto"
                      onClick={(e) => {
                        e.preventDefault();
                        setStoreEdit(true);
                      }}
                    >
                      EDIT
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="round-red-btn save-acc ms-auto"
                    >
                      SAVE
                    </button>
                  )}
                </div>
              </div>

              {!storeEdit ? (
                <div className="store-details">
                  <div className="row">
                    <div className="col-lg-4">
                      <h6>
                        STORE STATE
                        <span>{storeStateText}</span>
                      </h6>{" "}
                    </div>
                    <div className="col-lg-4">
                      <h6>
                        STORE CITY
                        <span>{cityText}</span>
                      </h6>
                    </div>
                    <div className="col-lg-4">
                      <h6>
                        STORE
                        <span>{storeText}</span>
                      </h6>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="form-filds ">
                  <div className="row">
                    <div className="col-lg-4">
                      <div
                        className={`form-floating ${
                          errors.stateprov && touched.stateprov
                            ? "is-danger"
                            : ""
                        }`}
                      >
                        <Select
                          className={
                            errors.stateprov && touched.stateprov
                              ? "error-select-search"
                              : ""
                          }
                          placeholder={
                            storeStateText !== ""
                              ? storeStateText
                              : "SELECT STATE/PROVINCE"
                          }
                          // placeholder={"* SELECT STATE/PROVINCE"}
                          options={storeState}
                          isSearchable={true}
                          name="stateprov"
                          onChange={(selectedOption) => {
                            setFieldValue("city", "");
                            setFieldValue("store", "");
                            setStoreText("");
                            setCityText("");

                            if (selectedOption) {
                              setFieldValue("stateprov", selectedOption.value);
                              changeCity(selectedOption.value);
                            } else {
                              setFieldValue("stateprov", "");
                            }
                          }}
                          onBlur={() => setFieldTouched("stateprov", true)}
                        />
                        <label htmlFor="floatingSelect" className="with-select">
                          STORE STATE
                        </label>
                        {errors.stateprov && touched.stateprov ? (
                          <p className="help is-danger">{errors.stateprov}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      {cityLoader ? (
                        <h5 class="form-floating  placeholder-glow mb-0">
                          <span
                            class="placeholder col-12"
                            style={{
                              height: "58px",
                            }}
                          ></span>
                        </h5>
                      ) : (
                        <div
                          className={`form-floating ${
                            errors.city && touched.city ? "is-danger" : ""
                          }`}
                        >
                          <Select
                            className={
                              errors.city && touched.city
                                ? "error-select-search"
                                : ""
                            }
                            placeholder={
                              cityText !== "" ? cityText : "SELECT CITY"
                            }
                            options={city}
                            isSearchable={true}
                            name="city"
                            onChange={(selectedOption) => {
                              setFieldValue("store", "");
                              setStoreText("");

                              if (selectedOption) {
                                setFieldValue("city", selectedOption.value);
                                changeStore(selectedOption.value);
                              } else {
                                setFieldValue("city", "");
                              }
                            }}
                            onBlur={() => setFieldTouched("city", true)}
                          />
                          <label htmlFor="" className="with-select">
                            STORE CITY
                          </label>
                          {errors.city && touched.city ? (
                            <p className="help is-danger">{errors.city}</p>
                          ) : null}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4">
                      {storeLoader ? (
                        <h5 class="form-floating  placeholder-glow mb-0">
                          <span
                            class="placeholder col-12"
                            style={{
                              height: "58px",
                            }}
                          ></span>
                        </h5>
                      ) : (
                        <div
                          className={`form-floating ${
                            errors.store && touched.store ? "is-danger" : ""
                          }`}
                        >
                          <Select
                            className={
                              errors.store && touched.store
                                ? "error-select-search"
                                : ""
                            }
                            placeholder={
                              storeText !== "" ? storeText : "SELECT STORE"
                            }
                            options={store}
                            isSearchable={true}
                            name="store"
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                setFieldValue("store", selectedOption.value);
                              } else {
                                setFieldValue("store", "");
                              }
                            }}
                            onBlur={() => setFieldTouched("store", true)}
                          />
                          <label
                            htmlFor="floatingSelect"
                            className="with-select"
                          >
                            STORE
                          </label>
                          {errors.store && touched.store ? (
                            <p className="help is-danger">{errors.store}</p>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-lg-4">
                      <div
                        className={`form-floating ${
                          errors.stateprov && touched.stateprov
                            ? "is-danger"
                            : ""
                        }`}
                      >
                        <select
                          className="form-select"
                          onChange={(e) => {
                            handleChange(e);
                            changeCity(e);
                          }}
                          onBlur={handleBlur}
                          name="stateprov"
                          value={values.stateprov || ""}
                          required
                        >
                          {storeState.map((res) => {
                            return (
                              <option key={res.value} value={res.value}>
                                {res.label}
                              </option>
                            );
                          })}
                        </select>
                        <label htmlFor="floatingSelect">STORE STATE</label>
                        {errors.stateprov && touched.stateprov ? (
                          <p className="help is-danger">{errors.stateprov}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="select-leading">
                        {cityLoader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                        <div
                          className={`form-floating ${
                            errors.city && touched.city ? "is-danger" : ""
                          }`}
                        >
                          <select
                            className="form-select"
                            onChange={(e) => {
                              handleChange(e);
                              changeStore(e);
                            }}
                            onBlur={handleBlur}
                            name="city"
                            value={values.city || ""}
                            required
                          >
                            <option value="">SELECT CITY</option>;
                            {city.map((res) => {
                              return (
                                <option key={res.value} value={res.value}>
                                  {res.label}
                                </option>
                              );
                            })}
                          </select>
                          <label htmlFor="">STORE CITY</label>
                          {errors.city && touched.city ? (
                            <p className="help is-danger">{errors.city}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="select-leading">
                        {storeLoader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                        <div className="form-floating">
                          <div
                            className={`form-floating ${
                              errors.store && touched.store ? "is-danger" : ""
                            }`}
                          >
                            <select
                              className="form-select"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              name="store"
                              value={values.store || ""}
                              required
                            >
                              <option value=""> SELECT STORE </option>
                              {store.map((res) => {
                                return (
                                  <option key={res.value} value={res.value}>
                                    {res.label}
                                  </option>
                                );
                              })}
                            </select>
                            <label htmlFor="">STORE</label>
                            {errors.store && touched.store ? (
                              <p className="help is-danger">{errors.store}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              )}
            </div>
          </div>
        </li>
      </ul>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </form>
  );
}
