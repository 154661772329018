import React from "react";
import { Link } from "react-router-dom";
import "./SlidingMenu.css";
export default function SlidingMenu() {
  return (
    <div className="sliding-menu">
      <ul id="mytabs" role="tablist">
        <li>
          <a href="/" id="go-first" data-target="#first">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              className="svg-icon"
            >
              <use xlinkHref="#icon-03"></use>
            </svg>
            Home
          </a>
        </li>

        <li>
          <Link to="/welcome">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              className="svg-icon"
            >
              <use xlinkHref="#icon-06"></use>
            </svg>
            Take Another Quiz
          </Link>
        </li>

        <li>
          <button className="prev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              className="svg-icon"
            >
              <use xlinkHref="#icon-05"></use>
            </svg>
            Previous
          </button>
        </li>

        <li>
          <button className="next">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              className="svg-icon"
            >
              <use xlinkHref="#icon-04"></use>
            </svg>
            Next
          </button>
        </li>
      </ul>
    </div>
  );
}
