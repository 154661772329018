import DOMPurify from "dompurify";
import React, { useContext, useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CourseService from "../../../../services/course.service";
import InnerHTML from "dangerously-set-html-content";
import "./CourseContent.css";
import { toast, ToastContainer } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import { UserContext } from "../../../../context/UserContext";
import { Helmet } from "react-helmet";

export default function CourseContent() {
  const { state } = useContext(UserContext);
  const { accessToken } = state;
  const [courseIntro, setCourseIntro] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [maxToast, setMaxToast] = useState(10);

  const [courseQuestions, setCourseQuestions] = useState("");
  const [courseFname, setCourseFname] = useState("");
  const [courseLname, setCourseLname] = useState("");
  const [courseFdesc, setCourseFdesc] = useState("");
  const [courseLdesc, setCourseLdesc] = useState("");
  const [roundImage, setRoundImage] = useState("");
  const [bgImage, setBgImage] = useState("");
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    const getCourse = async () => {
      setLoading(true);
      try {
        const { status, data } = await CourseService.getCourseNameById(id);
        if (status === 200) {
          const {
            questions,
            fname,
            lname,
            fdesc,
            ldesc,
            round_image,
            bg_image,
          } = data;
          setCourseQuestions(questions);
          setCourseFname(fname);
          setCourseLname(lname);
          setCourseFdesc(fdesc);
          setCourseLdesc(ldesc);
          setRoundImage(round_image);
          setBgImage(bg_image);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    };

    const getCourseIntro = async () => {
      setLoading(true);
      try {
        const { status, data } = await CourseService.getCourseIntroById(id);
        if (status === 200) {
          const { response: res } = data;

          const results = [];
          res.map((value) => {
            results.push({
              key: value.id,
              course_name: value.course_name,
              code: value.code,
              slide: value.slide,
            });
          });
          setCourseIntro([...results]);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
      }
    };
    if (accessToken) {
      const getEligibility = async () => {
        setLoading(true);
        try {
          const response = await CourseService.getEligibilityById(
            id,
            secureLocalStorage.getItem("_id")
          );

          if (response.status === 200) {
            getCourse();
            getCourseIntro();
          }
        } catch (err) {
          setMaxToast("1");

          if (err.response.status === 302) {
            toast.error("You are not eligible for this course", {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else if (err.response.status === 404) {
            toast.error(err.response.data.error, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          setLoading(false);
          setTimeout(() => {
            navigate("/welcome", { replace: true });
          }, 1000);
        }
      };
      getEligibility();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>
          ASU | {courseFname !== "" ? courseFname + courseLname : ". . ."}
        </title>
      </Helmet>
      <div className="tab-content">
        <ToastContainer limit={maxToast} />
        <div>
          <div className="tab-pane fade active in show" id="first">
            <div className="slides-here">
              <h2 className="slide-heading">
                <span>{courseFname}</span>
                {courseLname}
              </h2>
              <div
                className="back-slid"
                style={{ backgroundImage: "url(" + bgImage + ")" }}
              >
                <div className="text-contain">
                  <div className="row">
                    <div className="col-lg-7 inc-z">
                      <div className="content-div">
                        <h3>ASHLEY SLEEP TRAINING GUIDE</h3>
                        <p>
                          {/* {courseFdesc} */}
                          {courseFname}
                          <br className="d-none d-lg-block" />
                          {/* {courseLdesc} */}
                          {courseLname}
                        </p>
                        <button className="round-red-btn next">
                          Start Course
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-5 inc-z">
                      <div className="round-with-bar">
                        <div className="round-bor">
                          <img src={roundImage} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {courseIntro.map((res) => {
          return <InnerHTML key={res.key} html={res.slide} />;
        })}

        <div>
          <div className="tab-pane fade" id="knowlede-check">
            <div className="slides-here">
              <h2 className="slide-heading">
                <span>{courseFname}</span>
                {courseLname}
              </h2>
              <div className="back-slid slide-secnd">
                <div className="text-contain">
                  <div className="row">
                    <div className="col-lg-4 inc-z d-flex">
                      <img
                        src="/images/thumb-up-girl.png"
                        className="img-fluid w-75 m-auto me-lg-0"
                        alt="..."
                      />
                    </div>
                    <div className="col-lg-8 inc-z">
                      <div className="content-div">
                        <h3>KNOWLEDGE CHECK</h3>
                        <ul className="circle-list">
                          <li>Now it's time to review what you've learned.</li>
                          <li>
                            Please answer the following {courseQuestions}{" "}
                            question(s).
                          </li>

                          <li>
                            You may change your answer as many times as you want
                            before clicking the Check My Answer button.
                          </li>
                          <li>Once clicked, you cannot change your answer.</li>
                          <li>
                            Click the button to take a short quiz on what you
                            just learned.
                          </li>
                        </ul>

                        <Link
                          to={"/quiz/" + id}
                          className="round-red-btn mt-4 text-uppercase"
                        >
                          Start Quiz
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </>
  );
}
