import React, { useLayoutEffect, useState } from "react";

import HeaderSidebar from "../includes/HeaderSidebar";
import ToTop from "../includes/ToTop";
import { CKEditor } from "ckeditor4-react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AdminListService from "../../../services/admin-list.service";

export default function EditCourse() {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [ckVal, setCkVal] = useState([]);

  useLayoutEffect(() => {
    const getCourseSlide = async () => {
      setLoading(true);

      try {
        const { status, data } = await AdminListService.getCourseSlideById(id);
        if (status === 200) {
          const { response: res } = data;
          const results = [];
          res.map((value) => {
            results.push({
              slideData: value.slide,
            });
          });
          setCkVal([...results]);

          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          toast.error("Resource Not Found!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };

    getCourseSlide();
  }, []);

  let removeCkVal = (i) => {
    let newCkVal = [...ckVal];
    newCkVal.splice(i, 1);
    setCkVal(newCkVal);
  };
  let addCkVal = () => {
    setCkVal([...ckVal, { slideData: "" }]);
  };
  return (
    <div className="semi-dark">
      <div className="wrapper">
        <HeaderSidebar />
        <ToastContainer />

        <main className="page-content">
          <div className="row">
            <div className="col">
              <div className="manage-heading-2">
                <h2>Edit Courses</h2>
              </div>
              {ckVal.map((element, index) => (
                <div className="main-content-box position-relative" key={index}>
                  {index === 0 ? (
                    <button
                      className="btn btn-primary px-4 back-blue add-slide"
                      onClick={() => addCkVal()}
                    >
                      Add Slide{" "}
                      <i className="fadeIn animated bx bx-add-to-queue"></i>
                    </button>
                  ) : null}

                  <h2 className="manage-territories-heading">
                    {/* {element.slide} */}
                    Slide {index + 1}
                  </h2>

                  <div className="ckeditor-box">
                    <CKEditor
                      initData={element.slideData}
                      config={{
                        toolbar: [
                          ["Source"],
                          ["Styles", "Format", "Font", "FontSize"],
                          ["Undo", "Redo"],
                          [
                            "Bold",
                            "Italic",
                            "Strike",
                            "UnderLine",
                            "RemoveFormat",
                          ],
                          ["Link", "Unlink", "Anchor"],
                          ["BulletedList", "NumberedList"],
                          ["EasyImageUpload"],
                          ["Maximize", "ShowBlocks"],
                        ],
                        extraPlugins: "easyimage",
                        removePlugins: "image",
                        cloudServices_uploadUrl:
                          "https://33333.cke-cs.com/easyimage/upload/",
                        cloudServices_tokenUrl:
                          "https://33333.cke-cs.com/token/dev/ijrDsqFix838Gh3wGO3F77FSW94BwcLXprJ4APSp3XQ26xsUHTi0jcb1hoBt",
                      }}
                      onInstanceReady={(e) => {
                        // console.log(e.editor.getData());
                      }}
                      onChange={(e) => {
                        setCkVal([...ckVal, { slideData: e.editor.getData() }]);
                        console.log(ckVal);
                      }}
                    />
                  </div>
                  {index ? (
                    <div className="add-edit-delete-inline text-end mt-3">
                      <button
                        className="btn btn-primary px-4 back-blue"
                        onClick={() => removeCkVal(index)}
                      >
                        Delete Slide <i className="bi bi-trash-fill"></i>
                      </button>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </main>

        <ToTop />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </div>
  );
}
