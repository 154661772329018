import React from "react";
import Footer from "../Includes/Footer";
import SlidingBg from "../Includes/SlidingBg";
import ToTop from "../Includes/ToTop";
import { Helmet } from "react-helmet";

export default function Faq() {
  const TITLE = "ASU | FAQ's";

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="pad-bot">
        <ToTop />
        <SlidingBg />
        <section className="slide-up">
          <div className="container">
            <div className="slides-here">
              <h2 className="slide-heading">
                <span>FREQUENTLY ASKED </span>
                QUESTIONS
              </h2>
              <video
                width="100%"
                className="faq-vid"
                poster="/images/video-thumb.jpg"
                controls
              >
                <source src="/images/ashley_sleep.mp4" type="video/mp4" />
                {/* <source src="/images/ashley_sleep.ogv" type="video/ogv" /> */}
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}
