import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const API_URL = process.env.REACT_APP_API_Link;

const getLevelsByMain = (name, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "quiz/listSubLevels/" + name + "/" + id, {
    headers: headers,
  });
};

const getResultsById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "quiz/listResultByUserId/" + id, {
    headers: headers,
  });
};

const getSearchResultsById = (id, col, val, limit) => {
  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "percentage";
  } else if (col === "3") {
    col = "status";
  } else {
    col = "created_at";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "quiz/listResultByUserId/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getSearchWithDateResultsById = (id, col, val, createDate, limit) => {
  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "percentage";
  } else if (col === "3") {
    col = "status";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "quiz/listResultByUserId/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&created_at=" +
      createDate +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getLimitResultsById = (id, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "quiz/listResultByUserId/" + id + "?limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getPaginatedResultsById = (id, pageNo) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "quiz/listResultByUserId/" + id + "?page=" + pageNo,
    {
      headers: headers,
    }
  );
};

const getResultDate = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "quiz/diploma/" + id, {
    headers: headers,
  });
};

const getLevelStatus = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "quiz/levelStatus/" + id, {
    headers: headers,
  });
};

const getLevelEligibility = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "quiz/levelEligibility/" + id, {
    headers: headers,
  });
};

const getPassedLevel = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "quiz/passedLevel/" + id, {
    headers: headers,
  });
};
const getPassedCounter = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "quiz/countPassResultByUserId/" + id, {
    headers: headers,
  });
};

const submitResult = (userId, courseCode) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "quiz/resultSubmit",
    {
      userId: userId,
      courseCode: courseCode,
      ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const QuizService = {
  getResultsById,
  getSearchResultsById,
  getLimitResultsById,
  getLevelsByMain,
  getPaginatedResultsById,
  getSearchWithDateResultsById,
  getResultDate,
  getLevelStatus,
  getLevelEligibility,
  getPassedLevel,
  getPassedCounter,
  submitResult,
};

export default QuizService;
