import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { useFormik } from "formik";
import { signInSchema } from "../../../schema";
import UserService from "../../../services/user.service";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";
import { Helmet } from "react-helmet";

export default function Login() {
  const TITLE = "Login";
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(UserContext);
  const { accessToken, uType } = state;

  
  useEffect(() => {
    if (accessToken && uType === "rsa") {
      navigate("/welcome");
    }
  }, [navigate, accessToken]);

  const [loading, setLoading] = useState(false);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema: signInSchema,
      onSubmit: (values, action) => {
        login(action);
      },
    });

  useEffect(() => {
    if (accessToken && uType === "rsa") {
      console.log(uType);
      navigate("/welcome");
    }
  }, [navigate, accessToken, uType]);

  const login = async (action) => {
    setLoading(true);
    try {
      const response = await UserService.login(values);

      if (response.status === 200) {
        if (
          response.data.user.user_type !== "admin" &&
          response.data.user.user_type !== "dos" &&
          response.data.user.user_type !== "ndos"
        ) {
          action.resetForm();
          ctxDispatch({
            type: "LOGIN",
            accessToken: response.data.access_token,
            id: response.data.user.id,
            uName: response.data.user.username,
            uType: response.data.user.user_type,
            name:
              response.data.user.first_name +
              " " +
              response.data.user.last_name,
          });
          navigate("/welcome", { replace: true });
          setLoading(false);
        } else {
          toast.error("Username or Password is invalid !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }

        // console.log(response.data.access_token);
      }
    } catch (err) {
      if (err?.response?.status === 401) {
        setLoading(false);
        toast.error("Username or Password is invalid !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoading(false);

        toast.error("Something went wrong, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="login-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <Link to="/">
                <img
                  src="images/logo-white.png"
                  className="img-fluid logo"
                  alt=""
                />
              </Link>
            </div>
            <div className="col-lg-7">
              <h2>
                <span>welcome to</span>
                Ashley Sleep University
              </h2>
              <p>
                Premium Stretch Knit covers, high density memory foams are all
                used to provide the best value at the best prices.
              </p>
            </div>

            <div className="col-lg-4">
              <div className="white-box">
                <div className="rever-trans">
                  <form action="" onSubmit={handleSubmit} noValidate>
                    <div className="row">
                      <div className="col-12">
                        <div
                          className={`input-group ${
                            errors.username && touched.username
                              ? "is-danger"
                              : ""
                          }`}
                        >
                          <span className="input-group-text" id="basic-addon1">
                            <img src="images/black-person.png" alt="" />
                          </span>
                          <input
                            className="form-control"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="username"
                            value={values.username || ""}
                            required
                            placeholder={`${
                              errors.username && touched.username
                                ? errors.username
                                : "Username"
                            }`}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div
                          className={`input-group ${
                            errors.password && touched.password
                              ? "is-danger"
                              : ""
                          }`}
                        >
                          <span className="input-group-text" id="basic-addon1">
                            <img src="images/black-lock.png" alt="" />
                          </span>
                          <input
                            className="form-control"
                            type="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="password"
                            value={values.password || ""}
                            required
                            placeholder={`${
                              errors.password && touched.password
                                ? errors.password
                                : "Password"
                            }`}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                      <div className="col-lf">
                        <input type="submit" value="Login" />
                      </div>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-12">
                      <ul>
                        <li>
                          <span>
                            If you haven't registered for this website.
                          </span>
                          <Link to="/registration">Click here</Link>
                        </li>
                        <li>
                          <span>If you have Forgot your Password.</span>
                          <Link to="/forgot_pass">Click here</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <img src="images/cap.png" className="img-fluid cap-img" alt="" />
            </div>
          </div>
          <div className="video-holder">
            <video
              width="100%"
              className="login-vid"
              poster="/images/video-thumb.jpg"
              controls
            >
              <source src="/images/ashley_sleep.mp4" type="video/mp4" />
              {/* <source src="/images/ashley_sleep.ogv" type="video/ogv" /> */}
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="copyright">
          © Copyrights {new Date().getFullYear()} all rights reserved by
          ashleysleepuniversity.com
        </div>

        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
