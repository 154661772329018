import React, { useContext, useEffect } from "react";
import CourseContent from "./CourseContent/CourseContent";
import Footer from "../Includes/Footer";
import SlidingBg from "../Includes/SlidingBg";
import SlidingMenu from "./SlidingMenu/SlidingMenu";
import SvgsIco from "../Includes/SvgsIco";
import ToTop from "../Includes/ToTop";

export default function Course() {
  return (
    <div className="pad-bot">
      <ToTop />
      <SlidingBg />

      <section className="slide-up">
        <div className="container">
          <CourseContent />
          <SlidingMenu />
        </div>
      </section>
      <Footer />
      <SvgsIco />
    </div>
  );
}
